<div class="container app-sidebar" [ngClass]="httpService.theme">
    <div class="logo" [routerLink]="'/'">
        <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
            title="Credible Finance" loading="lazy" routerLink="/" />
    </div>

    <div class="collapse-sidebar" *ngIf="!currentCollapseStatus">
        <i class="fa-solid fa-angles-left" (click)="collapseSidebar()" matTooltip="Collapse"></i>
    </div>
    <div class="expand-sidebar" *ngIf="currentCollapseStatus">
        <i class="fa-solid fa-angles-right" (click)="collapseSidebar()" matTooltip="Expand"></i>
    </div>

    <div class="stats" *ngIf="!currentCollapseStatus">
        <cf-card  [theme]="httpService.theme" [stat]="'$' + (tvl_usd | number : '0.0-0')" heading="Total value locked"
            type="compact"></cf-card>
    </div>
    <div class="menus" [ngClass]="{ 'collased-menus': currentCollapseStatus }"
        *ngIf="this.httpService.checkUserOnboardingStatus()">
        <div *ngFor="let menu of newMenus; let i = index">
            <div [ngClass]="
                    selectedMenu && selectedMenu.id === menu.id
                        ? 'item-holder item-holder-active'
                        : 'item-holder'
                " (click)="changeModule(menu)">
                <img class="me-3" [src]="
                        selectedMenu && selectedMenu.id === menu.id
                            ? menu.themeIconUrl
                            : menu.blackIconUrl
                    " alt="" *ngIf="!currentCollapseStatus" />
                <img class="me-3" [src]="selectedMenu && selectedMenu.id === menu.id
                                                ? menu.themeIconUrl
                                                : menu.blackIconUrl
                                        " alt="" [matTooltip]="menu.name" *ngIf="currentCollapseStatus" />
                <span [ngStyle]="{
                        display: currentCollapseStatus ? 'none' : 'block'
                    }">
                    {{ menu.name }}
                </span>
            </div>
            <div *ngIf="menu.isExpandMenu">
                <div *ngFor="let subMenu of menu.subMenus" class="sub-menus">
                    <div (click)="changeSubMenu(subMenu)" class="sub-menu-item-holder align-items-center"
                        [routerLink]="subMenu.url">
                        <span class="hide-name">{{ subMenu.name }}</span>
                    </div>
                </div>
            </div>
            <div class="divider" *ngIf="menu.divider"></div>
        </div>
    </div>

    <br />
    <div class="community mb-3">
        <div class="heading text-center" *ngIf="!currentCollapseStatus">
            Join Our Community
        </div>

        <div class="icons" [ngClass]="{ 'collased-community-icons': currentCollapseStatus }">
            <a href="https://twitter.com/crediblefin" target="_blank"><i class="fa-brands fa-twitter"
                    matTooltip="Twitter"></i></a>
            <a href="https://t.me/crediblefinance" target="_blank"><i class="fa-brands fa-telegram"
                    matTooltip="Telegram"></i></a>
            <a href="https://medium.com/@credible_finance" target="_blank"><i class="fa-brands fa-medium"
                    matTooltip="Medium"></i></a>
        </div>
    </div>
    <!-- <div class="theme-switcher">
        <div class="theme-switcher-buttons">
            <div *ngIf="httpService.theme === 'dark'" class="d-flex" (click)="changeTheme('light')">
                <i class="fa-solid fa-moon"></i>
                Dark
            </div>
            <div *ngIf="httpService.theme === 'light'" class="d-flex" (click)="changeTheme('dark')">
                <i class="fa-solid fa-sun"></i>
                Light
            </div>
        </div>
    </div> -->
</div>