<div class="app-change-blockchain-dialog">
    <div class="page-heading" mat-dialog-title>Change blockchain</div>

    <mat-dialog-content>
        <div class="blockhain-container">
            <span class="title">Wallet address:</span>
            <span class="blockchain">
                {{ httpService.sliceAddress(httpService.user.wallet_address, 8) }}
            </span>
        </div>

        <div class="blockhain-container">
            <span class="title">Current blockchain:</span>
            <span class="blockchain">
                <img class="blockchain-icon" [src]="current_blockchain_icon" alt="Blockchain icon" />
                {{ data.current_blockchain | titlecase }}
            </span>
        </div>

        <div class="blockhain-container">
            <span class="title">Expected blockchain:</span>
            <span class="blockchain">
                <img class="blockchain-icon" [src]="expected_blockchain_icon" alt="Blockchain icon" />
                {{ data.expected_blockchain | titlecase}}
            </span>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="controls">
            <cf-button type="outline" label="Cancel" (clicked)="closeDialog()"></cf-button>
            <cf-button type="solid" label="Logout" (clicked)="logout()"></cf-button>
        </div>
    </mat-dialog-actions>
</div>