import IPayFiDrawdown from '../interfaces/IPayFiDrawdown';

export default class PayFiDrawdown implements IPayFiDrawdown {
    blockchain: string = '';
    blockchain_txid: string = '';
    category_id: string = '';
    category_name: string = '';
    chain: string = '';
    closed: boolean = false;
    collateral_amount: number = 0;
    collateral_amount_usd: number = 0;
    collateral_currency: string = '';
    conversion_rate: number = 0;
    created: number = 0;
    day_number: number = 0;
    drawdown_id: string = '';
    collateral_id: string = '';
    enabled: boolean = true;
    fees: number = 0;
    fees_currency: string = '';
    fees_usd: number = 0;
    maturity_time: number = 0;
    month_number: number = 0;
    network: string = '';
    pool_id: string = '';
    pool_info = {
        name: '' 
    };
    pool_name: string = '';
    received_amount: number = 0;
    received_amount_usd: number = 0;
    received_currency: string = '';
    repaid_amount: number = 0;
    repaid_currency: string = '';
    last_interest_payment_time: number = 0;
    status: string = '';
    user_id: number = 0;
    user_location = {
        city: '',
        country: '',
        regionName: '' 
    };
    wallet_address: string = '';
    progressSteps = [];
    payout_status: string = '';
    yield_rate: number = 0;
}