import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/localstorage.service';
import { serverUrl } from '../app/app.config';

@Injectable({
    providedIn: 'root'
})
export class DiscordService {
    constructor(private http: HttpClient, private localStorageService: LocalStorageService) {

    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    getAuthorizeUrl() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}discord/getAuthorizeUrl`, httpOptions);
    }

    processDiscordCallback(code: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        const body = {
            code
        };

        return this.http.post<any>(`${serverUrl}discord/processCallback`, body, httpOptions);
    }

    checkDiscordGroupJoined() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}discord/checkGroupJoined`, {}, httpOptions);
    }
}
