<div class="app-add-bank" [ngClass]="httpService.theme">
    <div class="page-heading" mat-dialog-title>Add bank</div>

    <mat-dialog-content>
        <div class="field">
            <cf-input [theme]="httpService.theme" label="Account holder name"
                placeholder="Account holder name"></cf-input>
        </div>
        <div class="field">
            <cf-input [theme]="httpService.theme" label="Account number" placeholder="Account number"></cf-input>

        </div>
        <div class="field">
            <cf-input [theme]="httpService.theme" label="IFSC Code" placeholder="IFSC code"></cf-input>
        </div>

        <div class="bank-summary">
            <div class="summary-item">
                <span class="summary-heading">Bank name</span>
                <span class="summary-value"></span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Bank branch</span>
                <span class="summary-value"></span>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="controls">
            <cf-button [theme]="httpService.theme" type="outline" label="Cancel" (clicked)="closeDialog()"></cf-button>
            <cf-button [theme]="httpService.theme" type="solid" label="Submit" (clicked)="addBank()"></cf-button>
        </div>
    </mat-dialog-actions>
</div>