import IPrivateEquityPool from '../interfaces/IPrivateEquityPool';

export default class PrivateEquityPool implements IPrivateEquityPool {
    category_id: string = '';
    category_name: string = '';
    created: number = 0;
    current_investment: string = '';
    enabled: Boolean = true;
    existing_investors: string = '';
    image_url: string = '';
    name: string = '';
    pool_id: string = '';
    raised: string = '';
    series: string = '';
    total_investment: string = '';
    updated: number = 0;
}