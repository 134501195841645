import {
    CfAlertModule, CfCardModule, CfCheckboxModule, 
    CfChipModule, CfDropdownModule, CfInputCurrencyDropdownModule, 
    CfInputModule, CfToggleModule, CfTabsModule, 
    CfLoaderModule, CfMobileNumberModule, CfButtonModule, 
    CfPaginatorModule, CfOtpModule, CfInputCurrencyModule, 
    CfCircularProgressBarModule, CfVerticalProgressBarModule, CfCheckboxLargeModule
} from '@crediblefinance/credible-ui';

const components = [
    CfAlertModule, 
    CfCardModule, 
    CfCheckboxModule,
    CfCheckboxLargeModule,
    CfChipModule, 
    CfDropdownModule, 
    CfInputCurrencyDropdownModule,
    CfInputModule, 
    CfToggleModule, 
    CfTabsModule,
    CfLoaderModule, 
    CfMobileNumberModule, 
    CfButtonModule, 
    CfPaginatorModule,
    CfOtpModule,
    CfInputCurrencyModule, 
    CfCircularProgressBarModule,
    CfVerticalProgressBarModule
];

export default components;
