var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import { PublicKey } from '@solana/web3.js';
import WalletAdapter from './base';
import { v4 as uuidv4 } from 'uuid';
import bs58 from 'bs58';
var IframeAdapter = /** @class */function (_super) {
  __extends(IframeAdapter, _super);
  function IframeAdapter(iframe, publicKey) {
    var _this = this;
    var _a;
    _this = _super.call(this) || this;
    _this._publicKey = null;
    _this._messageHandlers = {};
    _this.handleMessage = function (data) {
      if (_this._messageHandlers[data.id]) {
        var _a = _this._messageHandlers[data.id],
          resolve = _a.resolve,
          reject = _a.reject;
        delete _this._messageHandlers[data.id];
        if (data.error) {
          reject(data.error);
        } else {
          resolve(data.result);
        }
      }
    };
    _this._sendMessage = function (data) {
      if (!_this.connected) {
        throw new Error('Wallet not connected');
      }
      return new Promise(function (resolve, reject) {
        var _a, _b;
        var messageId = uuidv4();
        _this._messageHandlers[messageId] = {
          resolve: resolve,
          reject: reject
        };
        (_b = (_a = _this._iframe) === null || _a === void 0 ? void 0 : _a.contentWindow) === null || _b === void 0 ? void 0 : _b.postMessage({
          channel: 'solflareWalletAdapterToIframe',
          data: __assign({
            id: messageId
          }, data)
        }, '*');
      });
    };
    _this._iframe = iframe;
    _this._publicKey = new PublicKey((_a = publicKey === null || publicKey === void 0 ? void 0 : publicKey.toString) === null || _a === void 0 ? void 0 : _a.call(publicKey));
    return _this;
  }
  Object.defineProperty(IframeAdapter.prototype, "publicKey", {
    get: function () {
      return this._publicKey || null;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(IframeAdapter.prototype, "connected", {
    get: function () {
      return true;
    },
    enumerable: false,
    configurable: true
  });
  IframeAdapter.prototype.connect = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2 /*return*/];
      });
    });
  };
  IframeAdapter.prototype.disconnect = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._sendMessage({
              method: 'disconnect'
            })];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  IframeAdapter.prototype.signTransaction = function (transaction) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var signedTransaction, e_1;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (!this.connected) {
              throw new Error('Wallet not connected');
            }
            _b.label = 1;
          case 1:
            _b.trys.push([1, 3,, 4]);
            return [4 /*yield*/, this._sendMessage({
              method: 'signTransaction',
              params: {
                transaction: bs58.encode(transaction)
              }
            })];
          case 2:
            signedTransaction = _b.sent();
            return [2 /*return*/, bs58.decode(signedTransaction)];
          case 3:
            e_1 = _b.sent();
            throw new Error(((_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.toString) === null || _a === void 0 ? void 0 : _a.call(e_1)) || 'Failed to sign transaction');
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  IframeAdapter.prototype.signAllTransactions = function (transactions) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var signedTransactions, e_2;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (!this.connected) {
              throw new Error('Wallet not connected');
            }
            _b.label = 1;
          case 1:
            _b.trys.push([1, 3,, 4]);
            return [4 /*yield*/, this._sendMessage({
              method: 'signAllTransactions',
              params: {
                transactions: transactions.map(function (transaction) {
                  return bs58.encode(transaction);
                })
              }
            })];
          case 2:
            signedTransactions = _b.sent();
            return [2 /*return*/, signedTransactions.map(function (transaction) {
              return bs58.decode(transaction);
            })];
          case 3:
            e_2 = _b.sent();
            throw new Error(((_a = e_2 === null || e_2 === void 0 ? void 0 : e_2.toString) === null || _a === void 0 ? void 0 : _a.call(e_2)) || 'Failed to sign transactions');
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  IframeAdapter.prototype.signAndSendTransaction = function (transaction, options) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var result, e_3;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (!this.connected) {
              throw new Error('Wallet not connected');
            }
            _b.label = 1;
          case 1:
            _b.trys.push([1, 3,, 4]);
            return [4 /*yield*/, this._sendMessage({
              method: 'signAndSendTransaction',
              params: {
                transaction: bs58.encode(transaction),
                options: options
              }
            })];
          case 2:
            result = _b.sent();
            return [2 /*return*/, result];
          case 3:
            e_3 = _b.sent();
            throw new Error(((_a = e_3 === null || e_3 === void 0 ? void 0 : e_3.toString) === null || _a === void 0 ? void 0 : _a.call(e_3)) || 'Failed to sign and send transaction');
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  IframeAdapter.prototype.signMessage = function (data, display) {
    var _a;
    if (display === void 0) {
      display = 'hex';
    }
    return __awaiter(this, void 0, void 0, function () {
      var result, e_4;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (!this.connected) {
              throw new Error('Wallet not connected');
            }
            _b.label = 1;
          case 1:
            _b.trys.push([1, 3,, 4]);
            return [4 /*yield*/, this._sendMessage({
              method: 'signMessage',
              params: {
                data: data,
                display: display
              }
            })];
          case 2:
            result = _b.sent();
            return [2 /*return*/, Uint8Array.from(bs58.decode(result))];
          case 3:
            e_4 = _b.sent();
            throw new Error(((_a = e_4 === null || e_4 === void 0 ? void 0 : e_4.toString) === null || _a === void 0 ? void 0 : _a.call(e_4)) || 'Failed to sign message');
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  return IframeAdapter;
}(WalletAdapter);
export default IframeAdapter;