<div class="app-choose-profile-picture">
    <div class="page-heading" mat-dialog-title>Choose your Chicken</div>

    <mat-dialog-content>
        <div class="gallery" *ngIf="!loading">
            <!-- <div class="file-upload" matTooltip="Upload your profile picture">
                +
            </div> -->
            <div class="gallery-item" *ngFor="let image of profile_images" (click)="selectImage(image)"
                [ngClass]="{'current-profile-image': current_profile_image === image.path}">
                <img [src]="image.path" />
            </div>
        </div>
        <div class="loading" *ngIf="loading">
            <cf-loader class="loader"></cf-loader>
        </div>
    </mat-dialog-content>
</div>