import {Component} from '@angular/core';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.scss' ]
})
export class FooterComponent {
    currentYear: string = new Date().getFullYear().toString();

    constructor(public httpService: HttpService) { }
}
