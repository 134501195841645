import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit, Output, HostListener, 
    EventEmitter
} from '@angular/core';
import {
    FormGroup, FormBuilder 
} from '@angular/forms';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import WalletEvent from '../../../models/WalletEvent';
import { HttpService } from '../../../services/http.service';
import { LocalStorageService } from '../../../services/localstorage.service';
import { PhantomService } from '../../../services/phantom.service';
import { SolflareService } from '../../../services/solflare.service';
import { WalletService } from '../../../services/wallet.service';
import { RECAPTCHA_SITE_KEY } from '../../app.config';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { MetamaskService } from '../../metamask.service';

declare let grecaptcha: any;

@Component({
    selector: 'app-connect-wallet',
    templateUrl: './connect-wallet.component.html',
    styleUrls: [ './connect-wallet.component.scss' ]

})
export class ConnectWalletComponent implements OnInit {
    wallet_address: string = '';

    showControls = {
        connectButton: true,
        sendCodeButton: false,
        signingLoader: false,
        registerButton: false,
        disconnectButton: false
    };

    phantomDetected: boolean = false;
    solflareDetected: boolean = false;
    metamaskDetected: boolean = false;

    registerForm!: FormGroup;

    default_provider: string = 'phantom';
    current_provider: string = this.default_provider;
    window: Window;
    width: any;
    ledger: boolean = false;

    referral_code: string = '';

    allowedProviders: string[] = [ 'phantom', 'solflare', 'email' ];
    wallet_list_loader: boolean = false;

    @Output() connected = new EventEmitter<any>();
    @Output() next = new EventEmitter<any>();

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    constructor(
        private router: Router,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private localStorageService: LocalStorageService,
        private fb: FormBuilder,
        public phantomService: PhantomService,
        public solflareService: SolflareService,
        public metamaskService: MetamaskService,
        private walletService: WalletService,
        private route: ActivatedRoute
    ) {
        this.window = getWindow();

        this.registerForm = this.fb.group({
            wallet_address: [ '' ],
            signed_message: [ '' ],
            signature: [ '' ]
        });
    }

    ngOnInit(): void {
        this.width = this.window.innerWidth;
      
        this.phantomDetected = this.phantomService.isPresent();
        this.solflareDetected = this.solflareService.isPresent();
        this.metamaskDetected = this.metamaskService.isPresent();
        
        this.walletService.ledger = false;
      
        this.subscribeWalletObservables();
        this.subscribeQueryObservables();
    }

    connectWalletMobile() {
        const callbackUrl = 'https://credible.finance';

        const deepLink = `https://phantom.app/ul/browse/${callbackUrl}?ref=${callbackUrl}`;

        console.log('RELOAD ISSUE URL : HeaderComponent deepLink => ', this.window.location.href);

        return this.window.location.href = deepLink;
    }

    async connectWalletV2(address: string, provider: string, referral_code: string) {
        this.showControls.signingLoader = true;

        grecaptcha.enterprise.ready(async () => {
            const recaptcha = await grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, {
                action: 'checkWalletAddress'
            });
    
            const body: any = {
                wallet_address: this.wallet_address,
                referral_code,
                recaptcha,
                provider: provider,
                blockchain: this.walletService.blockchain,
                ledger: this.walletService.ledger
            };
    
            if (this.walletService.ledger) {
                const serializedTx = Array.from(this.registerForm.controls['signed_message'].value);
    
                body.serializedTx = serializedTx;
            }
    
            else {
                const signature = Array.from(this.registerForm.controls['signature'].value);
    
                body.signature = signature;
            }
    
            this.httpService.connectWalletV2(body).subscribe({
                next: (res: any) => {
                    console.log(address, res.data);
    
                    this.localStorageService.setItem('token', res.headers.get('token') || '');
                    this.walletService.current_provider = provider;
    
                    this.showControls.connectButton = false;
                    this.showControls.signingLoader = false;
                    this.showControls.disconnectButton = true;
    
                    this.httpService.getCurrentUser().then(() => {
                        this.connected.emit({
                            provider,
                            wallet_address: address
                        });
                    });
                },
                error: (err: HttpErrorResponse) => {
                    console.error(err);
    
                    this.showControls.connectButton = false;
                    this.showControls.signingLoader = false;
                    this.showControls.disconnectButton = true;
    
                    this.cfAlertService.showError(err);
                }
            });
        });
    }

    walletChanged(value: string) {
        console.log('walletChanged', value);

        this.wallet_address = value;
    }

    subscribeQueryObservables() {
        this.route.queryParams.subscribe(params => {
            // console.log('queryParams params', params);

            if (params['provider']) {
                const provider = params['provider'];

                if (this.allowedProviders.includes(provider))
                    this.current_provider = provider;
            }
        });
    }

    subscribeWalletObservables() {
        this.walletService.getEvent().subscribe(async (event: WalletEvent) => {
            switch (event.name) {
                case 'connect':

                    this.wallet_address = event.metadata.wallet_address;

                    this.showControls.connectButton = true;
                    this.showControls.signingLoader = true;
                    this.showControls.disconnectButton = false;
                    this.showControls.sendCodeButton = true;

                    break;

                case 'signMessage':
                    this.signMessage(event);

                    break;

                case 'disconnect':

                    this.cfAlertService.showSuccess(`${this.httpService.sliceAddress(this.wallet_address)}... account disconnected`);
                    this.wallet_address = '';

                    this.showControls.connectButton = true;
                    this.showControls.disconnectButton = false;
                    this.showControls.sendCodeButton = false;

                    this.router.navigate([ '/' ]);

                    break;

                case 'accountChanged':
                    if (event.metadata.new_address) {
                        console.log(`Switched to account ++ ${event.metadata.new_address}`);
                        
                        this.router.navigate([ '/' ]);
                    }

                    break;
            }
        });
    }

    async signMessage(event: WalletEvent) {
        if (event.metadata.status === 'failure')
            return this.cfAlertService.showMessage('Invalid signature');

        else if (event.metadata.status === 'success') {
            console.log('event.metadata.signature', event.metadata.signature);

            if (this.walletService.ledger)
                this.registerForm.controls['signed_message'].setValue(event.metadata.serializedTx);

            else
                this.registerForm.controls['signature'].setValue(event.metadata.signature);

            this.connectWalletV2(this.wallet_address, event.provider, this.referral_code);
        }
    }

    changeProvider(provider: string) {
        this.current_provider = provider;
    }

    loginWithEmail() {
        this.current_provider = 'email';
    }

    loginWithWallets() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                provider: this.default_provider
            }
        });
    }

    nextHandler() {
        this.next.emit();
    }

    ledgerChanged(event: any) {
        console.log('ledgerChanged', event);
        
        this.ledger = event;
        this.walletService.ledger = event;
    }

    getSupportedWallets() {
        this.httpService.getSupportedWallets().subscribe(res => {

        }, err => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
}