<div class="app-lend-pool-details-vault" [ngClass]="httpService.theme">
    <div class="grid-container">
        <div class="grid-item">
            <div class="d-flex justify-content-between">
                <div class="start-section">
                    <img class="me-3 img-wd" [src]="pool.image_url" />
                    <div class="multi-line-text-start">
                        <div class="heading">Asset issuer</div>
                        <div class="sub-heading">
                            {{ pool.name }}
                            <a [href]="pool.website" target="_blank" class="redirection-icon"
                                *ngIf="pool.website !== ''">
                                <i class="fa-solid fa-up-right-from-square"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-item">
            <div class="d-flex justify-content-between">
                <div class="start-section">
                    <img class="me-3 img-wd" [src]="pool.pool_network_img_url" />
                    <div class="multi-line-text-start">
                        <div class="heading">Vault Network</div>
                        <div class="sub-heading">
                            {{ pool.pool_network | titlecase }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-item">
            <div class="d-flex justify-content-between">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <div class="heading">
                            <div>Total APR</div>
                            <img [matTooltip]="total_apr_tooltip_text" class="tooltip-img"
                                src="../../assets/images/tooltip.png" />
                        </div>
                        <div class="sub-heading">
                            {{ selected_lending_yield_rate.apy }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-item">
            <div class="d-flex justify-content-between">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <div class="heading">Vault Utilisation</div>
                        <div class="sub-heading">
                            {{ pool_utilisation }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-item">
            <div class="d-flex justify-content-between">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <div class="heading">Tenure</div>
                        <div class="sub-heading">
                            {{
                            selected_lending_yield_rate.days === 0
                            ? "Flexible"
                            : selected_lending_yield_rate.days +
                            " days"
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="web-view">
        <ul class="mt-3">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Vault Asset : </span>
                        <img [src]="
                                    httpService.getCurrencyUrl(pool.pool_asset)
                                " class="pool-asset-icon" />
                        <span class="sub-heading">{{
                            pool.pool_asset | uppercase
                            }}</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Vault Size : </span>
                        <span class="sub-heading">{{
                            pool.pool_size_usd | number : "0.0-0"
                            }}
                            USD</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Vault Token : </span>
                        <span class="sub-heading ellipses">{{
                            pool.lp_currency_mint_address
                            }}</span>
                        <i class="fa-solid fa-up-right-from-square redirection-icon"
                            (click)="
                                    httpService.redirectionAddress(pool.blockchain, pool.chain, pool.network, pool.lp_currency_mint_address)"></i>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Vault Address : </span>
                        <span class="sub-heading">{{ pool.pool_pda }}</span>
                        <i class="fa-solid fa-up-right-from-square redirection-icon" (click)="
                                    httpService.redirectionAddress(pool.blockchain, pool.chain, pool.network, pool.pool_pda)"></i>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">USDC APR : </span>
                        <span class="sub-heading">{{
                            selected_lending_yield_rate.currency_percentage
                            }}%</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card" *ngIf="pool?.borrower_overview?.rating_agency_equivalent">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Rating Type : </span>
                        <span class="sub-heading">Indicative</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card" *ngIf="pool?.borrower_overview?.rating_agency_equivalent">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Rating Agency Equivalent :
                        </span>
                        <span class="sub-heading">{{
                            pool.borrower_overview.rating_agency_equivalent
                            }}</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card" *ngIf="pool?.borrower_overview?.implied_pd">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Implied PD : </span>
                        <span class="sub-heading">{{ pool.borrower_overview.implied_pd }}%</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card" *ngIf="pool?.borrower_overview?.repayment_frequency_days">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Repayment Frequency : </span>
                        <span class="sub-heading">{{
                            pool.borrower_overview
                            .repayment_frequency_days
                            }}
                            days</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">CRED APR : </span>
                        <span class="sub-heading">{{
                            this.selected_lending_yield_rate
                            .cred_percentage
                            }}%</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="mobile-view">
        <ul class="mt-3">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Vault Asset : </span>
                        <img [src]="
                                    httpService.getCurrencyUrl(pool.pool_asset)
                                " class="pool-asset-icon" />
                        <span class="sub-heading">{{
                            pool.pool_asset | uppercase
                            }}</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Vault Size : </span>
                        <span class="sub-heading">{{
                            pool.pool_size_usd | number : "0.0-0"
                            }}
                            USD</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Vault Token : </span>
                        <span class="sub-heading ellipses">{{
                            pool.lp_currency_mint_address
                            }}</span>
                        <i class="fa-solid fa-up-right-from-square redirection-icon" (click)="
                                    httpService.redirectionAddress(pool.blockchain, pool.chain, pool.network, pool.lp_currency_mint_address
                                    )
                                "></i>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Vault Address : </span>
                        <span class="sub-heading">{{ pool.pool_pda }}</span>
                        <i class="fa-solid fa-up-right-from-square redirection-icon" (click)="
                                    httpService.redirectionAddress(pool.blockchain, pool.chain, pool.network, pool.pool_pda)"></i>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">USDC APR : </span>
                        <span class="sub-heading">{{
                            selected_lending_yield_rate.currency_percentage
                            }}%</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">CRED APR : </span>
                        <span class="sub-heading">{{
                            this.selected_lending_yield_rate
                            .cred_percentage
                            }}%</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Implied PD : </span>
                        <span class="sub-heading">{{ pool.borrower_overview.implied_pd }}%</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Rating Type : </span>
                        <span class="sub-heading">Indicative</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Rating Agency Equivalent :
                        </span>
                        <span class="sub-heading">{{
                            pool.borrower_overview.rating_agency_equivalent
                            }}</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Repayment Frequency : </span>
                        <span class="sub-heading">{{
                            pool.borrower_overview
                            .repayment_frequency_days
                            }}
                            days</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="mt-3 normal-text" *ngIf="pool?.borrower_overview?.rating_agency_equivalent">
        Ratings and credit assessments provided by
        <a [href]="pool.borrower_overview.rating_agency_url" target="_blank">{{ pool.borrower_overview.rating_agency
            }}</a>
    </div>

    <hr />

    <div class="temp">
        <div class="left">
            <div class="graph-heading">VAULT UTILISATION</div>
            <div class="row" *ngIf="!chart_loading">
                <app-line-chart (showCharts)="showCharts($event)" [lineConfig]="chartLineConfig"
                    [title]="chartTitle" [currency]="'USDC'"></app-line-chart>
            </div>
            <div class="button-menu">
                <app-new-button-menu showButtonType="lableBorderButton" [pool_id]="pool_id"
                    [defaultTabIndex]="tabIndexVault" [labelArray]="labelArrayVault"
                    (buttonClick)="onTabChangeVault($event)"></app-new-button-menu>
            </div>
            <app-vault-allocation *ngIf="tabIndexVault === 0" [pool_id]="pool_id"></app-vault-allocation>
            <div class="button-menu">
                <app-new-button-menu showButtonType="lableBorderButton" [pool_id]="pool_id"
                    [defaultTabIndex]="tabIndexPool" [labelArray]="labelArrayPool"
                    (buttonClick)="onTabChangePool($event)"></app-new-button-menu>
            </div>
            <app-pool-activity *ngIf="tabIndexPool === 0" [pool_id]="pool_id"></app-pool-activity>
            <app-lend-pool-transactions *ngIf="tabIndexPool === 1" [pool_id]="pool_id"></app-lend-pool-transactions>
            <app-lend-pool-withdrawals *ngIf="tabIndexPool === 2" [pool_id]="pool_id"></app-lend-pool-withdrawals>
        </div>
        <div class="right">
            <app-lend-widget [pool_id]="pool.pool_id"></app-lend-widget>
            <div class="row overview">
                <div class="section-heading">VAULT REWARDS</div>
                <div class="section">
                    <div class="d-flex justify-content-between">
                        <div class="start-section">
                            <img class="me-3 img-wd" src="https://assets.credible.finance/Credibleicon.png" />
                            <div class="multi-line-text-start">
                                <div class="heading">Accrued</div>
                                <div class="sub-heading">
                                    {{
                                    total_cred_points | number : "0.0-4"
                                    }}
                                    CRED Tokens
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-4 claim-reward">
                        <cf-button label="Claim Reward" [disabled]="true" type="solid" (mouseover)="showTooltip()"
                            (mouseout)="hideTooltip()"></cf-button>
                        <div class="tooltip-container">
                            <span class="tooltip-text" [ngClass]="{
                                        'show-tooltip': isTooltipVisible
                                    }">
                                You cannot claim this reward right now.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row overview mt-4">
                <div class="section-heading">VAULT OVERVIEW</div>
                <div class="section">
                    <div class="section-item overview-border-bottom">
                        <div class="field-value-extra">
                            <span class="field-heading">Network</span>
                            <img matTooltip="Blockchain network the pool operates on" class="tooltip-img"
                                src="../../assets/images/tooltip.png" />
                        </div>
                        <span class="field-value">{{
                            pool.pool_network | titlecase
                            }}</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <span class="field-heading">Vault Token</span>
                        <div class="field-value-extra">
                            <div class="row field-value">
                                {{ pool.lp_currency_mint_address }}
                            </div>
                            <i class="fa-solid fa-up-right-from-square redirection-icon-right"
                                (click)="
                                        httpService.redirectionAddress(pool.blockchain, pool.chain, pool.network, pool.lp_currency_mint_address)"></i>
                        </div>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <span class="field-heading">Vault Size</span>
                        <span class="field-value">${{
                            pool.pool_size_usd | number : "0.0-0"
                            }}</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <span class="field-heading">Vault Utilisation</span>
                        <span class="field-value">{{ pool_utilisation }}%</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <div class="field-value-extra">
                            <span class="field-heading">Vault Size Cap</span>
                            <img matTooltip="Deposit capacity of this pool" class="tooltip-img"
                                src="../../assets/images/tooltip.png" />
                        </div>
                        <span class="field-value">{{
                            pool.pool_size_cap_usd > 0
                            ? pool.pool_size_cap_usd
                            : "No Cap"
                            }}</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <div class="field-value-extra">
                            <span class="field-heading">Collected Fees</span>
                            <img matTooltip="Collected revenue" class="tooltip-img"
                                src="../../assets/images/tooltip.png" />
                        </div>
                        <span class="field-value">{{
                            pool.stats.principal_withdrawal_fee
                            }}
                            USDC</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <span class="field-heading">Supply APR (total)</span>
                        <span class="field-value">{{ selected_lending_yield_rate.apy }}%</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <div class="field-value-extra">
                            <span class="field-heading">APR paid in USDC</span>
                            <img matTooltip="Interest rate for USDC" class="tooltip-img"
                                src="../../assets/images/tooltip.png" />
                        </div>
                        <span class="field-value">{{
                            selected_lending_yield_rate.currency_percentage
                            }}%</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <div class="field-value-extra">
                            <span class="field-heading">APR paid in CRED</span>
                            <img [matTooltip]="'CRED token incentives for lenders'" class="tooltip-img"
                                src="../../assets/images/tooltip.png" />
                        </div>
                        <span class="field-value">{{
                            selected_lending_yield_rate.cred_percentage
                            }}%</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <span class="field-heading">Tokenize APR</span>
                        <span class="field-value">{{ pool.borrow_yield_rate }}%</span>
                    </div>
                    <div class="section-item">
                        <div class="field-value-extra">
                            <span class="field-heading">KYC</span>
                            <img matTooltip="Indicates whether or not lenders are required to KYC" class="tooltip-img"
                                src="../../assets/images/tooltip.png" />
                        </div>
                        <span class="field-value">{{
                            pool.kyc_required ? "Required" : "Not Required"
                            }}</span>
                    </div>
                </div>
            </div>
            <div class="row overview mt-4" *ngIf="pool.borrower_overview.rating_agency_equivalent">
                <div class="section-heading">TOKENIZE OVERVIEW</div>
                <div class="section">
                    <div class="section-item overview-border-bottom">
                        <div class="field-heading">Name</div>
                        <span class="field-value">{{ pool.name }}</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <div class="field-value-extra">
                            <span class="field-heading">Rating Type</span>
                        </div>
                        <span class="field-value">Indicative</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <div class="field-value-extra">
                            <span class="field-heading">Rating Agency Equivalent</span>
                            <img matTooltip="Headline Credit Rating anchored to traditional rating agency scale."
                                class="tooltip-img" src="../../assets/images/tooltip.png" />
                        </div>
                        <span class="field-value">{{
                            pool.borrower_overview.rating_agency_equivalent
                            }}</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <div class="field-value-extra">
                            <span class="field-heading">Implied PD</span>
                            <img matTooltip="Implied probability of default for specified tenor. Derived using the Benchmark Rating and historical default rates."
                                class="tooltip-img" src="../../assets/images/tooltip.png" />
                        </div>
                        <span class="field-value">{{ pool.borrower_overview.implied_pd }}%</span>
                    </div>
                    <div class="section-item overview-border-bottom">
                        <div class="field-heading">Data room</div>
                        <span class="field-value">
                            <a [href]="
                                        pool.borrower_overview.data_room_url
                                    " target="_blank">
                                Link
                            </a>
                        </span>
                    </div>
                    <div class="section-item">
                        <div class="field-value-extra">
                            <span class="field-heading">Repayment frequency</span>
                            <img matTooltip="How often a borrower makes repayments" class="tooltip-img"
                                src="../../assets/images/tooltip.png" />
                        </div>
                        <span class="field-value">{{
                            pool.borrower_overview
                            .repayment_frequency_days
                            }}
                            days</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>