import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/localstorage.service';
import { serverUrl } from './app.config';

@Injectable({
    providedIn: 'root'
})
export class AmbassadorService {
    constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
    ) {}

    getOnboardingStats(level: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}ambassador/getOnboardingStats?level=${level}`, httpOptions);
    }

    getOnboardingChart(level: string, duration: string, type: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}ambassador/getOnboardingChart?level=${level}&duration=${duration}&type=${type}`, httpOptions);
    }

    getTvlStats(level: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}ambassador/getTvlStats?level=${level}`, httpOptions);
    }

    getTvlChart(level: string, duration: string, type: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}ambassador/getTvlChart?level=${level}&duration=${duration}&type=${type}`, httpOptions);
    }

    getTopTvlUsers(level: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}ambassador/getTopTvlUsers?level=${level}`, httpOptions);
    }

    getReferralLevels() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}ambassador/getReferralLevels`, httpOptions);
    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }
}
