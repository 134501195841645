import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, Input, OnChanges, OnInit,
    PLATFORM_ID,
    SimpleChanges
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

import { Router} from '@angular/router';

import { WalletService } from '../../services/wallet.service';
import {
    Connection, Transaction
} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import PrivateCreditPool from '../../models/PrivateCreditPool';
import { nullChecker } from '../../helpers/nullChecker';
import { isPlatformBrowser } from '@angular/common';
import { CircleService } from '../circle.service';
import { MetamaskService } from '../metamask.service';
import { ChangeBlockchainDialogComponent } from '../change-blockchain-dialog/change-blockchain-dialog.component';
import {
    MatDialogRef, MatDialog 
} from '@angular/material/dialog';
import { ChangeChainDialogComponent } from '../change-chain-dialog/change-chain-dialog.component';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';
import IPrivateCreditPool from '../../interfaces/IPrivateCreditPool';
import { KycService } from '../../services/kyc.service';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-new-investment',
    templateUrl: './new-investment.component.html',
    styleUrls: [ './new-investment.component.scss' ]

})
export class NewInvestmentComponent implements OnInit, OnChanges {
    selected_duration: number = 0;
    durationDropdownOptions: Array<IDropdown> = [];
    selectedDurationItem: any;
    platformId: object = {};
    isBrowser: boolean = false;

    currentMintAddressMap: any = {};
    paymentCurrencyDropdownOptions: Array<IDropdown> = [];
    payment_currency: string = 'usdc';

    payment_amount: number = 0;
    annual_interest: number = 0;
    annual_interest_rate: number = 0;
    cred_points: number = 0;
    cred_percentage: number = 0;
    interest: number = 0;
    pools: Array<IDropdown> = [];

    lending_id: string = '';
    pool: IPrivateCreditPool = new PrivateCreditPool();
    balance: number = 0;
    gas_fees_balance: number = 0;

    loading: boolean = false;
    new_investment_loading: boolean = false;

    transaction: any;

    vault_dropdown_options: Array<IDropdown> = [];
    selected_vault: any;
    selected_vault_id: string = '';
    additionalYieldLabel: string = 'CRED';

    showConfirmButton: boolean = false;
    showAllowanceButton: boolean = false;

    @Input() pool_id: string = '';

    differentNetwork: boolean = false;
    differentBlockchain: boolean = false;

    approval_txid: string = '';
    window: Window = getWindow();

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private phantomService: PhantomService,
        private metamaskService: MetamaskService,
        private solflareService: SolflareService,
        private walletService: WalletService,
        private circleService: CircleService,
        public blockchainDialogRef: MatDialogRef<ChangeBlockchainDialogComponent>,
        public networkDialogRef: MatDialogRef<ChangeChainDialogComponent>,
        public dialog: MatDialog,
        private kycService: KycService,
        @Inject(PLATFORM_ID) platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['pool_id'] && changes['pool_id'].currentValue) {
            this.pool_id = changes['pool_id'].currentValue;

            this.getPoolDetails();

            console.log('pool_id', this.pool_id);

            if (this.pool_id === 'badx61jpjr')
                this.additionalYieldLabel = 'CETES';
        }
    }

    getPoolDetails() {
        this.loading = true;

        this.httpService.getPoolDetails(this.pool_id).subscribe(res => {
            this.pool = res.data;

            if (this.pool.blockchain !== this.httpService.user.blockchain) 
                this.openChangeBlockchainDialog();

            else
                this.openChangeChainDialog();

            if (this.pool.blockchain === 'solana') {
                this.showConfirmButton = true;
                this.showAllowanceButton = false;
            }

            else {
                this.showConfirmButton = false;
                this.showAllowanceButton = true;
            }

            this.getPaymentCurrencyDropdownOptions(this.pool.blockchain, this.pool.chain);
            this.getGasFeeBalance(this.pool.blockchain, this.pool.chain);

            this.annual_interest_rate = this.pool.lending_yield_rates[0].currency_percentage;
            this.cred_percentage = !nullChecker(this.pool.lending_yield_rates[0].cred_percentage) ? this.pool.lending_yield_rates[0].cred_percentage : 0;

            this.setDurationDropdownOptions();

            this.selectedDurationItem = this.durationDropdownOptions[0];
            this.selected_duration = parseInt(this.durationDropdownOptions[0].value);

            this.payment_amount = this.pool.min_lending_amount_per_tx;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            this.loading = false;

            console.error(err);

            this.cfAlertService.showError(err);
            this.backToPools();
        });
    }

    getPaymentCurrencyDropdownOptions(blockchain: string, chain: string) {
        this.httpService.getLendingCurrencies(blockchain, chain).subscribe(res => {
            const paymentCurrencyDropdownOptions: Array<IDropdown> = [];
            const currencies = res.data;
            
            currencies.forEach((element: any) => {
                this.currentMintAddressMap[element.currency_key] = element.mint_address;

                paymentCurrencyDropdownOptions.push({
                    label: element.currency_name,
                    value: element.currency_key,
                    logo: element.logo,
                    optionEnabled: true
                });
            });

            this.paymentCurrencyDropdownOptions = paymentCurrencyDropdownOptions;
            
            if (currencies.length > 0)
                this.paymentCurrencyChanged(this.paymentCurrencyDropdownOptions[0]);
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    setDurationDropdownOptions() {
        const durationDropdownOptions: Array<IDropdown> = [];

        this.pool.lending_yield_rates.forEach((x) => {
            durationDropdownOptions.push({
                label: x.days === 0 ? 'Flexible' : `${x.days} days`,
                value: x.days.toString(),
                description: `${this.getInterestRate(x.days)}% APY`,
                optionEnabled: true
            });
        });

        this.durationDropdownOptions = durationDropdownOptions;

        if (this.durationDropdownOptions.length > 0)
            this.changeDuration(this.durationDropdownOptions[0]);
    }

    getCurrencyBalance(blockchain: string, chain: string, wallet_address: string, mint_address: string) {
        if (!this.isBrowser || this.differentBlockchain || this.differentNetwork)
            return;

        this.httpService.getCurrencyBalance(blockchain, chain, wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getGasFeeBalance(blockchain: string, chain: string) {
        if (this.differentBlockchain || this.differentNetwork)
            return;

        const wallet_address = this.httpService.user.wallet_address;

        this.httpService.getCurrencyBalance(blockchain, chain, wallet_address).subscribe((res: any) => {
            this.gas_fees_balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
    
    paymentCurrencyChanged(option: IDropdown) {
        this.payment_currency = option.value;

        this.balance = 0;

        this.annual_interest = this.payment_amount * this.annual_interest_rate / 100;
        this.cred_points = Math.round(this.payment_amount * (this.cred_percentage / 100));

        this.interest = this.getTotalInterest();

        const mint_address = this.currentMintAddressMap[this.payment_currency];

        this.getCurrencyBalance(this.pool.blockchain, this.pool.chain, this.httpService.user.wallet_address, mint_address);
    }

    paymentAmountChanged(payment_amount: number) {
        if (!payment_amount)
            payment_amount = 0;

        this.payment_amount = parseFloat(payment_amount.toString());

        this.annual_interest = this.payment_amount * this.annual_interest_rate / 100;
        this.cred_points = Math.round(this.payment_amount * (this.cred_percentage / 100));

        this.interest = this.getTotalInterest();
    }

    getInterestRate(days: number) {
        const item = this.pool.lending_yield_rates.find(x => x.days === days);

        return item ? item.currency_percentage : 0;
    }

    getCredPercentage(days: number) {
        const item = this.pool.lending_yield_rates.find(x => x.days === days);

        return item ? item.cred_percentage : 0;
    }

    changeDuration(event: any) {
        this.selected_duration = parseInt(event.value);

        this.annual_interest_rate = this.getInterestRate(this.selected_duration);
        this.cred_percentage = this.getCredPercentage(this.selected_duration);

        this.annual_interest = this.payment_amount * this.annual_interest_rate / 100;
        this.cred_points = Math.round(this.payment_amount * (this.cred_percentage / 100));

        this.interest = this.getTotalInterest();
    }

    getTotalInterest() {
        // flexible
        if (this.selected_duration === 0)
            return this.annual_interest;

        return this.annual_interest / 12 * this.selected_duration;
    }

    async approveAllowance() {
        const networkMatched = await this.openChangeChainDialog();

        if (!networkMatched)
            return;

        if (isNaN(this.payment_amount) || this.payment_amount === null || this.payment_amount === undefined || this.payment_amount === 0) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (this.balance < this.payment_amount) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        if (this.payment_amount < this.pool.min_lending_amount_per_tx) {
            this.cfAlertService.showMessage(`Minimum investment amount is ${this.pool.min_lending_amount_per_tx} ${this.payment_currency.toUpperCase()}`, true);

            return;
        }

        if (this.gas_fees_balance <= 0) {
            this.cfAlertService.showMessage('Insufficient SOL balance', true);

            return;
        }

        this.new_investment_loading = true;

        const body = {
            amount: this.payment_amount,
            currency: this.payment_currency,
            duration_days: this.selected_duration,
            pool_id: this.pool_id,
            vault_id: ''
        };

        if (!nullChecker(this.selected_vault_id))
            body.vault_id = this.selected_vault_id;

        this.httpService.approveAllowance(body).subscribe((res: any) => {
            this.lending_id = res.data.lending_id;
            
            // If approval is already higher than the amount
            // no need to approve again
            // so directly create investment
            if ('abi' in res.data) {
                const abi = res.data.abi;
                const contract_address = res.data.contract_address;
                const gas = res.data.gas;

                console.log('allowance_abi', abi);
                console.log('allowance_contract_address', contract_address);
                console.log('allowance_gas', gas);
                
                this.sendEthereumAllowanceTransaction(abi, contract_address, gas);
            }

            else
                this.createInvestment();
        }, (err: HttpErrorResponse) => {
            console.error('approveAllowance error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.new_investment_loading = false;
        });
    }

    createInvestment() {
        if (isNaN(this.payment_amount) || this.payment_amount === null || this.payment_amount === undefined || this.payment_amount === 0) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (this.balance < this.payment_amount) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        if (this.payment_amount < this.pool.min_lending_amount_per_tx) {
            this.cfAlertService.showMessage(`Minimum investment amount is ${this.pool.min_lending_amount_per_tx} ${this.payment_currency.toUpperCase()}`, true);

            return;
        }

        if (this.gas_fees_balance <= 0) {
            this.cfAlertService.showMessage('Insufficient SOL balance', true);

            return;
        }

        this.new_investment_loading = true;

        const body = {
            amount: this.payment_amount,
            currency: this.payment_currency,
            duration_days: this.selected_duration,
            pool_id: this.pool_id,
            approval_txid: this.approval_txid,
            vault_id: ''
        };

        if (!nullChecker(this.selected_vault_id))
            body.vault_id = this.selected_vault_id;

        this.httpService.createInvestment(body).subscribe((res: any) => {
            this.lending_id = res.data.lending_id;
            const challengeId = res.data.challengeId;
    
            if (challengeId)
                this.circleService.executeChallenge(challengeId);
            
            else if (res.data.transaction) {
                const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));
                
                this.sendSolanaTransaction(transaction);
            }

            else if (res.data.abi) {
                const abi = res.data.abi;
                const gas = res.data.gas;

                console.log('lending', abi, gas);
                
                this.sendEthereumTransaction(abi, gas);
            }
        }, (err: HttpErrorResponse) => {
            console.error('createInvestment error');
            console.error(err);

            this.cfAlertService.showError(err);
    
            this.new_investment_loading = false;
        });
    }

    async getSolanaSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    async getEthereumSignature(abi: string, contract_address: string, gas: number) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(abi, contract_address, this.httpService.user.wallet_address, gas);

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    async sendSolanaTransaction(transaction: Transaction) {
        let error_code = -1;
        let txnSignature = '';

        try {
            console.log(transaction);

            const res = await this.getSolanaSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature)
                return;
            
            transaction = res;

            const connection = new Connection(this.pool.rpc, 'confirmed');

            console.log('transaction', transaction);

            txnSignature = await connection.sendRawTransaction(transaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            }));

            console.log('sendSolanaTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            const error_info = new HttpErrorResponse({
                error: err
            });

            const split_items = error_info.error.message.split('custom program error:');

            if (split_items.length > 0) 
                error_code = parseInt(split_items[split_items.length - 1].trim(), 16);

            console.log('sendSolanaTransaction : error_code => ', error_code);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    async sendEthereumAllowanceTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;

        try {
            this.approval_txid = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('allowance_signature', this.approval_txid);

            this.cfAlertService.showMessage('Allowance succesful');

            this.createInvestment();
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }
    }

    async sendEthereumTransaction(lending_abi: string, lending_gas: number) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(lending_abi, this.pool.program_id, lending_gas);

            console.log('sendEthereumTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    updateSignature(signature: string, error_code: number) {
        const body = {
            lending_id: this.lending_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.httpService.updateInvestment(body).subscribe((res: any) => {
            this.new_investment_loading = false;

            this.cfAlertService.showMessage('Investment is under process', false);

            this.router.navigateByUrl('/transactions?tab=my-supply');
        }, (err: HttpErrorResponse) => {
            console.error('updateInvestment error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_investment_loading = false;
        });
    }

    backToPools() {
        this.router.navigate([ `/pools/${this.pool_id}` ]);
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    getVaults() {
        const data = {
            pool_id: this.pool_id
        };

        this.httpService.getVaults(data).subscribe(res => {
            res.data.forEach((x: any) => {
                this.vault_dropdown_options.push({
                    label: x.name,
                    value: x.pool_id,
                    description: x.name,
                    logo: x.image_url,
                    optionEnabled: true
                });
            });

            if (this.vault_dropdown_options.length > 0) {
                this.selected_vault = this.vault_dropdown_options[0];
                this.selected_vault_id = this.selected_vault.value;
            }
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    changeVault(event: any) {
        console.log('changeVault => ', event.value);

        this.selected_vault_id = event.value;
    }

    openChangeBlockchainDialog() {
        this.differentBlockchain = true;
        
        this.blockchainDialogRef = this.dialog.open(ChangeBlockchainDialogComponent, {
            width: '500px',
            data: {
                expected_blockchain: this.pool.blockchain,
                current_blockchain: this.httpService.user.blockchain
            }
        });

        this.blockchainDialogRef.afterClosed().subscribe(result => {
            if (result) 
                console.log('result', result);
        });
    }

    async openChangeChainDialog() {
        if (this.walletService.blockchain === 'solana') {
            if (this.pool.chain === 'solana')
                return true;
        }
        
        const chainId = await this.metamaskService.getChainId();
        const chainIdDecimal = parseInt(chainId, 16);

        const currentChainInfo = ETHEREUM_CHAINS[chainIdDecimal];

        if (this.pool.chain_id === currentChainInfo.chainId)
            return true;

        this.differentNetwork = true;

        this.networkDialogRef = this.dialog.open(ChangeChainDialogComponent, {
            width: '500px',
            data: {
                expected_chain: this.pool.chain_id,
                current_chain: currentChainInfo.chainId
            }
        });

        this.networkDialogRef.afterClosed().subscribe(result => {
            if (result) 
                console.log('result', result);
        });

        return false;
    }

    generateKycUrl() {
        this.loading = true;

        this.kycService.generateKycUrl(this.httpService.user).subscribe((res: any) => {
            this.loading = false;

            this.window.location.href = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }
}
