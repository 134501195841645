<div class="app-borrow-widget" [ngClass]="httpService.theme">
    <div class="borrow-widget-sub-section">
        <div class="borrow-widget-sub-section-tabs">
            <div class="button-container mb-4">
                <button type="button" class="button" [ngClass]="currentTab === 'drawdown' ? 'active' :''"
                    (click)="changedTab('drawdown')">
                    Drawdown
                </button>
                <button type="button" class="button" [ngClass]="currentTab === 'repay' ? 'active' :''"
                    (click)="changedTab('repay')">
                    Repay
                </button>

            </div>
        </div>

        <div class="tabs">
            <app-new-drawdown [pool_id]="pool_id" *ngIf="currentTab === 'drawdown'"></app-new-drawdown>
            <app-new-repayment *ngIf="currentTab === 'repay'" [drawdown_id]="drawdown_id"></app-new-repayment>
        </div>
    </div>
</div>