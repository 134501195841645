<div class="app-new-payfi-drawdown dark">
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>

    <div *ngIf="!loading">
        <div class="sidebar-and-steps">
            <div class="steps-web">
                <div class="steps-heading">
                    <div *ngFor="let step of steps; let i = index" class="step" [ngClass]="{
                        'step-active': i === currentStep,
                        'step-complete': step.completed
                    }" (click)="changeStep(i)">
                        <div class="step-counter">
                            <span *ngIf="!step.completed">{{ i + 1 }}</span>
                            <i class="fa-solid fa-check" *ngIf="step.completed"></i>
                        </div>
                        <div class="step-title">{{ step.title }}</div>
                        <div class="step-divider">
                            <hr />
                        </div>
                    </div>
                </div>

                <div class="steps-content">
                    <div *ngIf="steps[currentStep]?.step === 'payout'" class="step-content">
                        <div class="field">
                            <cf-dropdown [theme]="httpService.theme" [options]="received_currency_dropdown_options"
                                label="Payout currency" (change)="receiveCurrencyChanged($event)"
                                [selected]="received_currency" [showLogo]="true">
                            </cf-dropdown>
                        </div>

                        <div class="field">
                            <cf-input-currency [theme]="httpService.theme" [value]="received_amount" label="You borrow"
                                type="number" [currency]="received_currency"
                                [currencyLogo]="httpService.getCurrencyUrl(received_currency)"
                                (valueChanged)="loanAmountChanged($event)" [precision]="2">
                            </cf-input-currency>
                            <span class="equivalent-amount">Min: {{ min_payout_amount |
                                number:received_currency_precision}} {{ received_currency | uppercase }} </span>
                        </div>

                        <div class="actions">
                            <cf-button [theme]="httpService.theme" label="Next" type="solid"
                                (clicked)="next1Completed()"></cf-button>
                        </div>
                    </div>
                    <div *ngIf="steps[currentStep]?.step === 'tenure'" class="step-content">
                        <div class="field">
                            <cf-dropdown [theme]="httpService.theme" [options]="tenure_dropdown_options" label="Tenure"
                                [selected]="tenure" (change)="tenureChanged($event)" [showLogo]="false">
                            </cf-dropdown>
                        </div>

                        <div class="actions">
                            <cf-button [theme]="httpService.theme" label="Previous" type="outline"
                                (clicked)="previous2Completed()"></cf-button>

                            <cf-button [theme]="httpService.theme" label="Next" type="solid"
                                (clicked)="next2Completed()"></cf-button>
                        </div>
                    </div>
                    <div *ngIf="steps[currentStep]?.step === 'payment-method'" class="step-content">
                        <div class="field">
                            <cf-dropdown [theme]="httpService.theme" [options]="payment_method_options"
                                label="Payment methods" (change)="changePaymentMethod($event)"
                                [selected]="selected_payment_method_id" [showLogo]="false">
                            </cf-dropdown>
                        </div>

                        <div class="field">
                            <cf-dropdown [theme]="httpService.theme" [options]="bank_options"
                                label="Beneficiary account" (change)="changeBank($event)" [selected]="selected_bank_id"
                                [showLogo]="false">
                            </cf-dropdown>
                        </div>

                        <div class="field" *ngIf="selected_bank_id === 'new'">
                            <cf-checkbox-large [options]="bank_type_options" label="Account type"
                                (changed)="changeBankTyepOption($event)" [selected]="bank_type"></cf-checkbox-large>
                        </div>

                        <div class="field" *ngIf="selected_bank_id === 'new'">
                            <cf-input [theme]="httpService.theme" label="Account holder name"
                                [value]="account_holder_name" (valueChanged)="accountHolderNameChanged($event)"
                                placeholder="Account holder name"></cf-input>
                        </div>
                        <div class="field" *ngIf="selected_bank_id === 'new'">
                            <cf-input [theme]="httpService.theme" label="Account number" [value]="account_number"
                                (valueChanged)="accountNumberChanged($event)" placeholder="Account number"></cf-input>

                        </div>
                        <div class="field" *ngIf="selected_bank_id === 'new'">
                            <cf-input [theme]="httpService.theme" label="IFSC Code" placeholder="IFSC code"
                                [value]="ifsc" (valueChanged)="ifscCodeChanged($event)"></cf-input>
                        </div>

                        <div class="field" *ngIf="selected_bank_id === 'new'">
                            <cf-input [theme]="httpService.theme" label="Bank Name" placeholder="Bank name"
                                [value]="bank_name" (valueChanged)="bankNameChanged($event)"></cf-input>
                        </div>

                        <!-- <div class="new-payfi-drawdown-summary" *ngIf="selected_bank_id === 'new'">
                            <div class="summary-item">
                                <span class="summary-heading">Bank name</span>
                                <span class="summary-value"></span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">Bank branch</span>
                                <span class="summary-value"></span>
                            </div>
                        </div> -->

                        <div class="actions">
                            <cf-button [theme]="httpService.theme" label="Previous" type="outline"
                                (clicked)="previous3Completed()"></cf-button>

                            <cf-button [theme]="httpService.theme" label="Next" type="solid"
                                (clicked)="next3Completed()" [loading]="bank_add_loading"></cf-button>
                        </div>
                    </div>
                    <div *ngIf="steps[currentStep]?.step === 'collateral'" class="step-content">
                        <div class="field">
                            <cf-dropdown [theme]="httpService.theme" [options]="collateral_options"
                                label="Collateral currency" (change)="changeCollateralCurrency($event)"
                                [selected]="collateral_currency" [showLogo]="true">
                            </cf-dropdown>
                        </div>

                        <div class="actions">
                            <cf-button [theme]="httpService.theme" label="Previous" type="outline"
                                (clicked)="previous4Completed()"></cf-button>

                            <cf-button [theme]="httpService.theme" label="Next" type="solid"
                                (clicked)="next4Completed()"></cf-button>
                        </div>
                    </div>

                    <div *ngIf="steps[currentStep]?.step === 'summary'" class="step-content">
                        <div class="new-payfi-drawdown-summary">
                            <div class="progress-bar-holder">
                                <div class="progress-bar" [style.width.%]="timer / initial_timer * 100"></div>
                            </div>

                            <div class="summary-content">
                                <div class="summary-item">
                                    <span class="summary-heading">Approved LTV</span>
                                    <span class="summary-value">{{ selected_pool.approved_ltv_percentage }}%</span>
                                </div>
                                <div class="summary-item">
                                    <span class="summary-heading">Fees ({{ selected_pool.drawdown_fee_percentage
                                        }}%)</span>
                                    <span class="summary-value">{{ fee | number:received_currency_precision }} {{
                                        received_currency | uppercase }}</span>
                                </div>
                                <div class="summary-item">
                                    <span class="summary-heading">Conversion rate</span>
                                    <span class="summary-value">1 {{ collateral_currency | uppercase }} = {{
                                        conversion_rate
                                        | number :
                                        received_currency_precision }}
                                        {{ received_currency | uppercase }}</span>
                                </div>
                                <div class="summary-item">
                                    <span class="summary-heading">Repayment Frequency</span>
                                    <span class="summary-value">{{ selected_pool.repayment_frequency_days }}
                                        days</span>
                                </div>
                                <div class="summary-item">
                                    <span class="summary-heading">Interest rate</span>
                                    <span class="summary-value">{{ selected_pool.drawdown_yield_rate }}%</span>
                                </div>
                                <div class="summary-item">
                                    <span class="summary-heading">
                                        Repayment amount
                                        <i class="fa-solid fa-circle-info"
                                            [matTooltip]="'Repayment amount to be paid for every ' + selected_pool.repayment_frequency_days + ' days'"></i>
                                    </span>
                                    <span class="summary-value" style="color: #cf0;">{{ repayment_amount | number :
                                        received_currency_precision }} {{
                                        received_currency |
                                        uppercase }}</span>
                                </div>
                                <div class="summary-item">
                                    <span class="summary-heading">
                                        Total interest
                                        <i class="fa-solid fa-circle-info"
                                            [matTooltip]="'Total interest amount for ' + tenure + ' days'"></i>
                                    </span>
                                    <span class="summary-value">{{ interest | number : received_currency_precision }}
                                        {{ received_currency |
                                        uppercase }}</span>
                                </div>
                                <div class="summary-item">
                                    <span class="summary-heading">Existing Collateral</span>
                                    <span class="summary-value">$ {{ userCollateralInfo?.collateral_usd | number:'0.0-2'
                                        }}</span>
                                </div>
                                <div class="summary-item">
                                    <span class="summary-heading">Existing Drawdown</span>
                                    <span class="summary-value">$ {{ userCollateralInfo?.dropdown_usd | number:'0.0-2'
                                        }}</span>
                                </div>
                                <div class="summary-item">
                                    <span class="summary-heading">New collateral required</span>
                                    <span class="summary-value" style="color: #cf0;" *ngIf="additional_collateral > 0">
                                        $ {{ additional_collateral | number:'0.0-2' }}
                                    </span>
                                    <span class="summary-value" style="color: #cf0;" *ngIf="additional_collateral <= 0">
                                        Not required
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="actions">
                            <cf-button [theme]="httpService.theme" label="Previous" type="outline"
                                (clicked)="previous5Completed()"></cf-button>

                            <div *ngIf="!new_drawdown_loading">
                                <cf-button [label]="confirm_btn_label" type="solid"
                                    [disabled]="httpService.user.wallet_address === ''"
                                    (clicked)="createDrawdown()"></cf-button>
                                <span class="equivalent-amount">
                                    Available:
                                    {{ balance | number : collateral_currency_precision }}
                                    {{ collateral_currency | uppercase }}
                                </span>
                            </div>
                            <div *ngIf="new_drawdown_loading">
                                <cf-loader></cf-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="steps-mobile">
                <div class="step-container" *ngFor="let step of steps; let i = index">
                    <div class="step-heading">
                        <div class="step" [ngClass]="{
                                    'step-active': i === currentStep,
                                    'step-complete': step.completed
                                }" (click)="changeStep(i)">
                            <div class="step-counter">
                                <span *ngIf="!step.completed">{{ i + 1 }}</span>
                                <i class="fa-solid fa-check" *ngIf="step.completed"></i>
                            </div>
                            <div class="step-title">{{ step.title }}</div>
                        </div>
                    </div>

                    <div class="step-content">
                        <cf-card [theme]="httpService.theme" type="empty"
                            *ngIf="currentStep === i && steps[currentStep]?.step === 'payout'"></cf-card>

                        <cf-card [theme]="httpService.theme" type="empty"
                            *ngIf="currentStep === i && steps[currentStep]?.step === 'tenure'"></cf-card>

                        <cf-card [theme]="httpService.theme" type="empty"
                            *ngIf="currentStep === i && steps[currentStep]?.step === 'payment-method'"></cf-card>

                        <cf-card [theme]="httpService.theme" type="empty"
                            *ngIf="currentStep === i && steps[currentStep]?.step === 'collateral'"></cf-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>