import {
    Component, OnInit 
} from '@angular/core';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { HttpService } from '../../../services/http.service';
import { CircleService } from '../../circle.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { nullChecker } from '../../../helpers/nullChecker';
import { ChallengeStatus } from '@circle-fin/w3s-pw-web-sdk/dist/src/types';
import { getWindow } from 'ssr-window';
import { circle_app_id } from '../../app.config';
import { LocalStorageService } from '../../../services/localstorage.service';

@Component({
    selector: 'app-circle-new-withdrawal',
    templateUrl: './circle-new-withdrawal.component.html',
    styleUrl: './circle-new-withdrawal.component.scss'
})
export class CircleNewWithdrawalComponent implements OnInit {
    currencyOptions: Array<IDropdown> = [{
        label: 'USDC',
        value: 'usdc',
        logo: this.httpService.getCurrencyUrl('usdc'),
        optionEnabled: true
    }, {
        label: 'Solana',
        value: 'sol',
        logo: this.httpService.getCurrencyUrl('sol'),
        optionEnabled: true
    }];
    selectedCurrency: string = '';

    networkOptions: Array<IDropdown> = [{
        label: 'Solana',
        value: 'SOL-DEVNET',
        logo: this.httpService.getCurrencyUrl('sol'),
        optionEnabled: true
    }];
    selectedNetwork: string = '';

    window: Window;

    amount: string = '';
    destination_address: string = '';
    withdrawal_otp: string = '';
    showVerificationCode: boolean = false;
    loading: boolean = false;
    network_fees: number = 0;
    min_withdrawal: number = 10;

    circle_user_token: string = '';
    circle_encryption_key: string = '';
    app_id: string = circle_app_id;

    constructor(
        public httpService: HttpService,
        private circleService: CircleService,
        private cfAlertService: CfAlertService,
        private localStorageService: LocalStorageService
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.selectedCurrency = this.currencyOptions[0].value;
        this.selectedNetwork = this.networkOptions[0].value;
    }

    getWithdrawalFee() {
        if (nullChecker(this.amount) || parseFloat(this.amount) <= 0 || nullChecker(this.selectedNetwork) || nullChecker(this.destination_address)) {
            this.network_fees = 0;

            return;
        }

        const body = {
            amount: this.amount,
            currency: this.selectedCurrency,
            destination_address: this.destination_address
        };

        this.circleService.getWithdrawalFee(body).subscribe((res: any) => {
            this.network_fees = res.data.high.networkFee;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    changeCurrency(option: IDropdown): void {
        this.selectedCurrency = option.value;

        this.getWithdrawalFee();
    }

    changeNetwork(option: IDropdown): void {
        this.selectedNetwork = option.value;

        this.getWithdrawalFee();
    }

    changeAmount(amount: string) {
        this.amount = amount;

        this.getWithdrawalFee();
    }

    changeVerificationCode(verification_code: string) {
        this.withdrawal_otp = verification_code;
    }

    changeWalletAddress(wallet_address: string) {
        this.destination_address = wallet_address;

        this.getWithdrawalFee();
    }

    getReceiableAmount() {
        const amount = parseFloat(this.amount);

        if (isNaN(amount)) 
            return 0;

        return amount - this.network_fees;
    }

    getVerificationCode() {
        if (nullChecker(this.amount) || parseFloat(this.amount) <= 0 || nullChecker(this.selectedNetwork) || nullChecker(this.destination_address))
            return;

        if (parseFloat(this.amount) < this.min_withdrawal) {
            this.cfAlertService.showMessage(`Minimum withdrawal amount is ${this.min_withdrawal} ${this.selectedCurrency.toUpperCase()}`, true);

            return;
        }

        this.loading = true;

        const body = {
            amount: parseFloat(this.amount),
            currency: this.selectedCurrency,
            destination_address: this.destination_address,
            blockchain: this.selectedNetwork,
            fee_level: 'HIGH'
        };

        this.circleService.newWithdrawalRequest(body).subscribe((res: any) => {
            console.log(res);

            this.showVerificationCode = true;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    submit() {
        if (nullChecker(this.amount) || parseFloat(this.amount) <= 0 || nullChecker(this.selectedNetwork) || nullChecker(this.destination_address) || nullChecker(this.withdrawal_otp))
            return;

        this.loading = true;

        const body = {
            amount: this.amount,
            currency: this.selectedCurrency,
            destination_address: this.destination_address,
            blockchain: this.selectedNetwork,
            fee_level: 'HIGH',
            withdrawal_otp: parseInt(this.withdrawal_otp)
        };

        this.circleService.newWithdrawalRequest(body).subscribe((res: any) => {
            const challengeId = res.data.challengeId;

            // this.circle_user_token = res.data.circle_user_token;
            // this.circle_encryption_key = res.data.circle_encryption_key;

            this.circle_user_token = this.localStorageService.getItem('circle_user_token') || '';
            this.circle_encryption_key = this.localStorageService.getItem('circle_encryption_key') || '';

            this.circleService.sdk?.setAuthentication({
                userToken: this.circle_user_token,
                encryptionKey: this.circle_encryption_key
            });
    
            this.circleService.sdk?.setAppSettings({
                appId: this.app_id
            });

            this.circleService.sdk?.execute(challengeId, (err, result) => {
                console.log('execute onComplete');
                console.log('err', err);
                console.log('result', result);
    
                if (result?.status !== ChallengeStatus.FAILED) {
                    console.log('Challenge completed');

                    this.cfAlertService.showSuccess('Withdrawal request submitted succesfully');
    
                    setTimeout(() => {
                        console.log('RELOAD ISSUE URL : CircleNewWithdrawalComponent => ', this.window.location.href);
                        this.window.location.reload();
                    }, 2000);
                }
    
                else {
                    this.cfAlertService.showMessage('Challenge not completed. Please retry.', true);
    
                    console.log('RELOAD ISSUE URL : CircleNewWithdrawalComponent => ', this.window.location.href);

                    this.window.location.reload();
                }
            });

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
}
