import {
    Component, Inject, OnInit 
} from '@angular/core';
import {
    MatDialogRef, MAT_DIALOG_DATA 
} from '@angular/material/dialog';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';
import { MetamaskService } from '../metamask.service';
import Web3 from 'web3';
import IEthereumChain from '../../interfaces/IEthereumChain';

@Component({
    selector: 'app-change-chain-dialog',
    templateUrl: './change-chain-dialog.component.html',
    styleUrl: './change-chain-dialog.component.scss'
})
export class ChangeChainDialogComponent implements OnInit {
    loading: boolean = false;

    expected_chain_info: IEthereumChain = {
        chainId: 0,
        chainName: '',
        network: '',
        blockExplorerUrl: '',
        chainLogo: '',
        nativeCurrency: {
            name: '',
            symbol: '',
            decimals: 0
        },
        rpc: ''
    };

    current_chain_info: IEthereumChain = {
        chainId: 0,
        chainName: '',
        network: '',
        blockExplorerUrl: '',
        chainLogo: '',
        nativeCurrency: {
            name: '',
            symbol: '',
            decimals: 0
        },
        rpc: ''
    };

    constructor(
        public dialogRef: MatDialogRef<ChangeChainDialogComponent>,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private metamaskService: MetamaskService,
        @Inject(MAT_DIALOG_DATA) public data: { expected_chain: number, current_chain: number }
    ) { }

    ngOnInit(): void {
        console.log('expected_chain', this.data.expected_chain);

        if (this.data.expected_chain) {
            this.expected_chain_info = this.getChainIcon(this.data.expected_chain);
            console.log('expected_chain_info', this.expected_chain_info);
        }
        
        console.log('current_chain', this.data.current_chain);

        if (this.data.current_chain) {
            this.current_chain_info = this.getChainIcon(this.data.current_chain);
            console.log('current_chain_info', this.current_chain_info);
        }
    }

    getChainIcon(chain: number): IEthereumChain {
        console.log('getChainIcon', chain);

        const chainInfo = ETHEREUM_CHAINS[chain];

        return chainInfo;
    }

    switchNetwork() {
        this.loading = true;

        const chainInfo = ETHEREUM_CHAINS[this.data.expected_chain];

        this.metamaskService.provider?.request({
            method: 'wallet_switchEthereumChain',
            params: [{
                chainId: Web3.utils.toHex(chainInfo.chainId)
            }]
        }).then((res) => {
            this.cfAlertService.showMessage(`Switched to ${chainInfo.chainName}`, false);

            this.closeDialog();
        }).catch((e) => {
            this.cfAlertService.showMessage(`Failed to switch to ${chainInfo.chainName}`, true);
            console.error('switchNetwork error', e);
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
