import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, OnInit
} from '@angular/core';
import {
    MAT_DIALOG_DATA, MatDialogRef
} from '@angular/material/dialog';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../../services/http.service';
import { OnboardingService } from '../../onboarding.service';

@Component({
    selector: 'app-choose-profile-picture',
    templateUrl: './choose-profile-picture.component.html',
    styleUrl: './choose-profile-picture.component.scss'
})
export class ChooseProfilePictureComponent implements OnInit {
    profile_images: Array<any> = [];

    current_profile_image = '';
    loading: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ChooseProfilePictureComponent>,
        private onboardingService: OnboardingService,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        @Inject(MAT_DIALOG_DATA) public data: { current_profile_picture: string }
    ) { }

    ngOnInit(): void {
        this.getProfilePictures();
    }

    selectImage(image: any) {
        this.current_profile_image = image.path;

        this.closeDialog();
    }

    shuffleArray(array: Array<any>) {
        let currentIndex = array.length;

        while (currentIndex !== 0) {
            const randomIndex = Math.floor(Math.random() * currentIndex);

            currentIndex--;

            [ array[currentIndex], array[randomIndex] ] = [
                array[randomIndex], array[currentIndex]
            ];
        }
    }

    closeDialog() {
        this.dialogRef.close(this.current_profile_image);
    }

    getProfilePictures() {
        this.loading = true;

        this.onboardingService.getProfilePictures().subscribe((res: any) => {
            this.loading = false;

            this.profile_images = res.data;

            this.shuffleArray(this.profile_images);

            const current_index = this.profile_images.findIndex((x: { path: string; }) => x.path === this.data.current_profile_picture);

            this.profile_images.splice(current_index, 1);
            this.profile_images.unshift({
                path: this.data.current_profile_picture
            });

            this.current_profile_image = this.profile_images[0].path;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }
}
