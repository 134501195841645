import {
    Component, Input, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-lend-widget',
    templateUrl: './lend-widget.component.html',
    styleUrl: './lend-widget.component.scss'
})

export class LendWidgetComponent implements OnInit {
    currentTab: any = 'supply';

    @Input() pool_id: string = '';

    constructor(public httpService: HttpService) {
    }

    ngOnInit(): void {
        
    }

    changedTab(tab: any) {
        this.currentTab = tab;
    }
}
