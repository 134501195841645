<div class="app-root">
    <div class="container-fluid loader" *ngIf="loading">
        <div class="loader-content">
            <cf-loader [diameter]="50"></cf-loader>
        </div>
    </div>

    <div *ngIf="!loading">
        <div class="container-fluid g-0" style="background: black;">
            <div class="g-0 kyc-section"
                *ngIf="httpService.user.kyc_status === 0 && httpService.registerStepsCompleted">
                <span>Please complete your KYC. Click here to <span class="kyc-button"
                        (click)="generateKycUrl()">continue</span>.
                </span>
                <cf-loader [theme]="httpService.theme" [diameter]="20" *ngIf="kyc_loading"></cf-loader>
            </div>
            <div class="g-0 holder">
                <div class="sidebar" [ngStyle]="{width: currentSidebarWidth + 'px'}">
                    <app-sidebar *ngIf="showSidebar" (collapsed)="sidebarCollapsed($event)"
                        [currentCollapseStatus]="currentCollapseStatus"></app-sidebar>
                </div>
                <div class="non-sidebar w-100" [ngStyle]="{width: nonSidebarWidth + 'px'}">
                    <div class="row g-0" *ngIf="showHeader">
                        <div class="col-md-12">
                            <app-header [showConnectWallet]="showConnectWallet"></app-header>
                        </div>
                    </div>
                    <router-outlet></router-outlet>
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>
</div>