{
    "name": "@circle-fin/w3s-pw-web-sdk",
    "version": "1.1.6",
    "description": "Javascript/Typescript SDK for Circle Programmable Wallets",
    "main": "dist/src/index.js",
    "types": "dist/src/index.d.ts",
    "scripts": {
        "build": "npx tsc",
        "test": "jest --env=jsdom",
        "test:watch": "jest --watch --env=jsdom",
        "lint": "eslint . --ext .ts",
        "lint-fix": "eslint . --ext .ts --fix",
        "format": "prettier --write .",
        "format-check": "prettier --check src/"
    },
    "repository": {
        "type": "git",
        "url": "git+https://github.com/circlefin/w3s-pw-web-sdk.git"
    },
    "keywords": [
        "circle",
        "circle.com",
        "usdc",
        "euroc",
        "stablecoins",
        "programmable wallets"
    ],
    "homepage": "https://github.com/circlefin/w3s-pw-web-sdk#readme",
    "publishConfig": {
        "registry": "https://registry.npmjs.com/"
    },
    "license": "Apache-2.0",
    "bugs": {
        "url": "https://github.com/circlefin/w3s-pw-web-sdk/issues"
    },
    "engines": {
        "node": ">=10.0.0"
    },
    "dependencies": {
        "dotenv": "^16.3.1",
        "firebase": "^10.12.1",
        "jsonwebtoken": "^9.0.2",
        "uuid": "^9.0.1"
    },
    "devDependencies": {
        "@types/jest": "^28.1.7",
        "@types/node": "^14.14.14",
        "@typescript-eslint/eslint-plugin": "^5.0.0",
        "eslint": "^8.0.1",
        "eslint-config-standard-with-typescript": "^22.0.0",
        "eslint-plugin-import": "^2.25.2",
        "eslint-plugin-n": "^16.3.0",
        "eslint-plugin-promise": "^6.1.1",
        "jest": "^29.6.1",
        "jest-environment-jsdom": "^29.6.1",
        "prettier": "^2.7.1",
        "ts-jest": "^29.1.1",
        "typescript": "^4.9.5"
    }
}
