<div class="app-new-investment dark">
    <div *ngIf="!loading">
        <div class="row">
            <cf-input-currency-dropdown theme="dark" [options]="paymentCurrencyDropdownOptions" [value]="payment_amount"
                label="Payment amount" [selected]="payment_currency" type="number"
                (valueChanged)="paymentAmountChanged($event)" (currencyChanged)="paymentCurrencyChanged($event)"
                [precision]="2">
            </cf-input-currency-dropdown>
            <span class="equivalent-amount">
                Available:
                {{ balance | number : httpService.getPrecision(payment_currency) }}
                {{ payment_currency | uppercase }}
            </span>
        </div>

        <div class="row">
            <cf-dropdown theme="dark" [options]="durationDropdownOptions" [class]="'input'" label="Select Duration"
                [showLogo]="false" [selected]="selectedDurationItem" (change)="changeDuration($event)">
            </cf-dropdown>
        </div>

        <div class="row" *ngIf="vault_dropdown_options.length > 0">
            <cf-dropdown theme="dark" [options]="vault_dropdown_options" [class]="'input'" label="Select Vault"
                [selected]="selected_vault" (change)="changeVault($event)">
            </cf-dropdown>
        </div>

        <div class="new-investment-summary">
            <div class="summary-item">
                <span class="summary-heading">Principal amount</span>
                <span class="summary-value">{{
                    payment_amount
                    | number
                    : httpService.getPrecision(payment_currency)
                    }}
                    {{ payment_currency | uppercase }}</span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">{{ annual_interest_rate | number }}% APY
                    {{ payment_currency | uppercase }}</span>
                <span class="summary-value">{{ annual_interest | number }}
                    {{ payment_currency | uppercase }}</span>
            </div>
            <div class="summary-item" *ngIf="cred_percentage > 0">
                <span class="summary-heading">{{ cred_percentage | number }}% APY {{additionalYieldLabel}}
                    pts</span>
                <span class="summary-value">{{ cred_points | number }} pts</span>
            </div>
        </div>

        <div class="actions">
            <div *ngIf="differentBlockchain">
                <cf-button label="Switch Blockchain" type="solid" (clicked)="openChangeBlockchainDialog()"></cf-button>
            </div>
            <div *ngIf="
                    !differentBlockchain &&
                    this.pool?.kyc_required === true &&
                    httpService.user.wallet_address !== '' &&
                    httpService.user?.kyc_status !== 1
                ">
                <cf-button [theme]="httpService.theme" [label]="'Start KYC'" (clicked)="generateKycUrl()"
                        [loading]="loading"></cf-button>
            </div>
            <div class="action" *ngIf="
                    !differentBlockchain &&
                    httpService.user.wallet_address !== '' &&
                    (!this.pool?.kyc_required ||
                        httpService.user?.kyc_status === 1)
                ">
                <div>
                    <cf-button label="Confirm" type="solid" (clicked)="createInvestment()" *ngIf="showConfirmButton"
                        [loading]="new_investment_loading"></cf-button>
                    <cf-button label="Switch Network" type="solid" (clicked)="openChangeChainDialog()"
                        *ngIf="differentNetwork"></cf-button>
                    <cf-button label="Approve" type="solid" (clicked)="approveAllowance()"
                        *ngIf="!differentNetwork && showAllowanceButton" [loading]="new_investment_loading"></cf-button>
                </div>
            </div>
            <div class="action" *ngIf="httpService.user.wallet_address === ''">
                <cf-button label="Connect wallet" type="solid" (clicked)="connectWallet()"></cf-button>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
</div>