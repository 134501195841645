import IPoolStats from '../interfaces/IPoolStats';

export default class PoolStats implements IPoolStats {
    collateral: number = 0;
    collateral_usd: number = 0;
    drawdown_fee: number = 0;
    outstanding_drawdown: number = 0;
    outstanding_drawdown_usd: number = 0;
    tvl: number = 0;
    tvl_usd: number = 0;
    principal_withdrawal_fee: number = 0;
    drawdown: number = 0;
    drawdown_usd: number = 0;
    investment: number = 0;
    investment_usd: number = 0;
}