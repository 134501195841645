<div class="app-bank-accounts" [ngClass]="httpService.theme">
    <div class="controls">
        <cf-button [theme]="httpService.theme" (clicked)="openProfilePictureDialog()" label="Add Bank"></cf-button>
    </div>

    <div class="banks">
        <cf-card [theme]="httpService.theme" *ngFor="let bank of banks" type="empty">
            <div class="fields">
                <div class="field">
                <div class="label">Account holder's name</div>
                <div class="value">{{ bank.account_holder_name }}</div>
            </div>
            <div class="field">
                <div class="label">Account number</div>
                <div class="value">{{ httpService.displayBankAccountNumber(bank.account_number) }}</div>
            </div>
            <div class="field">
                <div class="label">Bank Name</div>
                <div class="value">{{ bank.bank_name }}</div>
            </div>
            <div class="field">
                <div class="label">IFSC</div>
                <div class="value">{{ bank.ifsc }}</div>
            </div>
            <div class="field">
                <div class="label">Type</div>
                <div class="value">{{ bank.type | titlecase }}</div>
            </div>
            <div class="field">
                <div class="label">Currency</div>
                <div class="value">{{ bank.currency | uppercase }}</div>
            </div>
        </div>
        </cf-card>
    </div>
</div>