import {
    Component, OnInit 
} from '@angular/core';
import { AddBankComponent } from '../add-bank/add-bank.component';
import {
    MatDialog, MatDialogRef 
} from '@angular/material/dialog';
import { HttpService } from '../../services/http.service';
import IBank from '../../interfaces/IBank';
import { PayFiService } from '../../services/payfi.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';

@Component({
    selector: 'app-bank-accounts',
    templateUrl: './bank-accounts.component.html',
    styleUrl: './bank-accounts.component.scss'
})
export class BankAccountsComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AddBankComponent>,
        public dialog: MatDialog,
        public httpService: HttpService,
        private payFiService: PayFiService,
        private cfAlertService: CfAlertService
    ) { }

    banks: Array<IBank> = [];

    ngOnInit(): void {
        this.getUserBankAccounts();
    }

    openProfilePictureDialog() {
        this.dialogRef = this.dialog.open(AddBankComponent, {
            width: '550px',
            height: '650px',
            data: {}
        });

        this.dialogRef.afterClosed().subscribe(result => {
            if (result) 
                console.log(result);
        });
    }

    getUserBankAccounts() {
        this.payFiService.getUserBankAccounts().subscribe((res: any) => {
            this.banks = res.data;
        }, (err: HttpErrorResponse) => {
            console.error('getUserBankAccounts error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
}