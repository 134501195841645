<div class="app-vault-allocation" [ngClass]="httpService.theme">
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div class="row" *ngIf="!loading">
        <div class="col-md-12 box">
            <table>
                <thead>
                    <tr>
                        <th>Pool</th>
                        <th>Amount</th>
                        <th>Weightage</th>
                    </tr>
                </thead>
                <tbody *ngIf="items.length > 0">
                    <tr *ngFor="let item of items">
                        <td>
                            <div class="pool-name-container">
                                <img [src]="item.pool_logo" [alt]="item.pool_name" class="pool-logo" />
                                <span class="pool-name">{{ item.pool_name }}</span>
                            </div>

                        </td>
                        <td>
                            {{
                            item.vault_tvl
                            | number
                            : httpService.getPrecision(
                            item.currency
                            )
                            }}
                            {{ item.currency | uppercase }}
                        </td>
                        <td>{{ item.weightage | number : "0.0-2" }} %</td>
                    </tr>
                </tbody>
                <tbody *ngIf="total_count === 0">
                    <tr>
                        <td colspan="7" class="pd">
                            No records found
                        </td>
                    </tr>
                </tbody>
                <tfoot></tfoot>
            </table>
            <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
                (changed)="pageChanged($event)"></cf-paginator>
        </div>
    </div>
</div>