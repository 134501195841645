import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { serverUrl } from '../app/app.config';
import { LocalStorageService } from './localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class TwitterService {
    constructor(private http: HttpClient, private localStorageService: LocalStorageService) {

    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    getRequestToken() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}twitter/getRequestToken`, {}, httpOptions);
    }

    storeTwitterVerifier(oauth_token: string, oauth_verifier: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        const body = {
            oauth_token,
            oauth_verifier
        };

        return this.http.post<any>(`${serverUrl}twitter/storeVerifier`, body, httpOptions);
    }

    checkFollowingCredible() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}twitter/checkFollowingCredible`, httpOptions);
    }
}
