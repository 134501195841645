import IEthereumChain from '../interfaces/IEthereumChain';

const MAINNET = {
    chainId: 1,
    chainName: 'Ethereum',
    network: 'mainnet',
    chainLogo: 'https://assets.credible.finance/currencies/ethereum.png',
    blockExplorerUrl: 'https://etherscan.io',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
    }
};

const ROPSTEN = {
    chainId: 3,
    chainName: 'Ropsten',
    network: 'testnet',
    chainLogo: 'https://assets.credible.finance/currencies/ropsten.png',
    blockExplorerUrl: 'https://ropsten.etherscan.io',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Ropsten Ether',
        symbol: 'ROP',
        decimals: 18
    }
};

const RINKEBY = {
    chainId: 4,
    chainName: 'Rinkeby',
    network: 'testnet',
    chainLogo: 'https://assets.credible.finance/currencies/rinkeby.png',
    blockExplorerUrl: 'https://rinkeby.etherscan.io',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Rinkeby Ether',
        symbol: 'RIN',
        decimals: 18
    }
};

const GOERLI = {
    chainId: 5,
    chainName: 'Goerli',
    network: 'testnet',
    chainLogo: 'https://assets.credible.finance/currencies/goerli.png',
    blockExplorerUrl: 'https://goerli.etherscan.io',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Goerli Ether',
        symbol: 'GOR',
        decimals: 18
    }
};

const KOVAN = {
    chainId: 42,
    chainName: 'Kovan',
    network: 'testnet',
    chainLogo: 'https://assets.credible.finance/currencies/kovan.png',
    blockExplorerUrl: 'https://kovan.etherscan.io',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Kovan Ether',
        symbol: 'KOV',
        decimals: 18
    }
};

const XDAI = {
    chainId: 100,
    chainName: 'xDai',
    network: 'mainnet',
    chainLogo: 'https://assets.credible.finance/currencies/xdai.png',
    blockExplorerUrl: 'https://blockscout.com/xdai/mainnet',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'xDai',
        symbol: 'xDai',
        decimals: 18
    }
};

const MATIC = {
    chainId: 137,
    chainName: 'Matic',
    network: 'mainnet',
    chainLogo: 'https://assets.credible.finance/currencies/matic.png',
    blockExplorerUrl: 'https://explorer-mainnet.maticvigil.com',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Matic',
        symbol: 'Matic',
        decimals: 18
    }
};

const MUMBAI = {
    chainId: 80001,
    chainName: 'Mumbai',
    network: 'testnet',
    chainLogo: 'https://assets.credible.finance/currencies/matic.png',
    blockExplorerUrl: 'https://explorer-mumbai.maticvigil.com',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Mumbai Matic',
        symbol: 'Matic',
        decimals: 18
    }
};

const BASETESTNET = {
    chainId: 84532,
    chainName: 'Base Sepolia',
    network: 'testnet',
    chainLogo: 'https://assets.credible.finance/currencies/base.png',
    blockExplorerUrl: 'https://sepolia-explorer.base.org',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18
    }
};

const BSC = {
    chainId: 56,
    chainName: 'Binance Smart Chain',
    network: 'mainnet',
    chainLogo: 'https://assets.credible.finance/currencies/bsc.png',
    blockExplorerUrl: 'https://bscscan.com',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
    }
};

const BSCTESTNET = {
    chainId: 97,
    chainName: 'Binance Smart Chain Testnet',
    network: 'testnet',
    chainLogo: 'https://assets.credible.finance/currencies/bsc.png',
    blockExplorerUrl: 'https://testnet.bscscan.com',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
    }
};

const ARBITRUM = {
    chainId: 42161,
    chainName: 'Arbitrum',
    network: 'mainnet',
    chainLogo: 'https://assets.credible.finance/currencies/arbitrum.png',
    blockExplorerUrl: 'https://arbiscan.io',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
    }
};

const ARBITRUMTESTNET = {
    chainId: 421611,
    chainName: 'Arbitrum Testnet',
    network: 'testnet',
    chainLogo: 'https://assets.credible.finance/currencies/arbitrum.png',
    blockExplorerUrl: 'https://testnet.arbiscan.io',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
    }
};

const OPTIMISM = {
    chainId: 10,
    chainName: 'Optimism',
    network: 'mainnet',
    chainLogo: 'https://assets.credible.finance/currencies/optimism.png',
    blockExplorerUrl: 'https://optimistic.etherscan',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
    }
};

const OPTIMISMTESTNET = {
    chainId: 69,
    chainName: 'Optimism Testnet',
    network: 'testnet',
    chainLogo: 'https://assets.credible.finance/currencies/optimism.png',
    blockExplorerUrl: 'https://kovan-optimistic.etherscan.io',
    rpc: 'https://rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
    }
};

const PLUMEDEVNET = {
    chainId: 98864,
    network: 'devnet',
    chainName: 'Plume devnet',
    chainLogo: 'https://pbs.twimg.com/profile_images/1818315886618615808/h0wR_0La_400x400.jpg',
    blockExplorerUrl: 'https://test-explorer.plumenetwork.xyz',
    rpc: 'https://test-rpc.plumenetwork.xyz',
    nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
    }
};

const ETHEREUM_CHAINS: {
    [key: string]: IEthereumChain;
} = {};

// You can query by blockchain or chainId
ETHEREUM_CHAINS['MAINNET'] = MAINNET;
ETHEREUM_CHAINS[MAINNET.chainId] = MAINNET;

ETHEREUM_CHAINS['ROPSTEN'] = ROPSTEN;
ETHEREUM_CHAINS[ROPSTEN.chainId] = ROPSTEN;

ETHEREUM_CHAINS['RINKEBY'] = RINKEBY;
ETHEREUM_CHAINS[RINKEBY.chainId] = RINKEBY;

ETHEREUM_CHAINS['GOERLI'] = GOERLI;
ETHEREUM_CHAINS[GOERLI.chainId] = GOERLI;

ETHEREUM_CHAINS['KOVAN'] = KOVAN;
ETHEREUM_CHAINS[KOVAN.chainId] = KOVAN;

ETHEREUM_CHAINS['XDAI'] = XDAI;
ETHEREUM_CHAINS[XDAI.chainId] = XDAI;

ETHEREUM_CHAINS['MATIC'] = MATIC;
ETHEREUM_CHAINS[MATIC.chainId] = MATIC;

ETHEREUM_CHAINS['MUMBAI'] = MUMBAI;
ETHEREUM_CHAINS[MUMBAI.chainId] = MUMBAI;

ETHEREUM_CHAINS['BSC'] = BSC;
ETHEREUM_CHAINS[BSC.chainId] = BSC;

ETHEREUM_CHAINS['BSCTESTNET'] = BSCTESTNET;
ETHEREUM_CHAINS[BSCTESTNET.chainId] = BSCTESTNET;

ETHEREUM_CHAINS['ARBITRUM'] = ARBITRUM;
ETHEREUM_CHAINS[ARBITRUM.chainId] = ARBITRUM;

ETHEREUM_CHAINS['ARBITRUMTESTNET'] = ARBITRUMTESTNET;
ETHEREUM_CHAINS[ARBITRUMTESTNET.chainId] = ARBITRUMTESTNET;

ETHEREUM_CHAINS['OPTIMISM'] = OPTIMISM;
ETHEREUM_CHAINS[OPTIMISM.chainId] = OPTIMISM;

ETHEREUM_CHAINS['OPTIMISMTESTNET'] = OPTIMISMTESTNET;
ETHEREUM_CHAINS[OPTIMISMTESTNET.chainId] = OPTIMISMTESTNET;

ETHEREUM_CHAINS['PLUMEDEVNET'] = PLUMEDEVNET;
ETHEREUM_CHAINS[PLUMEDEVNET.chainId] = PLUMEDEVNET;

ETHEREUM_CHAINS['BASETESTNET'] = BASETESTNET;
ETHEREUM_CHAINS[BASETESTNET.chainId] = BASETESTNET;

export default ETHEREUM_CHAINS;