export default {
  name: 'EIP-2718',
  comment: 'Typed Transaction Envelope',
  url: 'https://eips.ethereum.org/EIPS/eip-2718',
  status: 'Final',
  minimumHardfork: 'chainstart',
  gasConfig: {},
  gasPrices: {},
  vm: {},
  pow: {}
};
