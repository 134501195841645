import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, HostListener, OnInit, ViewChild 
} from '@angular/core';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import { HttpService } from '../../../services/http.service';
import { LineChartComponent } from '../../charts/line-chart/line-chart.component';
import ILineConfig from '../../charts/line-chart/ILineConfig';
import { PayFiService } from '../../../services/payfi.service';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';
import IPayFiPool from '../../../interfaces/IPayFiPool';

@Component({
    selector: 'app-lend-pool-details-payfi',
    templateUrl: './lend-pool-details-payfi.component.html',
    styleUrl: './lend-pool-details-payfi.component.scss'
})
export class LendPoolDetailsOverdraftComponent implements OnInit {
    loading: boolean = false;

    pools: Array<IPayFiPool> = [];
    commonData: any = {};
    window: Window;
    
    width: any;

    tabs: Array<TabOption> = [{
        label: 'My drawdowns',
        value: 'my-drawdowns'
    }, {
        label: 'My collalterals',
        value: 'my-collaterals'
    }, {
        label: 'My Repayments',
        value: 'my-repayments'
    }, {
        label: 'My Lending',
        value: 'my-investments'
    }];
    currentTab: string = this.tabs[0].value;

    chart_loading: boolean = true;
    stats: any;
    graphArray: any = [];
    chartTitle = '';
    chartLineConfig = Array<ILineConfig>();
    isTooltipVisible = false;

    @ViewChild(LineChartComponent) child!: LineChartComponent;

    constructor(private route: ActivatedRoute, 
        public httpService: HttpService, 
        private payFiService: PayFiService,
        private cfAlertService: CfAlertService, 
        private router: Router) {
        this.window = getWindow();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        this.getPayFiPoolCommonData();

        this.width = this.window.innerWidth;
    }

    showTooltip() {
        this.isTooltipVisible = true;
    }

    hideTooltip() {
        this.isTooltipVisible = false;
    }

    getPayFiPoolCommonData() {
        this.loading = true;

        this.payFiService.getPayFiPoolCommonData().subscribe(res => {
            this.commonData = res.data;

            this.getPools();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    getPools() {
        this.payFiService.getPools().subscribe(res => {
            this.pools = res.data;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);

            this.router.navigateByUrl('/liquidity');
        });
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                }, 
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    getAmount(amount: number) {
        if (amount !== 0)
            return amount.toFixed(2);
        else
            return 0;
    }

    showCharts(event: string) {
        let chartDataDrawdown, chartDataInvestment;

        if (event === '30D') {
            chartDataInvestment = this.stats.tvlvsdate_30;
            chartDataDrawdown = this.stats.outstandingdrawdownamountvsdate_30;
        }

        else if (event === '60D') {
            chartDataInvestment = this.stats.tvlvsdate_60;
            chartDataDrawdown = this.stats.outstandingdrawdownamountvsdate_60;
        }

        else if (event === '90D') {
            chartDataInvestment = this.stats.tvlvsdate_90;
            chartDataDrawdown = this.stats.outstandingdrawdownamountvsdate_90;
        }

        this.chartLineConfig = [{
            name: 'Investment',
            data: chartDataInvestment,
            lineColor: '#FFD700'
        }, {
            name: 'Drawdown',
            data: chartDataDrawdown,
            lineColor: '#FFD700'
        }];

        this.child.loadchart();
    }
}
