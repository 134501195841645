import {
    Component, Input, 
    OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';
import { getWindow } from 'ssr-window';
import User from '../../models/User';
import { HttpService } from '../../services/http.service';
import { LocalStorageService } from '../../services/localstorage.service';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { WalletService } from '../../services/wallet.service';
import { MetamaskService } from '../metamask.service';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';

@Component({
    selector: 'app-header-popup-menu',
    templateUrl: './header-popup-menu.component.html',
    styleUrl: './header-popup-menu.component.scss'
})
export class HeaderPopupMenuComponent implements OnInit {
    @Input() provider: string = '';
    @Input() wallet_address: string = '';
    total_balance: number = 0;
    pools_page = 0;
    pools_limit = 10;
    window: Window;
    risk_score: any = {};
    chain_name: string = '';

    constructor(
        private router: Router,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        private metamaskService: MetamaskService,
        public walletService: WalletService,
        public httpService: HttpService,
        private localStorageService: LocalStorageService,
        private cfAlertService: CfAlertService,
        private clipboardService: ClipboardService
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        // this.getLoyaltyPoints();
        this.getLendUserPoolStats();

        if (!this.httpService.user.diamonds)
            this.httpService.user.diamonds = 0;

        if (this.httpService.user.wallet_address !== '')
            this.getRiskScore();

        console.log('metamask isConnected', this.metamaskService.provider?.isConnected());

        if (this.metamaskService.provider?.isConnected())
            this.getConnectedBlockchain();
    }

    async logout() {
        if (this.walletService.current_provider === 'phantom') {
            this.phantomService.disconnectWallet();
            this.walletService.current_provider = '';
        }

        else if (this.walletService.current_provider === 'solflare') {
            this.solflareService.disconnectWallet();
            this.walletService.current_provider = '';
        }

        else if (this.walletService.current_provider === 'metamask') {
            this.metamaskService.disconnectWallet();
            this.walletService.current_provider = '';
        }

        this.localStorageService.clear();

        this.httpService.user = new User();
        this.httpService.registerStepsCompleted = false;
        this.localStorageService.setItem('rsc', 'false');

        await this.router.navigate([ '/' ], {
            replaceUrl: true
        });

        console.log('RELOAD ISSUE URL : HeaderPopupMenuComponent => ', this.window.location.href);

        this.window.location.reload();
    }

    copyWalletAddress() {
        this.clipboardService.copy(this.httpService.user.wallet_address);
        this.cfAlertService.showSuccess('Wallet address copied');
    }

    getLendUserPoolStats() {
        this.httpService.getLendUserPoolStats(this.pools_page, this.pools_limit).subscribe(res => {
            const data = res.data.records;

            this.total_balance = res.data.balance;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    getRiskScore() {
        this.httpService.getRiskScore().subscribe(res => {
            this.risk_score = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    redirectToWebacy() {

    }

    async getConnectedBlockchain() {
        if (this.httpService.user.blockchain === 'solana') {
            this.chain_name = 'Solana';

            return ;
        }

        const chainIdHex = await this.metamaskService.getChainId();

        console.log('chainIdHex', chainIdHex);

        const chainIdDecimal = parseInt(chainIdHex, 16);

        console.log('chainIdDecimal', chainIdDecimal);

        if (chainIdHex) {
            if (chainIdDecimal in ETHEREUM_CHAINS)
                this.chain_name = ETHEREUM_CHAINS[chainIdDecimal].chainName;

            else
                this.chain_name = 'Unknown chain';
        }
    }
}
