import {
    Component, EventEmitter, OnInit,
    Output
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import { HttpService } from '../../../services/http.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {
    MatDialog, MatDialogRef
} from '@angular/material/dialog';
import { nullChecker } from '../../../helpers/nullChecker';
import { ChooseProfilePictureComponent } from '../choose-profile-picture/choose-profile-picture.component';
import { OnboardingService } from '../../onboarding.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnInit {
    window: Window;

    profile_images: Array<any> = [];

    @Output() completed = new EventEmitter<any>();
    @Output() previous = new EventEmitter<any>();
    loading: boolean = false;
    currentUser: any;
    display_name: string = '';
    profile_picture_url: string = '';

    constructor(
        private router: Router,
        public httpService: HttpService,
        private onboardingService: OnboardingService,
        private cfAlertService: CfAlertService,
        public dialogRef: MatDialogRef<ChooseProfilePictureComponent>,
        public dialog: MatDialog
    ) {
        this.window = getWindow();
        console.log('app-user-profile constructor');
    }

    ngOnInit(): void {
        if (this.httpService?.user?.display_name && this.httpService?.user?.profile_picture)
            this.completed.emit();

        if (this.httpService?.user?.display_name)
            this.display_name = this.httpService.user.display_name;

        this.getProfilePictures();
    }

    randomInteger(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    previousHandler() {
        this.previous.emit();
    }

    displayNameChanged(event: any) {
        this.display_name = event;
    }

    next() {
        if (nullChecker(this.display_name)) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Display name is required'
                }
            }));

            return;
        }

        const body = {
            profile_picture: this.profile_picture_url,
            display_name: this.display_name
        };

        this.onboardingService.updateProfile(body).subscribe((res: any) => {
            this.httpService.user.profile_picture = this.profile_picture_url;
            this.httpService.user.display_name = this.display_name;

            this.completed.emit();
        }, (err: HttpErrorResponse) => {
            console.error('update profile error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getRandomImage() {
        const index = this.randomInteger(0, this.profile_images.length - 1);

        console.log('index', index);

        return this.profile_images[index];
    }

    openProfilePictureDialog() {
        this.dialogRef = this.dialog.open(ChooseProfilePictureComponent, {
            width: '550px',
            height: '550px',
            data: {
                current_profile_picture: this.profile_picture_url
            }
        });

        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.profile_picture_url = result;
                this.httpService.user.profile_picture = result;
            }
        });
    }

    getProfilePictures() {
        console.log('getProfilePictures');

        this.loading = true;

        this.onboardingService.getProfilePictures().subscribe((res: any) => {
            this.loading = false;
            this.profile_images = res.data;

            this.profile_picture_url = this.getRandomImage().path;
            this.httpService.user.profile_picture = this.profile_picture_url;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }
}
