function getRedirectionAddressUrl(blockchain: string, chain: string, network: string, address: string): string {
    if (blockchain === 'solana') {
        if (network === 'devnet')
            return `https://explorer.solana.com/address/${address}?cluster=devnet`;

        else
            return `https://explorer.solana.com/address/${address}`;
    }

    if (blockchain === 'ethereum') {
        if (chain === 'plume') {
            if (network === 'devnet')
                return `https://test-explorer.plumenetwork.xyz/address/${address}`;

            else
                return `https://test-explorer.plumenetwork.xyz/address/${address}`;
        }
        
        else if (chain === 'base') {
            if (network === 'testnet')
                return `https://base-sepolia.blockscout.com/address/${address}`;

            else
                return `https://base-sepolia.blockscout.com/address/${address}`;
        }
    }

    return '';
}

export default getRedirectionAddressUrl;