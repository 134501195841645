import {
    Component, Inject, OnInit 
} from '@angular/core';
import {
    MatDialogRef, MAT_DIALOG_DATA 
} from '@angular/material/dialog';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-add-bank',
    templateUrl: './add-bank.component.html',
    styleUrl: './add-bank.component.scss'
})
export class AddBankComponent implements OnInit {
    loading = false;
    
    constructor(
        public dialogRef: MatDialogRef<AddBankComponent>,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        @Inject(MAT_DIALOG_DATA) public data: { current_profile_picture: string }
    ) { }

    ngOnInit(): void {
    }

    addBank() {

    }

    searchIfsc() {

    }

    closeDialog() {
        this.dialogRef.close();
    }
}
