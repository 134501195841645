function getRedirectionTxUrl(blockchain: string, chain: string, network: string, blockchain_txid: string): string {
    if (blockchain === 'solana') {
        if (network === 'devnet')
            return `https://explorer.solana.com/tx/${blockchain_txid}?cluster=devnet`;

        else
            return `https://explorer.solana.com/tx/${blockchain_txid}`;
    }

    // Plume testnet (deprecated)
    // Plume devnet (current available)
    // Plume mainnet (next 2-3 months)
    if (blockchain === 'ethereum') {
        if (chain === 'plume') {
            if (network === 'devnet')
                return `https://test-explorer.plumenetwork.xyz/tx/${blockchain_txid}`;

            else
                return `https://test-explorer.plumenetwork.xyz/tx/${blockchain_txid}`;
        }
        
        else if (chain === 'base') {
            if (network === 'testnet')
                return `https://base-sepolia.blockscout.com/tx/${blockchain_txid}`;

            else
                return `https://base-sepolia.blockscout.com/tx/${blockchain_txid}`;
        }
    }

    return '';
}

export default getRedirectionTxUrl;