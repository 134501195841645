export default {
  name: 'EIP-3541',
  comment: 'Reject new contracts starting with the 0xEF byte',
  url: 'https://eips.ethereum.org/EIPS/eip-3541',
  status: 'Final',
  minimumHardfork: 'berlin',
  requiredEIPs: [],
  gasConfig: {},
  gasPrices: {},
  vm: {},
  pow: {}
};
