<div class="app-circle-balances">
    <div class="balances">
        <cf-card type="empty" class="balance" *ngFor="let item of currencies" [theme]="httpService.theme">
            <div class="balance">
                <div class="currency">
                    <img [src]="item.logo" width="30" [alt]="item.currency_key" />
                    {{ item.currency_name }}
                </div>
                <div class="balance-amount" *ngIf="item.loading">
                    <cf-loader [diameter]="20"></cf-loader>
                </div>
                <div class="balance-amount" *ngIf="!item.loading">
                    {{ item.balance | number }} {{ item.currency_key | uppercase }}
                </div>
                <div class="actions">
                    <cf-button type="text" label="Deposit" (clicked)="redirectToDeposit()"></cf-button>
                    <cf-button type="text" label="Withdraw" (clicked)="redirectToWithdrawal()"></cf-button>
                </div>
            </div>
        </cf-card>
    </div>
</div>