import './polyfills';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { showConsoleLogs } from './app/app.config';

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if ('ngRef' in window) 
        (window as any).ngRef.destroy();
  
    (window as any).ngRef = ref;

    if (!showConsoleLogs)
        console.log = () => {};

    // Otherwise, log the boot error
}).catch(err => console.error(err));