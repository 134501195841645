<div class="loyalty-points" [ngClass]="httpService.theme">
    <div class="d-flex justify-content-between gap-5 top">

        <div class="left w-100">
            <cf-card [theme]="httpService.theme" type="empty">
                <mat-vertical-stepper [linear]="false" class="stepper" (selectionChange)="onStepChange($event)"
                    #stepper>
                    <mat-step [completed]="steps.telegram" [optional]="false">
                        <ng-template matStepLabel style="width: 100%">
                            <div class="airdrop-step">
                                <span>Telegram <i class="fa-brands fa-telegram" style="font-size: 1.2em"></i></span>
                                <span [ngClass]="{'text-color-verified': telegram.accountConnected}">+{{ telegram_points
                                    }}</span>
                            </div>
                        </ng-template>
                        <div class="description mbt-1">
                            Follow us on our <a href="https://t.me/crediblefinance" target="_blank">Telegram
                                group</a>
                            to know about all of our latest updates.
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="telegram-steps" *ngIf="httpService.user.wallet_address !== ''">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Telegram account connected</td>
                                                <td>
                                                    <cf-checkbox [readonly]="true" [checked]="true"
                                                        *ngIf="telegram.accountConnected"></cf-checkbox>
                                                    <cf-loader *ngIf="!telegram.accountConnected" [diameter]="20">
                                                    </cf-loader>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Credible group joined</td>
                                                <td>
                                                    <cf-checkbox [readonly]="true" [checked]="true"
                                                        *ngIf="telegram.groupJoined"></cf-checkbox>
                                                    <cf-loader *ngIf="!telegram.groupJoined" [diameter]="20">
                                                    </cf-loader>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6 d-grid align-content-center">
                                <cf-button label="Login with Telegram" required (clicked)="loginWithTelegram()"
                                    type="outline" [loading]="telegram.joinGroupLoading"
                                    *ngIf="telegram.loginButton && httpService.user.wallet_address !== ''">
                                </cf-button>
                                <cf-button *ngIf="httpService.user.wallet_address === ''" label="Connect Wallet"
                                    type="outline" (clicked)="connectWallet()"></cf-button>
                                <!-- 
                            <cf-button label="Join group" required
                                (click)="checkTelegramGroupJoined(true)" type="outline"
                                *ngIf="telegram.checkGroupButton"></cf-button> -->
                            </div>
                            <hr class="mt-4" />
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <cf-button label="Next step" (clicked)="goForward(stepper)" type="outline"
                                    *ngIf="!telegram.loginButton && !telegram.checkGroupButton"></cf-button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [completed]="steps.discord" [optional]="false">
                        <ng-template matStepLabel style="width: 100%">
                            <div class="airdrop-step">
                                <span>Discord <i class="fa-brands fa-discord" style="font-size: 1.2em"></i></span>
                                <span [ngClass]="{'text-color-verified': discord.accountConnected}">+{{ discord_points
                                    }}</span>
                            </div>
                        </ng-template>
                        <div class="description mbt-1">
                            Follow us on our Discord channel to know about all of our latest updates.
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="telegram-steps" *ngIf="httpService.user.wallet_address !== ''">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Discord account connected</td>
                                                <td>
                                                    <cf-checkbox [readonly]="true" [checked]="true"
                                                        *ngIf="discord.accountConnected"></cf-checkbox>
                                                    <cf-loader *ngIf="!discord.accountConnected" [diameter]="20">
                                                    </cf-loader>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Credible server joined</td>
                                                <td>
                                                    <cf-checkbox [readonly]="true" [checked]="true"
                                                        *ngIf="discord.serverJoined"></cf-checkbox>
                                                    <cf-loader *ngIf="!discord.serverJoined" [diameter]="20">
                                                    </cf-loader>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6 d-grid align-content-center">
                                <cf-button label="Login with Discord" required (clicked)="getDiscordAuthorizeUrl()"
                                    type="outline" [loading]="discord.joinGroupLoading"
                                    *ngIf="discord.loginButton && httpService.user.wallet_address !== ''">
                                </cf-button>

                                <!-- <cf-button label="Join server" required
                                (click)="checkDiscordGroupJoined(true)" type="outline" *ngIf="discord.check">
                            </cf-button> -->

                                <cf-button *ngIf="httpService.user.wallet_address === ''" label="Connect Wallet"
                                    type="outline" (clicked)="connectWallet()"></cf-button>
                            </div>
                            <hr class="mt-4" />
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <cf-button label="Next step" (clicked)="goForward(stepper)" type="outline"
                                    *ngIf="!discord.loginButton && !discord.checkGroupButton"></cf-button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [completed]="steps.twitter" [optional]="false">
                        <ng-template matStepLabel style="width: 100%">
                            <div class="airdrop-step">
                                <span>Twitter <i class="fa-brands fa-twitter" style="font-size: 1.2em"></i></span>
                                <span [ngClass]="{'text-color-verified': twitter.accountConnected}">+{{ twitter_points
                                    }}</span>
                            </div>
                        </ng-template>
                        <div class="description mbt-1">
                            Follow us on our Twitter page to know about all of our latest updates.
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="telegram-steps" *ngIf="httpService.user.wallet_address !== ''">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Twitter account connected</td>
                                                <td>
                                                    <cf-checkbox [readonly]="true" [checked]="true"
                                                        *ngIf="twitter.accountConnected"></cf-checkbox>
                                                    <cf-loader *ngIf="!twitter.accountConnected" [diameter]="20">
                                                    </cf-loader>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Username</td>
                                                <td>
                                                    {{ httpService.user.twitter?.screen_name }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6 d-grid align-content-center">
                                <cf-button label="Login with Twitter" required (clicked)="getRequestToken()"
                                    type="outline" [loading]="twitter.loading"
                                    *ngIf="!twitter.accountConnected && httpService.user.wallet_address !== ''">
                                </cf-button>
                                <cf-button *ngIf="httpService.user.wallet_address === ''" label="Connect Wallet"
                                    type="outline" (clicked)="connectWallet()"></cf-button>
                            </div>
                            <hr class="mt-4" />
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <cf-button label="Next step" (clicked)="goForward(stepper)" type="outline"
                                    *ngIf="twitter.accountConnected"></cf-button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [completed]="steps.super_team" [optional]="false">
                        <ng-template matStepLabel style="width: 100%">
                            <div class="airdrop-step">
                                <span>Superteam
                                    <i class="fa-solid fa-star" style="font-size: 1.2em"></i>
                                </span>
                                <span [ngClass]="{'text-color-verified': super_team.member}">+{{ super_team_points
                                    }}</span>
                            </div>
                        </ng-template>
                        <div class="description mbt-1">
                            Verify your Superteam membership to get exclusive diamonds.
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="telegram-steps" *ngIf="httpService.user.wallet_address !== ''">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Superteam Solana address verified</td>
                                                <td>
                                                    <cf-checkbox [readonly]=" true" [checked]="true"
                                                        *ngIf="super_team.member"></cf-checkbox>
                                                    <cf-loader *ngIf="!super_team.member" [diameter]="20">
                                                    </cf-loader>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6 d-grid align-content-center">
                                <cf-button label="Verify" required (clicked)="checkSuperTeamUser()" type="outline"
                                    [loading]="super_team.loading"
                                    *ngIf="!super_team.member && httpService.user.wallet_address !== ''">
                                </cf-button>
                                <cf-button *ngIf="httpService.user.wallet_address === ''" label="Connect Wallet"
                                    type="outline" (clicked)="connectWallet()"></cf-button>
                            </div>
                            <hr class="mt-4" />
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <cf-button label="Next step" (clicked)="goForward(stepper)" type="outline"
                                    *ngIf="super_team.member"></cf-button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [completed]="steps.sol_balance" [optional]="false">
                        <ng-template matStepLabel style="width: 100%">
                            <div class="airdrop-step">
                                <span>SOL Balance
                                    <i class="fa-solid fa-coins" style="font-size: 1.2em"></i>
                                </span>
                                <span *ngIf="sol_balance.reward_claimed" class="text-color-verified">+{{
                                    sol_balance_points
                                    }}</span>
                                <span *ngIf="!sol_balance.reward_claimed">Up to 10,000 diamonds</span>
                            </div>
                        </ng-template>
                        <div class="description mbt-1">
                            Earn more diamonds based on your Solana wallet balance.
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="telegram-steps" *ngIf="httpService.user.wallet_address !== ''">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>SOL balance holding verified</td>
                                                <td>
                                                    <cf-checkbox [readonly]="true" [checked]="true"
                                                        *ngIf="sol_balance.reward_claimed"></cf-checkbox>
                                                    <cf-loader *ngIf="!sol_balance.reward_claimed" [diameter]="20">
                                                    </cf-loader>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6 d-grid align-content-center">
                                <cf-button label="Verify" required (clicked)="checkSolanaTokenBalance()" type="outline"
                                    [loading]="sol_balance.loading"
                                    *ngIf="!sol_balance.reward_claimed && httpService.user.wallet_address !== ''">
                                </cf-button>
                                <cf-button *ngIf="httpService.user.wallet_address === ''" label="Connect Wallet"
                                    type="outline" (clicked)="connectWallet()"></cf-button>
                            </div>
                            <hr class="mt-4" />
                        </div>
                        <!-- <div class="row">
                        <div class="col-md-12">
                            <cf-button label="Next step" (clicked)="goForward(stepper)" type="outline"
                                *ngIf="sol_balance.reward_claimed"></cf-button>
                        </div>
                    </div> -->
                    </mat-step>
                </mat-vertical-stepper>
            </cf-card>
        </div>

        <div class="right w-100">
            <div class="one mx-auto d-flex justify-content-between align-items-center gap-3 mb-3">
                <div class="w-100">
                    <cf-card [theme]="httpService.theme" type="empty" [stat]="this.httpService.user.diamonds.toFixed(0)"
                        heading="Total Diamonds" type="compact">
                    </cf-card>

                </div>
                <div class="w-100">
                    <cf-card [theme]="httpService.theme" type="empty" [stat]=" '0'" heading="Bonus Diamonds"
                        type="compact">
                    </cf-card>
                </div>
                <div class="w-100">
                    <cf-card [theme]="httpService.theme" type="empty" [stat]=" '0'" heading="Loyalty Rank"
                        type="compact">
                    </cf-card>
                </div>
            </div>
            <div class="two mx-auto d-flex justify-content-between align-items-center gap-3 mb-5">
                <div class="w-100">
                    <cf-card [theme]="httpService.theme" type="empty" [stat]="lending_diamonds.toFixed(0)"
                        heading="Lending Diamonds" type="compact">
                    </cf-card>
                </div>
                <div class="w-100">
                    <cf-card [theme]="httpService.theme" type="empty" [stat]="borrow_diamonds.toFixed(0)"
                        heading=" Borrowing Diamonds" type="compact">
                    </cf-card>
                </div>
                <div class="w-100">
                    <cf-card [theme]="httpService.theme" type="empty" [stat]="referral_diamonds.toFixed(0)"
                        heading="Referral Diamonds" type="compact">
                    </cf-card>
                </div>
            </div>
            <div class="text-center d-flex justify-content-center gap-3 mb-5">
                <cf-button [theme]="httpService.theme" label="How do diamonds work?" (clicked)="redirectPoints()">
                </cf-button>
            </div>
            <!-- <div *ngIf="httpService.user.wallet_address !== ''"
                class="invite-link align-items-center text-center d-flex justify-content-center gap-3 mb-3">
                <div style="pointer-events: none">
                    <cf-input [theme]="httpService.theme" [value]="referral_link" [label]="'Referral Link'" [readonly]="false"
                        [copy]="false">
                    </cf-input>
                </div>
                <cf-button (clicked)="copyReferLink()" label="Copy link"></cf-button>
            </div> -->
        </div>
    </div>

    <div class="four pt-5">
        <div class="row">
            <div class="col-md-12">
                <h3>Leaderboard</h3>
            </div>
            <div class="col-md-12 box">
                <table>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Address</th>
                            <th>Total Diamonds</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of leaderboard; let i = index">
                            <td>{{ (leaderboard_page * leaderboard_limit) + i + 1 }}</td>
                            <td>
                                {{ item.wallet_address }}
                                <i class="fa-solid fa-up-right-from-square ml"
                                    (clicked)="httpService.redirectionAddress(item.blockchain, item.chain, item.network, item.wallet_address)"></i>
                            </td>
                            <td>{{ item.diamonds.toFixed(0) }} Diamond</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="leaderboard.length === 0">
                        <tr>
                            <td colspan="3" class="pd">
                                No records found
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <td colspan="3">
                            <cf-paginator *ngIf="leaderboard_count > 0" [page]="leaderboard_page + 1"
                                [count]="leaderboard_count" [limit]="leaderboard_limit"
                                (changed)="pageChangedLeaderboard($event)"></cf-paginator>
                        </td>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>

    <div class="three text-center mb-5 pt-5">
        <p>We reserve the right to update point calculations at any time.</p>
    </div>
</div>