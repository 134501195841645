import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/localstorage.service';
import { serverUrl } from './app.config';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    constructor(
        private http: HttpClient, 
        private localStorageService: LocalStorageService
    ) { }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    getProfilePictures() {
        const httpOptions = {
            headers: new HttpHeaders()
        };

        return this.http.get<any>(`${serverUrl}getProfilePictures`, httpOptions);
    }

    updateProfile(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updateProfile`, body, httpOptions);
    }

    acceptTermsAndConditions() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}acceptTermsAndConditions`, {}, httpOptions);
    }

    rejectTermsAndConditions() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}rejectTermsAndConditions`, {}, httpOptions);
    }

    validateInviteCode(invite_code: string) {
        const httpOptions = {
            headers: new HttpHeaders()
        };

        return this.http.get<any>(`${serverUrl}validateInviteCode?invite_code=${invite_code}`, httpOptions);
    }

    updateSocialMedia(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}joinSocialMedia`, body, httpOptions);
    }
}
