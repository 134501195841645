import {
    Component, Input, OnInit
} from '@angular/core';
import { getWindow } from 'ssr-window';

import IPrivateEquityPool from '../../../interfaces/IPrivateEquityPool';
import PrivateEquityPool from '../../../models/PrivateEquityPool';

@Component({
    selector: 'app-lend-private-equity',
    templateUrl: './lend-private-equity.component.html',
    styleUrl: './lend-private-equity.component.scss'
})

export class LendPrivateEquityComponent implements OnInit {
    @Input() pool: IPrivateEquityPool = new PrivateEquityPool();

    window: Window;

    constructor() {
        this.window = getWindow();
    }

    ngOnInit(): void {
        console.log('lend-private-equity.component.ts ngOnInit()');
    }
}