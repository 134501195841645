import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: [ './not-found.component.scss' ]
})

export class NotFoundComponent implements OnInit {
    theme: string = 'dark';

    constructor(public httpService: HttpService, private router: Router) { }

    ngOnInit(): void {
        console.log('not-found.component.ts ngOnInit()');
    }

    goHome() {
        this.router.navigate([ '/' ]);
    }
}
