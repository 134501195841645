import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, OnInit, PLATFORM_ID 
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import {
    Transaction, Connection 
} from '@solana/web3.js';
import { HttpService } from '../../services/http.service';
import { PhantomService } from '../../services/phantom.service';
import { WalletService } from '../../services/wallet.service';
import { MetamaskService } from '../metamask.service';
import { Router } from '@angular/router';
import { nullChecker } from '../../helpers/nullChecker';
import IPayFiPool from '../../interfaces/IPayFiPool';
import PayFiPool from '../../models/PayFiPool';
import { PayFiService } from '../../services/payfi.service';
import {
    DecimalPipe, isPlatformBrowser 
} from '@angular/common';
import IBank from '../../interfaces/IBank';
import ICheckbox from '@crediblefinance/credible-ui/lib/cf-checkbox-large/ICheckbox';

@Component({
    selector: 'app-new-payfi-drawdown',
    templateUrl: './new-payfi-drawdown.component.html',
    styleUrl: './new-payfi-drawdown.component.scss'
})
export class NewPayFiDrawdownComponent implements OnInit {
    platformId: object = {};
    isBrowser: boolean = false;

    collateral_amount_usd: number = 0;
    collateral_amount: number = 0;
    collateral_currency_precision: string = '0.0-2';
    collateralCurrencyDropdownOptions: Array<IDropdown> = [];
    collateral_currency: string = '';
    
    received_amount: number = 0;
    received_currency_dropdown_options: Array<IDropdown> = [];
    received_currency: string = '';
    received_currency_precision: string = '0.0-2';

    tenure: string = '28';
    tenure_dropdown_options: Array<IDropdown> = [];

    repayment_amount: number = 0;
    interest: number = 0;

    currentMintAddressMap: any = {};

    fee: number = 0;

    conversion_rate: number = 0;

    loading: boolean = true;
    new_drawdown_loading: boolean = false;
    transaction: any;

    drawdown_id: string = '';
    confirm_btn_label: string = 'Confirm';

    pools: Array<IPayFiPool> = [];
    poolDropdownOptions: Array<IDropdown> = [];

    userCollateralInfo: any = {};
    balance: number = 0;
    differentBlockchain: boolean = false;
    differentNetwork: boolean = false;

    bank_options: Array<IDropdown> = [];
    payment_method_options: Array<IDropdown> = [];
    collateral_options: Array<IDropdown> = [];
    bank_type_options: Array<ICheckbox> = [{
        label: 'Savings',
        value: 'savings',
        optionEnabled: true
    },
    {
        label: 'Current',
        value: 'current',
        optionEnabled: true
    }];
    bank_type: string = this.bank_type_options[0].value;

    selected_bank_id: string = '';
    selected_payment_method_id: string = '';
    bank_add_loading: boolean = false;
    steps = [{
        step: 'payout',
        title: '',
        completed: false
    },
    {
        step: 'tenure',
        title: '',
        completed: false
    },
    {
        step: 'payment-method',
        title: '',
        completed: false
    },
    {
        step: 'collateral',
        title: '',
        completed: false
    },
    {
        step: 'summary',
        title: '',
        completed: false
    }];
    currentStep = -1;
    step_skipping_allowed: boolean = false;

    selected_pool: IPayFiPool = new PayFiPool();
    account_holder_name: string = '';
    account_number: string = '';
    ifsc: string = '';
    bank_name: string = '';
    all_payout_currencies: Array<any> = [];
    min_payout_amount: number = 0;
    
    setIntervalId: any = null;
    timer: number = 0;
    initial_timer: number = 60;
    additional_collateral: number = 0;

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private decimalPipe: DecimalPipe,
        private walletService: WalletService,
        private metamaskService: MetamaskService,
        private payFiService: PayFiService,
        private router: Router,
        @Inject(PLATFORM_ID) platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        console.log('new-payfi-drawdown.component.ts ngOnInit()');

        this.getPools();
        this.getTenureDropdownOptions();
        this.getUserTotalCollateral();
        this.checkNextStep();
    }

    getPools() {
        this.payFiService.getPools().subscribe(res => {
            this.pools = res.data;

            this.getCollateralCurrencies();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    getCurrencyBalance(blockchain: string, chain: string, wallet_address: string, mint_address: string) {
        if (!this.isBrowser || this.differentBlockchain || this.differentNetwork)
            return;

        this.httpService.getCurrencyBalance(blockchain, chain, wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getUserTotalCollateral() {
        this.payFiService.getUserTotalCollateral().subscribe((res: any) => {
            this.userCollateralInfo = res.data;
        }, (err: HttpErrorResponse) => {
            console.error('getUserTotalCollateral error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    receiveCurrencyChanged(option: IDropdown) {
        this.received_currency = option.value;

        for (let i = 0; i < this.all_payout_currencies.length; i++) {
            if (this.all_payout_currencies[i].key === this.received_currency)
                this.min_payout_amount = this.all_payout_currencies[i].min_drawdown;
        }

        console.log('min_payout_amount', this.min_payout_amount);

        this.loanAmountChanged(this.min_payout_amount);
        this.getPaymentMethods(this.received_currency);
        this.getUserBankAccounts(this.received_currency);
    }

    setConfirmBtnLabel() {
        if (this.additional_collateral > 0)
            this.confirm_btn_label = 'Pay ' + this.decimalPipe.transform(this.collateral_amount, this.collateral_currency_precision) + ' ' + this.collateral_currency.toUpperCase();

        else
            this.confirm_btn_label = 'Confirm';
    }

    loanAmountChanged(amount: number) {
        if (!amount)
            amount = 0;

        this.received_amount = parseFloat(amount.toString());
        this.fee = this.received_amount * this.selected_pool.drawdown_fee_percentage / 100;

        this.conversion_rate = this.httpService.currencyConversionMap[this.collateral_currency].usd * this.getFiatRate(this.received_currency);

        // without considering LTV
        const collateral_amount = (this.received_amount + this.fee) / this.conversion_rate;

        // with considering LTV
        this.collateral_amount = collateral_amount / (this.selected_pool.approved_ltv_percentage / 100);
        this.collateral_amount_usd = this.collateral_amount * this.httpService.currencyConversionMap[this.collateral_currency].usd;

        this.interest = this.received_amount * this.selected_pool.drawdown_yield_rate / 100 / 365 * parseInt(this.tenure);
        this.repayment_amount = this.received_amount * this.selected_pool.drawdown_yield_rate / 100 / 365 * this.selected_pool.repayment_frequency_days;

        this.additional_collateral = this.collateral_amount_usd - this.userCollateralInfo?.collateral_usd + this.userCollateralInfo?.dropdown_usd;
        
        console.log('collateral_amount', this.collateral_amount);

        this.setConfirmBtnLabel();
    }

    tenureChanged(option: IDropdown) {
        this.tenure = option.value;
        this.interest = this.received_amount * this.selected_pool.drawdown_yield_rate / 100 / 365 * parseInt(this.tenure);
    }

    getTenureDropdownOptions() {
        const options = [];

        for (let i = 1; i <= 52; i++) {
            options.push({
                label: i === 1 ? `${i} week (${i * 7} days)` : `${i} weeks (${i * 7} days)`,
                value: (i * 7).toString(),
                optionEnabled: true
            });
        }

        this.tenure_dropdown_options = options;
    }

    changeBank(option: IDropdown) {
        this.selected_bank_id = option.value;

        console.log('selected_bank_id', this.selected_bank_id);
    }

    changeCollateralCurrency(option: IDropdown) {
        this.collateral_currency = option.value;

        console.log('collateral_currency', this.collateral_currency);
    }

    changePaymentMethod(option: IDropdown) {
        this.selected_payment_method_id = option.value;

        console.log('selected_payment_method_id', this.selected_payment_method_id);
    }

    createDrawdown() {
        if (nullChecker(this.received_amount)) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (nullChecker(this.received_currency)) {
            this.cfAlertService.showMessage('Please select receiving currency', true);

            return;
        }

        if (nullChecker(this.collateral_currency)) {
            this.cfAlertService.showMessage('Please select collateral currency', true);

            return;
        }

        if (this.received_amount < this.selected_pool.min_borrow_amount) {
            this.cfAlertService.showMessage(`Minimum borrow amount is ${this.selected_pool.min_borrow_amount.toFixed(2)} ${this.received_currency.toUpperCase()}`, true);

            return;
        }

        if (this.collateral_amount > this.balance) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        this.new_drawdown_loading = true;

        const body = {
            collateral_amount: this.collateral_amount,
            received_amount: this.received_amount,
            received_currency: this.received_currency,
            tenure: this.tenure,
            collateral_currency: this.collateral_currency,
            pool_id: this.selected_pool.pool_id,
            bank_id: this.selected_bank_id
        };
  
        this.payFiService.createDrawdown(body).subscribe((res: any) => {
            this.drawdown_id = res.data.drawdown_id;

            if ('transaction' in res.data) {
                const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));

                this.sendSolanaTransaction(transaction);
            }
            else if (res.data.abi) {
                const abi = res.data.abi;
                const gas = res.data.gas;
                const contract_address = res.data.contract_address;

                console.log('withdrawal', abi, contract_address, gas);
                
                this.sendEthereumTransaction(abi, contract_address, gas);
            }
        }, (err: HttpErrorResponse) => {
            console.error('createDrawdown error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_drawdown_loading = false;
        });
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        return undefined;
    }

    updateSignature(signature: string, error_code: number) {
        const body = {
            drawdown_id: this.drawdown_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.payFiService.updateDrawdown(body).subscribe((res: any) => {
            this.new_drawdown_loading = false;

            this.cfAlertService.showMessage(res.message);

            this.router.navigateByUrl( '/transactions?tab=my-payfi-drawdowns');
        }, (err: HttpErrorResponse) => {
            console.error('updateSignature error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_drawdown_loading = false;
        });
    }

    async sendSolanaTransaction(transaction: Transaction) {
        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res getSignature', res);

            if (!res || !res.signature)
                return;

            transaction = res;

            const connection = new Connection(this.selected_pool.rpc, 'confirmed');

            console.log('transaction', transaction);

            const txnSignature = await connection.sendRawTransaction(transaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            }));

            console.log('txn signature', txnSignature);

            this.updateSignature(txnSignature, -1);
        }

        catch (err) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            this.cfAlertService.showError(new HttpErrorResponse({
                error: err
            }));

            this.new_drawdown_loading = false;
        }
    }

    async sendEthereumTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('sendEthereumTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    async getEthereumSignature(abi: string, contract_address: string, gas: number) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(abi, contract_address, this.httpService.user.wallet_address, gas);

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    getUserBankAccounts(received_currency: string) {
        this.payFiService.getUserBankAccounts(received_currency).subscribe((res: any) => {
            const banks: Array<IBank> = res.data;

            const options = [];

            for (let i = 0; i < banks.length; i++) {
                options.push({
                    label: `${banks[i].bank_name} (${this.httpService.displayBankAccountNumber(banks[i].account_number)})`,
                    value: banks[i].bank_id,
                    optionEnabled: true
                });
            }

            options.push({
                label: 'Add new beneficiary',
                value: 'new',
                optionEnabled: true
            });

            this.bank_options = options;
            this.selected_bank_id = this.bank_options[0].value;
        }, (err: HttpErrorResponse) => {
            console.error('getUserBankAccounts error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getPaymentMethods(payout_currency: string) {
        const payment_method_options = [];

        const index = this.all_payout_currencies.findIndex((item: any) => item.key === payout_currency);

        for (let i = 0; i < this.all_payout_currencies[index].payout_methods.length; i++) {
            payment_method_options.push({
                label: this.all_payout_currencies[index].payout_methods[i].label,
                value: this.all_payout_currencies[index].payout_methods[i].key,
                optionEnabled: true
            });
        }

        this.payment_method_options = payment_method_options;
        this.selected_payment_method_id = this.payment_method_options[0].value;
    }

    getCollateralCurrencies() {
        this.payFiService.getCollateralCurrencies().subscribe((res: any) => {
            const currencies = res.data;

            const options: Array<IDropdown> = [];

            for (let i = 0; i < currencies.length; i++) {
                options.push({
                    label: currencies[i].label,
                    value: currencies[i].key,
                    logo: this.httpService.getCurrencyUrl(currencies[i].key),
                    optionEnabled: true
                });
            }

            this.collateral_options = options;
            this.collateral_currency = this.collateral_options[0].value;

            for (let i = 0; i < this.pools.length; i++) {
                if (this.pools[i].drawdown_currency === this.collateral_currency) {
                    this.selected_pool = this.pools[i];

                    break;
                }
            }

            this.getCurrencyBalance(this.selected_pool.blockchain, this.selected_pool.chain, this.httpService.user.wallet_address, this.selected_pool.drawdown_currency_mint_address);

            this.getPayoutCurrencies();

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error('getUserBankAccounts error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getFiatRate(fiat_currency: string) {
        for (let i = 0; i < this.all_payout_currencies.length; i++) {
            if (this.all_payout_currencies[i].key === fiat_currency)
                return this.all_payout_currencies[i].usd_rate;
        }

        return 0;
    }

    getPayoutCurrencies() {
        this.payFiService.getPayoutCurrencies().subscribe((res: any) => {
            this.all_payout_currencies = res.data;

            const options: Array<IDropdown> = [];

            for (let i = 0; i < this.all_payout_currencies.length; i++) {
                options.push({
                    label: this.all_payout_currencies[i].label,
                    value: this.all_payout_currencies[i].key,
                    logo: this.httpService.getCurrencyUrl(this.all_payout_currencies[i].key),
                    optionEnabled: true
                });
            }

            this.received_currency_dropdown_options = options;
            this.received_currency = this.received_currency_dropdown_options[0].value;
            this.received_currency_precision = this.httpService.getPrecision(this.received_currency);

            this.getPaymentMethods(this.received_currency);

            this.min_payout_amount = this.all_payout_currencies[0].min_drawdown;

            this.loanAmountChanged(this.min_payout_amount);
            this.getUserBankAccounts(this.received_currency);
        }, (err: HttpErrorResponse) => {
            console.error('getUserBankAccounts error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    checkNextStep() {
        let currentStep = -1;

        for (let i = 0; i < this.steps.length; i++) {
            if (!this.steps[i].completed) {
                currentStep = i;
                break;
            }
        }
    
        console.log('checkNextStep => ', currentStep);
    
        if (currentStep === -1) 
            console.log('All steps completed');
    
        else 
            this.currentStep = currentStep;
    }

    changeStep(index: number) {
        if (this.step_skipping_allowed)
            this.currentStep = index;
    }

    next1Completed() {
        if (this.received_amount < this.min_payout_amount) {
            const min_payout_amount = this.decimalPipe.transform(this.min_payout_amount, '0.0-2');

            this.cfAlertService.showMessage(`Minimum payout amount is ${min_payout_amount} ${this.received_currency.toUpperCase()}`, true);

            return;
        }

        this.steps[0].completed = true;
        this.checkNextStep();
    }

    next2Completed() {
        this.steps[1].completed = true;
        this.checkNextStep();
    }

    next3Completed() {
        if (this.selected_bank_id === 'new')
            this.addUserBankAccount();

        else {
            this.steps[2].completed = true;
            this.checkNextStep();
        }
    }

    next4Completed() {
        this.steps[3].completed = true;
        this.checkNextStep();

        this.closeTimer();
        this.startTimer();
    }

    previous2Completed() {
        this.steps[0].completed = false;
        this.checkNextStep();
    }

    previous3Completed() {
        this.steps[1].completed = false;
        this.checkNextStep();
    }

    previous4Completed() {
        this.steps[2].completed = false;
        this.checkNextStep();
    }

    previous5Completed() {
        this.steps[3].completed = false;
        this.checkNextStep();
    }

    accountHolderNameChanged(name: string) {
        this.account_holder_name = name;
    }

    accountNumberChanged(number: string) {
        this.account_number = number;
    }

    ifscCodeChanged(code: string) {
        this.ifsc = code;
    }

    bankNameChanged(name: string) {
        this.bank_name = name;
    }

    addUserBankAccount() {
        this.bank_add_loading = true;

        const body = {
            account_holder_name: this.account_holder_name,
            account_number: this.account_number,
            currency: this.received_currency,
            ifsc: this.ifsc,
            type: this.bank_type,
            bank_name: this.bank_name
        };

        this.payFiService.addUserBankAccount(body).subscribe((res: any) => {
            const added_bank = res.data;

            this.bank_options.push({
                label: `${added_bank.bank_name} (${this.httpService.displayBankAccountNumber(added_bank.account_number)})`,
                value: added_bank.bank_id,
                optionEnabled: true
            });

            this.selected_bank_id = added_bank.bank_id;

            this.bank_add_loading = false;

            this.steps[2].completed = true;
            this.checkNextStep();
        }, (err: HttpErrorResponse) => {
            this.bank_add_loading = false;

            console.error('addUserBankAccount error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    changeBankTyepOption(option: ICheckbox) {
        console.log('changeBankTyepOption', option);

        this.bank_type = option.value;
    }

    closeTimer() {
        if (this.setIntervalId)
            clearInterval(this.setIntervalId);
    }

    startTimer() {
        this.timer = this.initial_timer;

        this.setIntervalId = setInterval(() => {
            this.timer = this.timer - 0.1;

            if (this.timer <= 0)
                this.timer = this.initial_timer;
        }, 100);
    }
}
