import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit 
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import ICircleWithrawal from '../../../interfaces/ICircleWithdrawal';
import IUser from '../../../interfaces/IUser';
import User from '../../../models/User';
import { HttpService } from '../../../services/http.service';
import { CircleService } from '../../circle.service';
import { Router } from '@angular/router';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-circle-withdrawals',
    templateUrl: './circle-withdrawals.component.html',
    styleUrl: './circle-withdrawals.component.scss'
})
export class CircleWithdrawalsComponent implements OnInit {
    user: IUser = new User();
    loading: boolean = false;
    loading_stats: boolean = false;
    currentTime: number = (new Date()).getTime();
    withdrawals: Array<ICircleWithrawal> = [];

    total_count: number = 0;
    page: number = 0;
    limit: number = 10;
    opened_panel: string = '';

    statusOptions: Array<IDropdown> = [{
        label: 'All',
        value: '',
        optionEnabled: true
    },
    {
        label: 'Requested',
        value: 'requested',
        optionEnabled: true
    },
    {
        label: 'Sent',
        value: 'sent',
        optionEnabled: true
    }, {
        label: 'Confirmed',
        value: 'confirmed',
        optionEnabled: true
    }, {
        label: 'Complete',
        value: 'complete',
        optionEnabled: true
    }, {
        label: 'Failed',
        value: 'failed',
        optionEnabled: true
    }];
    selected_status: string = '';

    window: Window;

    constructor(
        public httpService: HttpService, 
        private cfAlertService: CfAlertService,
        private router: Router,
        private circleService: CircleService
    ) { 
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.httpService.setMetaData('My withdrawals', 'withdrawals made by the user');

        this.getwithdrawals();
    }

    afterExpand(lending_id: string) {
        this.opened_panel = lending_id;
    }

    getwithdrawals() {
        this.loading = true;

        const body = {
            page: this.page,
            limit: this.limit,
            token_required: true,
            status: this.selected_status
        };

        this.circleService.getWithdrawals(body).subscribe((res: any) => {
            this.withdrawals = res.data;
            this.total_count = res.total_count;

            if (this.withdrawals.length > 0)
                this.opened_panel = this.withdrawals[0].blockchain_txid;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    pageChanged(event: any) {
        this.page = event;
        this.getwithdrawals();
    }

    redirectionPool(category_id: string, pool_id: string) {
        this.router.navigate([ `/liquidity/${category_id}/${pool_id}` ]);
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    changeStatus(event: any) {
        this.selected_status = event.value;
        this.page = 0;
        this.getwithdrawals();
    }

    getStatusClasses(id: string, status: string) {
        const classes: any = {};

        if (this.opened_panel === id)
            classes['current-opened'] = true;

        if (status === 'pending')
            classes['status-pending'] = true;

        else if (status === 'processing')
            classes['status-processing'] = true;

        else if (status === 'complete')
            classes['status-success'] = true;

        else if (status === 'failed')
            classes['status-failed'] = true;

        return classes;
    }

    redirectTx(blockchain: string, blockchain_txid: string) {
        let url = '';

        if (blockchain === 'SOL-DEVNET')
            url = `https://explorer.solana.com/tx/${blockchain_txid}?cluster=devnet`;

        else
            url = `https://explorer.solana.com/tx/${blockchain_txid}`;

        this.window.open(url, '_blank');
    }
}
