<div class="app-ambassador-leaderboard">
    <div class="top-section">
        <div class="section-heading">
            Top Liquidity Members
        </div>
        <div class="level-dropdown" matTooltip="Referral Level">
            <cf-dropdown [options]="levelOptions" [showLogo]="false" [showLabel]="false" (change)="levelChange($event)"
                [selected]="current_level"></cf-dropdown>
        </div>
    </div>

    <div class="top-liquidity-members">
        <table>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Wallet Address</th>
                    <th>Level</th>
                    <th>Liquidity Added</th>
                </tr>
            </thead>
            <tbody *ngIf="topPerformers.length > 0">
                <tr *ngFor="let topPerformer of topPerformers; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ topPerformer.wallet_address }}</td>
                    <td>Level {{ topPerformer.level }}</td>
                    <td>{{ topPerformer.tvl_usd }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="topPerformers.length === 0" class="no-data-tbody">
                <tr>
                    <td colspan="4">
                        <div class="no-data-td">No data available</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="section-heading">
        Top CRED Members
    </div>

    <div class="top-cred-members">
        <table>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Wallet Address</th>
                    <th>Level</th>
                    <th>CRED purchased</th>
                </tr>
            </thead>
            <tbody *ngIf="topCredUsers.length > 0">
                <tr *ngFor="let topCredUser of topCredUsers; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ topCredUser.wallet_address }}</td>
                    <td>Level {{ topCredUser.level }}</td>
                    <td>{{ topCredUser.cred_purchased }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="topCredUsers.length === 0" class="no-data-tbody">
                <tr>
                    <td colspan="4">
                        <div class="no-data-td">No data available</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
