<div class="app-payfi-widget" [ngClass]="httpService.theme">
    <cf-card [theme]="httpService.theme" type="empty">
        <div class="payfi-widget-sub-section">
            <div class="payfi-widget-sub-section-tabs">
                <div class="button-container mb-4">
                    <button type="button" class="button" [ngClass]="currentTab === tab.key ? 'active' :''"
                        (click)="changedTab(tab.key)" *ngFor="let tab of tabs">
                        {{ tab.label }}
                    </button>
                </div>
            </div>

            <div class="tabs">
                <app-new-payfi-drawdown *ngIf="currentTab === 'drawdown'"></app-new-payfi-drawdown>
                <app-new-payfi-collateral *ngIf="currentTab === 'collateral'"></app-new-payfi-collateral>
                <app-new-payfi-repayment *ngIf="currentTab === 'repayment'"></app-new-payfi-repayment>
                <app-new-investment *ngIf="currentTab === 'lending'" [pool_id]="lending_pool_id"></app-new-investment>
                <app-new-withdrawal *ngIf="currentTab === 'withdrawal'" [pool_id]="lending_pool_id"></app-new-withdrawal>
                <app-new-earning *ngIf="currentTab === 'earnings'" [pool_id]="lending_pool_id"></app-new-earning>
            </div>
        </div>
    </cf-card>
</div>