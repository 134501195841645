<div class="app-dashboard" [ngClass]="httpService.theme">
    <div class="section-1">
        <span class="section-heading">Hello {{ httpService.user.display_name }}</span>

        <div class="overview">
            <div class="left-side">
                <cf-card [theme]="httpService.theme" type="empty">
                    <div class="balance-container">
                        <span class="balance-container-title">Portfolio balance</span>
                        <span class="balance-container-value">
                            <span style="font-size: 18px !important"> $</span>
                            {{ total_balance | number : "0.0-2" }}</span>

                        <div class="actions">
                            <cf-button [theme]="httpService.theme" type="outline" label="Supply"
                                (clicked)="redirectToUrl('/liquidity')"></cf-button>
                            <cf-button [theme]="httpService.theme" type="outline" label="Withdraw"
                                (clicked)="redirectToUrl('/liquidity')"></cf-button>
                            <cf-button [theme]="httpService.theme" type="outline" label="Earnings"
                                (clicked)="redirectToUrl('/liquidity')"></cf-button>
                        </div>
                    </div>
                </cf-card>

                <cf-card [theme]="httpService.theme" type="empty">
                    <div class="invite-link-container">
                        <div class="invite-container">
                            <cf-input [theme]="httpService.theme" [value]="invitation_link" [label]="'Invite Link'"
                                [readonly]="true" [copy]="true" theme="dark" [trim]="trimInvitationLink"
                                [trimLength]="30">
                            </cf-input>
                        </div>
                    </div>
                </cf-card>

                <div class="friends-container">
                    <cf-card [theme]="httpService.theme" type="empty">
                        <div class="friend-container">
                            <div class="friend-container-value-extra">
                                <div class="unclaimed-interest">
                                    <div style="font-size: 18px !important">
                                        $</div>
                                    <div>{{ unclaimed_interest_usd | number : "0.0-2" }}</div>
                                    <div class="refresh-icon" *ngIf="
                                    unclaimed_interest_loaded &&
                                    httpService.user.wallet_address !== ''
                                ">
                                        <i class="fas fa-redo fa-sm custom-size" [matTooltip]="
                                        unclaimed_interest_tooltip_text
                                    " (click)="updateUserUnclaimedInterest()" style="color: #ffffff"></i>
                                    </div>
                                </div>
                                <div class="refresh" *ngIf="
                                    !unclaimed_interest_loaded &&
                                    httpService.user.wallet_address !== ''
                                ">
                                    <cf-loader [theme]="httpService.theme" [diameter]="20"></cf-loader>
                                </div>
                            </div>
                            <div class="friend-container-title">
                                Unclaimed earnings
                            </div>
                        </div>
                    </cf-card>

                    <cf-card [theme]="httpService.theme" type="empty">
                        <div class="friend-container">
                            <div class="friend-container-value">
                                {{ this.httpService.user.diamonds | number:'0.0-0' }}
                            </div>
                            <div class="friend-container-title">Diamonds</div>
                        </div>
                    </cf-card>
                </div>
            </div>

            <div class="right-side">
                <cf-card [theme]="httpService.theme" type="empty">
                    <div class="portfolio-container">
                        <div class="portfolio-chart-controls">
                            <span class="card-heading">Portfolio</span>

                            <div class="portfolio-chart-duration" matTooltip="Select chart step size">
                                <cf-dropdown [theme]="httpService.theme" [options]="portfolioOptions" [showLogo]="false"
                                    [showLabel]="false"></cf-dropdown>
                            </div>
                        </div>

                        <div class="portfolio-chart" *ngIf="chart_loaded">
                            <app-line-chart [lineConfig]="chartLineConfig" title="" [showLegend]="false"
                                [currency]="'USDC'" [height]="350"></app-line-chart>
                        </div>
                    </div>
                </cf-card>
            </div>
        </div>
    </div>

    <div class="section-2">
        <cf-card [theme]="httpService.theme" type="empty">
            <cf-tabs [tabs]="tabs" [currentTab]="currentTab" (changed)="changeTab($event)"
                [theme]="httpService.theme"></cf-tabs>

            <div class="section-5" *ngIf="currentTab === 'pools'">
                <div class="pools-container">
                    <div class="pools">
                        <table class="web">
                            <thead>
                                <tr>
                                    <th class="pool-name-header">Pool</th>
                                    <th>Balance</th>
                                    <th>Weightage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of active_pools">
                                    <td>
                                        <div class="pool-name-container" *ngIf="item?.pool_id"
                                            (click)="redirectionPool(item.category_id, item.pool_id)">
                                            <img [src]="item.pool_logo" [alt]="item.pool_logo" class="pool-logo" />
                                            <span class="pool-name">{{
                                                item.pool_name
                                                }}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="user-balance">
                                            {{ item.user_balance | number : "0.0-2" }}
                                            {{ item.pool_currency | uppercase }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="pool-weight">
                                            <div class="pool-weight-progress-bar">
                                                <div class="animated-weight"
                                                    [ngStyle]="{ width: item.animated_weight + '%' }"></div>
                                            </div>
                                            {{ item.animated_weight | number : "0.0-0" }} %
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="active_pools.length === 0">
                                    <td colspan="3" class="no-record">
                                        No pools available
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot></tfoot>
                        </table>

                        <div class="mobile">
                            <table>
                                <tbody>
                                    <tr *ngFor="let item of active_pools">
                                        <table>
                                            <tr>
                                                <td class="table-heading">
                                                    <span>Pool</span>
                                                </td>
                                                <td class="table-value">
                                                    <div class="pool-name-container" *ngIf="item?.pool_id" (click)="
                                                        redirectionPool(item.category_id,item.pool_id)
                                                    ">
                                                        <img [src]="item.pool_logo" [alt]="item.pool_logo"
                                                            class="pool-logo" />
                                                        <span class="pool-name ellipses">{{
                                                            item.pool_name
                                                            }}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-heading">
                                                    <span>Balance</span>
                                                </td>
                                                <td class="table-value">
                                                    <div class="user-balance">
                                                        {{ item.user_balance | number : "0.0-2" }}
                                                        {{ item.pool_currency | uppercase }}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-heading">
                                                    <span>Weightage</span>
                                                </td>
                                                <td class="table-value">
                                                    <div class="pool-weight">
                                                        <div class="pool-weight-progress-bar">
                                                            <div class="animated-weight"
                                                                [ngStyle]="{ width: item.animated_weight + '%' }"></div>
                                                        </div>
                                                        {{ item.animated_weight | number : "0.0-0" }} %
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </tr>
                                    <tr *ngIf="active_pools.length === 0">
                                        <td colspan="2" class="no-record">
                                            No pools found
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot></tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-6" *ngIf="currentTab === 'assets'">
                <div class="assets-container">
                    <div class="assets">
                        <table class="web">
                            <thead>
                                <tr>
                                    <th>Pool</th>
                                    <th>User Balance</th>
                                    <th>Tx Hash</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of assets">
                                    <td>
                                        <div class="pool-name-container" *ngIf="item?.pool_id"
                                            (click)="redirectionPool(item.category_id, item.pool_id)">
                                            <img [src]="item.pool_logo" [alt]="item.pool_logo" class="pool-logo" />
                                            <span class="pool-name ellipses">{{
                                                item.pool_name
                                                }}</span>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.user_tvl | number : "0.0-2" }}
                                    </td>
                                    <td>
                                        <div class="redirection">
                                            <span class="ellipses">{{ item.lp_currency_mint_address }}
                                            </span>
                                            <i class="fa-solid fa-up-right-from-square ml icon" (click)="
                                                httpService.redirectionTx(item.blockchain, item.chain, item.network,
                                                    item.lp_currency_mint_address
                                                )
                                            "></i>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="assets.length === 0">
                                    <td colspan="3" class="no-record">
                                        No assets available
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot></tfoot>
                        </table>

                        <div class="mobile">
                            <table>
                                <tbody>
                                    <tr *ngFor="let item of assets">
                                        <table>
                                            <tr>
                                                <td class="table-heading">
                                                    <span>Pool</span>
                                                </td>
                                                <td class="table-value">
                                                    <div class="pool-name-container" *ngIf="item?.pool_id" (click)="
                                                redirectionPool(item.category_id, item.pool_id)
                                                    ">
                                                        <img [src]="item.pool_logo" [alt]="item.pool_logo"
                                                            class="pool-logo" />
                                                        <span class="pool-name">{{
                                                            item.pool_name
                                                            }}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-heading">
                                                    <span>User Balance</span>
                                                </td>
                                                <td class="table-value">
                                                    {{ item.user_tvl | number : "0.0-2" }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-heading">
                                                    <span>Tx Hash</span>
                                                </td>
                                                <td class="table-value">
                                                    <div class="redirection">
                                                        <span class="ellipses">{{ item.lp_currency_mint_address }}
                                                        </span>
                                                        <i class="fa-solid fa-up-right-from-square ml icon" (click)="
                                                            httpService.redirectionAddress(item.blockchain, item.chain, item.network, 
                                                                item.lp_currency_mint_address
                                                            )
                                                        "></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </tr>
                                    <tr *ngIf="assets.length === 0">
                                        <td colspan="2" class="no-record">
                                            No assets found
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot></tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </cf-card>
    </div>

    <div class="section-4">
        <cf-card [theme]="httpService.theme" type="empty">
            <div class="transactions-container">
                <span class="card-heading">Recent Transactions</span>

                <div class="transactions">
                    <table class="web">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Date</th>
                                <th>Pool</th>
                                <th>Amount</th>
                                <th>Tx Hash</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of recentTransactions">
                                <td [ngClass]="{
                                    'green-text': item.type === 'Supply',
                                    'red-text': item.type === 'Withdrawal',
                                    'golden-text': item.type === 'Earning'
                                }">
                                    {{ item.type }}
                                </td>
                                <td>{{ item.created | date : "mediumDate" }}</td>
                                <td>
                                    <div class="pool-name-container" *ngIf="item?.pool_id"
                                        (click)="redirectionPool(item.category_id, item.pool_id)">
                                        <img [src]="item.pool_image_url" [alt]="item.pool_image_url"
                                            class="pool-logo" />
                                        <span class="pool-name">{{
                                            item.pool_name
                                            }}</span>
                                    </div>
                                </td>
                                <td>
                                    {{
                                    item.amount
                                    | number
                                    : httpService.getPrecision(
                                    item.currency
                                    )
                                    }}
                                    {{ item.currency | uppercase }}
                                </td>
                                <td>
                                    <div class="redirection">
                                        <span class="ellipses">{{ item.blockchain_txid }}
                                        </span>
                                        <i class="fa-solid fa-up-right-from-square ml icon" (click)="
                                            httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)
                                        "></i>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="recentTransactions.length === 0">
                                <td colspan="5" class="no-record">
                                    No recent transactions
                                </td>
                            </tr>
                        </tbody>
                        <tfoot></tfoot>
                    </table>

                    <div class="mobile">
                        <table>
                            <tbody>
                                <tr *ngFor="let item of recentTransactions">
                                    <table>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Type</span>
                                            </td>
                                            <td class="table-value">
                                                <div [ngClass]="{
                                                'green-text': item.type === 'Supply',
                                                'red-text': item.type === 'Withdrawal',
                                                'golden-text': item.type === 'Earning'
                                            }">
                                                    {{ item.type }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Date</span>
                                            </td>
                                            <td class="table-value">
                                                {{
                                                item.created
                                                | date : "mediumDate"
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Pool</span>
                                            </td>
                                            <td class="table-value">
                                                <div class="pool-name-container" *ngIf="item?.pool_id" (click)="
                                            redirectionPool(item.category_id, item.pool_id)
                                                ">
                                                    <img [src]="item.pool_image_url" [alt]="item.pool_image_url"
                                                        class="pool-logo" />
                                                    <span class="pool-name">{{
                                                        item.pool_name
                                                        }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Amount</span>
                                            </td>
                                            <td class="table-value">
                                                {{
                                                item.amount
                                                | number
                                                : httpService.getPrecision(
                                                item.currency
                                                )
                                                }}
                                                {{ item.currency | uppercase }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Tx Hash</span>
                                            </td>
                                            <td class="table-value">
                                                <div class="redirection">
                                                    <span class="ellipses">{{ item.blockchain_txid }}
                                                    </span>
                                                    <i class="fa-solid fa-up-right-from-square ml icon" (click)="
                                                        httpService.redirectionTx(item.blockchain, item.chain, item.network, 
                                                            item.blockchain_txid
                                                        )
                                                    "></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </tr>
                                <tr *ngIf="recentTransactions.length === 0">
                                    <td colspan="2" class="no-record">
                                        No recent transactions
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </cf-card>
    </div>

    <div class="section-3">
        <cf-card [theme]="httpService.theme" type="empty">

            <div class="activites-container">
                <span class="card-heading">Recent Activity</span>
                <div class="activities">
                    <div class="activity-container" *ngFor="let activity of activites">
                        <div class="activity-description">
                            {{ activity.description }}
                        </div>
                        <div class="activity-time">
                            {{ activity.created | date : "medium" }}
                        </div>
                    </div>
                    <div class="activity-container" *ngIf="activites.length === 0">
                        <div class="no-record">No recent activites</div>
                    </div>
                </div>
            </div>
        </cf-card>
    </div>
</div>