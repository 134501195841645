<div class="app-header-popup-menu" [ngClass]="httpService.theme">
    <span class="risk-score" *ngIf="httpService.user.wallet_address !== ''" (click)="redirectToWebacy()"
        matTooltip="Risk score powered by Webacy" matTooltipPosition="left">

        <div class="d-flex gap-2">
            <span class="">Risk score</span>

            <div class="d-grid">
                <span class="low" *ngIf="risk_score?.risk === 'low'">{{ risk_score?.risk | titlecase }} ({{
                    risk_score.score | number:'0.0-0' }})</span>
                <span class="medium" *ngIf="risk_score?.risk === 'medium'">{{ risk_score?.risk | titlecase }} ({{
                    risk_score.score | number:'0.0-0' }})</span>
                <span class="high" *ngIf="risk_score?.risk === 'high'">{{ risk_score?.risk | titlecase }} ({{
                    risk_score.score | number:'0.0-0' }})</span>
            </div>
        </div>
        <span class="powered-by mt-1">Powered by
            <img src="https://dapp.webacy.com/webacyLogo.svg" />
        </span>
    </span>

    <div class="divider"></div>

    <span class="wallet-address" *ngIf="httpService.user.wallet_address !== ''" (click)="copyWalletAddress()"
        matTooltip="Copy address" matTooltipPosition="left">
        <img src="https://assets.credible.finance/Phantom-Icon-Transparent-Purple.png"
            *ngIf="walletService.current_provider === 'phantom'" />
        <img src="../../assets/images/solflare.png" *ngIf="walletService.current_provider === 'solflare'" />
        <img src="https://assets.credible.finance/logos/Metamask.png"
            *ngIf="walletService.current_provider === 'metamask'" />
        <div class="d-grid">
            {{
            httpService.sliceAddress(httpService.user.wallet_address, 7)
            }}
            <div class="blockchain">
                {{ chain_name | titlecase }}
            </div>
        </div>
    </span>

    <div class="divider"></div>

    <div class="diamonds-holder" *ngIf="httpService.user.wallet_address !== ''">
        <img [width]="20" src="https://assets.credible.finance/IMG312.png" alt="">
        <span>
            {{ this.httpService.user.moons | number }} Moons
        </span>
    </div>

    <div class="divider"></div>

    <span class="portfolio-holder" *ngIf="httpService.user.wallet_address !== ''">
        <i class="fa-solid fa-sack-dollar"></i>
        {{ total_balance | number:'0.0-0' }} USD
    </span>

    <div class="divider"></div>

    <div class="menu-item">
        <img src="https://assets.credible.finance/Logout.png" class="menu-item-image" alt="Loogout" />

        <div class="menu-item-name" (click)="logout()">Logout</div>
    </div>
</div>