<div class="app-footer container-fluid" [ngClass]="httpService.theme">
    <div class="row">
        <div class="d-flex justify-content-center">
            <span class="powered-by">
                © 2023 - {{ currentYear }} Copyright
            </span>
        </div>
    </div>

    <div class="row">
        <span class="warning">
            Disclaimer — The information provided on this website is for illustrative purposes only and does not constitute an offer or solicitation to buy or sell any tokens in any jurisdiction. This content does not represent an offer, solicitation, or sale where such actions would be prohibited, illegal, or in violation of applicable laws, prior to registration or qualification under the relevant securities laws.
        </span>
    </div>
    <div class="row">
        <span class="warning">
            Credible Finance makes no representations or warranties, whether express or implied, regarding the accuracy, reliability, or completeness of the information or opinions presented on this website. You are strongly advised to seek independent legal and financial counsel before making any decisions based on the information provided.
        </span>
    </div>
</div>
