import { isPlatformBrowser } from '@angular/common';
import {
    Inject, Injectable, PLATFORM_ID 
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    platformId: object = {};

    constructor(@Inject(PLATFORM_ID) platformId: object) {
        this.platformId = platformId;
    }

    setItem(key: string, value: string) {
        if (isPlatformBrowser(this.platformId)) 
            localStorage.setItem(key, value);
    }

    getItem(key: string) {
        if (isPlatformBrowser(this.platformId)) 
            return localStorage.getItem(key);
        
        return null;
    }

    removeItem(key: string) {
        if (isPlatformBrowser(this.platformId)) 
            return localStorage.removeItem(key);
        
        return null;
    }

    clear() {
        if (isPlatformBrowser(this.platformId)) 
            return localStorage.clear();
        
        return null;
    }
}
