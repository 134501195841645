<div class="cf-otp">
    <div class="container-fluid">
        <div class="row" *ngIf="showLabel">
            <div class="col-md-12">
                <label class="heading">{{ label }}</label>
            </div>
        </div>
        <div class="inputs">
            <div *ngFor="let item of numbers; let i = index" class="input-holder">
                <input type="text" [autofocus]="focus[i]" (keydown)="handleKeyDown($event, i)"
                    [ngClass]="{'autofocus': focus[i]}" (keyup)="handleKeyUp($event, i)"
                    (paste)="handlePaste($event, i)" [id]="'cf-otp-input-web-' + i" autocomplete="off" maxlength="1"
                    class="web" *ngIf="platform === 'web'" [value]="otp[i]" />

                <input type="text" [autofocus]="focus[i]" (keydown)="handleKeyDown($event, i)"
                    [ngClass]="{'autofocus': focus[i]}" (keyup)="handleKeyUp($event, i)"
                    (paste)="handlePaste($event, i)" [id]="'cf-otp-input-mobile-' + i" autocomplete="off" maxlength="1"
                    class="mobile" [value]="otp[i]" *ngIf="platform === 'mobile'" />
            </div>
        </div>
    </div>
</div>