export default {
  name: 'dao',
  comment: 'DAO rescue hardfork',
  url: 'https://eips.ethereum.org/EIPS/eip-779',
  status: 'Final',
  gasConfig: {},
  gasPrices: {},
  vm: {},
  pow: {}
};
