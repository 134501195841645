/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { getWindow } from 'ssr-window';
import { DiscordService } from '../../services/discord.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import { KycService } from '../../services/kyc.service';
import { TwitterService } from '../../services/twitter.service';
import { OnboardingService } from '../onboarding.service';

@Component({
    selector: 'app-spaceship',
    templateUrl: './spaceship.component.html',
    // eslint-disable-next-line array-bracket-spacing
    styleUrls: ['./spaceship.component.scss']
})
export class SpaceshipComponent implements OnInit {
    width: any;
    constructor(public httpService: HttpService,
        private discordService: DiscordService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private kycService: KycService,
        private twitterService: TwitterService,
        private onboardingService: OnboardingService
    ) {
        this.window = getWindow();
        this.width = this.window.outerWidth;
    }

    tasks: Array<any> = [];
    total_task_count: any;
    completed_task_count: any;
    pending_task_count: any;
    total_user_moon = 0;
    referred_user_count: any;
    invitation_link: any;

    questions: Array<any> = [];
    max: number = 5;
    current: number = 2;
    window: Window;

    public discord: any = {
        loading: false,
        loginButton: true,
        serverJoined: false,
        checkGroupButton: false,
        checkGroupJoinedIntervalId: null,
        accountConnected: false,
        groupCheckAttempt: 0
    };

    public twitter: any = {
        loading: false,
        accountConnected: false
    };

    kyc_loading: boolean = false;
    twitterJoined: boolean = false;
    discordJoined: boolean = false;
    discord_join_loading: boolean = false;
    twitter_join_loading: boolean = false;
    ngOnInit(): void {
        this.getUserTasks();
        this.getSpaceshipFaqQuestions();

        this.invitation_link = `${this.window.location.origin}/invite/${this.httpService.user.referral_code}`;
    }

    get progress(): number {
        return (this.completed_task_count / this.total_task_count) * 100;
    }

    openQues(index: any) {
        this.questions[index].open = true;
    }

    closeQues(index: any) {
        this.questions[index].open = false;
    }

    redirectUrl(url: any) {
        this.window.open(url, '_blank');
    }

    connectWallet() {
        this.router.navigate(['/connect-wallet'], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    loginWith(id: any, redirect_url: string) {
        switch (id) {
            case 'discord':
                this.connectDiscord(redirect_url);
                break;

            case 'kyc':
                this.generateKycUrl();
                break;

            case 'twitter':
                this.connectTwitter(redirect_url);
                break;

            case 'lend_payfi':
                this.lendNow(redirect_url);
                break;

            case 'oracle_license':
                this.oracleLicense(redirect_url);
                break;
        }
    }
    lendNow(redirect_url: any) {
        // const lend_url = redirect_url;

        // this.router.navigate([redirect_url]);

        this.router.navigate(['/payfi'], {
            queryParams: {
                currenttab: 'lending'
            }
        });

        // this.discord_join_loading = true;

        // const body = {
        //     social_media_type: 'discord'
        // };

        // this.apiCall(body);
    }
    oracleLicense(redirect_url: any) {
        const oracle_license_url = redirect_url;

        this.window.open(oracle_license_url, '_blank');
    }
    connectDiscord(redirect_url: any) {
        const discord_url = redirect_url;

        // if (this.width > 768)
        this.window.open(discord_url, '_blank');

        this.discord_join_loading = true;

        const body = {
            social_media_type: 'discord'
        };

        this.apiCall(body);
    }

    connectTwitter(redirect_url: any) {
        // if (this.width > 768)
        this.window.open(redirect_url, '_blank');

        this.twitter_join_loading = true;

        const body = {
            social_media_type: 'twitter'
        };

        this.apiCall(body);
    }

    generateKycUrl() {
        this.kyc_loading = true;

        this.kycService.generateKycUrl(this.httpService.user).subscribe((res: any) => {
            this.kyc_loading = false;

            this.window.location.href = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.kyc_loading = false;

            this.cfAlertService.showError(err);
        });
    }

    getUserTasks() {
        this.httpService.getUserTasks().subscribe((res: any) => {
            this.tasks = res.data.tasks;
            this.total_task_count = res.data.tasks_count;
            this.completed_task_count = res.data.completed_task_count;
            this.pending_task_count = res.data.pending_task_count;
            this.total_user_moon = res.data.total_user_moons;
            this.referred_user_count = res.data.referred_user_count;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    // https://testnet.credible.finance/payfi?currenttab=lending

    getSpaceshipFaqQuestions() {
        this.httpService.getSpaceshipFaqQuestions().subscribe((res: any) => {
            this.questions = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    apiCall(body: any) {
        this.onboardingService.updateSocialMedia(body).subscribe((res: any) => {
            if (body.social_media_type === 'discord') {
                this.discord_join_loading = false;
                this.discordJoined = true;

                this.httpService.user.discord = {
                    id: '',
                    server_joined: true,
                    global_name: '',
                    username: ''
                    // id: 0
                };
            }

            if (body.social_media_type === 'twitter') {
                this.twitter_join_loading = false;
                this.twitterJoined = true;

                this.httpService.user.twitter = {
                    group_joined: true,
                    user_id: 0,
                    screen_name: ''
                };
            }

            this.cfAlertService.showMessage(res.message);

            // if (this.twitterJoined && this.discordJoined)
            //     this.next();
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);

            this.cfAlertService.showError(err);

            if (body.social_media_type === 'discord')
                this.discord_join_loading = false;

            if (body.social_media_type === 'twitter')
                this.twitter_join_loading = false;
        });
    }
}
