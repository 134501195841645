import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, Input, OnChanges, OnInit,
    PLATFORM_ID,
    SimpleChanges
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import {
    Connection, Transaction 
} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { WalletService } from '../../services/wallet.service';
import {Router} from '@angular/router';
import IDrawdown from '../../interfaces/IDrawdown';
import { nullChecker } from '../../helpers/nullChecker';
import { MetamaskService } from '../metamask.service';
import { isPlatformBrowser } from '@angular/common';
import PrivateCreditPool from '../../models/PrivateCreditPool';
import IPrivateCreditPool from '../../interfaces/IPrivateCreditPool';

@Component({
    selector: 'app-new-repayment',
    templateUrl: './new-repayment.component.html',
    styleUrls: [ './new-repayment.component.scss' ]

})

export class NewRepaymentComponent implements OnInit, OnChanges {
    currencyDropdownOptions: Array<IDropdown> = [];
    amount: number = 0;
    amount_usd: number = 0;
    conversion_rate: number = 0;
    currency: string = 'usdc';
    platformId: object = {};
    isBrowser: boolean = false;

    drawdownDropdownOptions: Array<IDropdown> = [];
    drawdowns: Array<IDrawdown> = [];

    repayment_id: string = '';

    loading: boolean = false;
    btn_loading: boolean = false;

    drawdown_info: any = {};
    show_no_drawdowns: boolean = false;

    pool_id: string = '';
    balance: number = 0;
    gas_fees_balance: number = 0;
    @Input() drawdown_id: string = '';
    pool: IPrivateCreditPool = new PrivateCreditPool();

    constructor(
        private metamaskService: MetamaskService,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private walletService: WalletService,
        private router: Router,
        @Inject(PLATFORM_ID) platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        if (this.httpService.user.wallet_address !== '') 
            this.getDrawdowns();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['pool_id'] && changes['pool_id'].currentValue) {
            this.pool_id = changes['pool_id'].currentValue;

            this.getPoolDetails();
        }
    }
    
    getDrawdowns() {
        this.show_no_drawdowns = false;
        this.loading = true;

        const body = {
            page: 0,
            limit: 1000,
            token_required: true,
            usage: 'create_repayment'
        };

        this.httpService.getDrawdowns(body).subscribe(res => {
            this.drawdowns = res.data;

            if (this.drawdowns.length > 0) {
                this.drawdowns.forEach((element: any) => {
                    this.drawdownDropdownOptions.push({
                        label: `${element.amount.toFixed(2)} ${element.currency.toUpperCase()} (${new Date(element.created).toDateString()})`,
                        value: element.drawdown_id,
                        optionEnabled: true
                    });
                });

                if (nullChecker(this.drawdown_id)) {
                    this.drawdown_id = res.data[0].drawdown_id;
                    this.getGasFeeBalance(res.data[0].blockchain, res.data[0].chain);

                    this.drawdown_info = res.data.find((item: any) => {
                        return item.drawdown_id === this.drawdown_id;
                    });
                }

                else 
                    this.drawdown_info = res.data[0];
                
                this.calculateAmount();
    
                this.currencyDropdownOptions.push({
                    label: this.drawdown_info.currency === 'usdt' ? 'Tether USD' : 'Circle USD',
                    value: this.drawdown_info.currency,
                    logo: this.httpService.getCurrencyUrl(this.drawdown_info.currency)
                });

                this.pool_id = this.drawdown_info.pool_id;
            }

            else
                this.show_no_drawdowns = true;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    amountChanged(amount: number) {
        if (!amount) 
            amount = 0;
        
        this.amount = parseFloat(amount.toString());

        console.log( this.currency);
        console.log( this.httpService.currencyConversionMap);

        this.conversion_rate = this.httpService.currencyConversionMap[this.currency].usd;
        this.amount_usd = this.amount * this.conversion_rate;
    }

    changeDrawdown(event: any) {
        this.drawdown_id = event.value;
        this.drawdown_info = this.drawdowns.find((item: any) => {
            return item.drawdown_id === this.drawdown_id;
        });

        this.calculateAmount();
    }

    calculateAmount() {
        this.currency = this.drawdown_info.currency;

        let pending_interest = this.calculatePendingInterest(this.drawdown_info.last_interest_payment_time, this.drawdown_info.outstanding_amount, this.drawdown_info.interest_rate);

        console.log('pending_interest initial : ', pending_interest);

        if (pending_interest < Math.pow(10, -2))
            pending_interest = 0;

        console.log('pending_interest final : ', pending_interest);
    
        this.amountChanged(pending_interest);
    }

    currencyChanged(event: IDropdown) {
        this.currency = event.value;

        this.getCurrencyBalance(this.pool.blockchain, this.pool.chain, this.httpService.user.wallet_address, this.pool.lending_currency_mint_address);
    }

    calculatePendingInterest(interest_claimed_time: number, outstanding_principal_amount: number, interest_rate: number) {
        const current_time = new Date().getTime();
        const difference_in_minutes = (current_time - interest_claimed_time) / 60000;
        
        const interest_per_min = outstanding_principal_amount * interest_rate / 100 / 365 / 24 / 60;
        
        const interest = interest_per_min * difference_in_minutes;
    
        return interest;
    }

    createRepayment() {
        if (isNaN(this.amount) || this.amount === null || this.amount === undefined || this.amount === 0) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Please enter valid amount'
                }
            }));

            return;
        }

        const body = {
            drawdown_id: this.drawdown_id,
            amount: this.amount,
            currency: this.currency.toLowerCase()
        };

        this.btn_loading = true;

        this.httpService.createRepayment(body).subscribe((res: any) => {    
            this.btn_loading = false;
            this.repayment_id = res.data.repayment_id;

            if (res.data.transaction) {
                const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));
                
                this.sendSolanaTransaction(transaction);
            }

            else if (res.data.abi) {
                const abi = res.data.abi;
                const gas = res.data.gas;
                const contract_address = res.data.contract_address;

                console.log('lending', abi, contract_address, gas);
                
                this.sendEthereumTransaction(abi, contract_address, gas);
            }
        }, (err: HttpErrorResponse) => {
            console.error('Withdrawal error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.btn_loading = false;
        });
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    updateSignature(signature: string, error_code: number = -1) {
        const body = {
            drawdown_id: this.drawdown_id,
            repayment_id: this.repayment_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.httpService.updateRepayment(body).subscribe((res: any) => {
            this.btn_loading = false;

            this.cfAlertService.showMessage('Repayment succesful');

            this.router.navigateByUrl('/transactions?tab=my-repayments');
        }, (err: HttpErrorResponse) => {
            console.error('updateRepayment error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.btn_loading = false;
        });
    }

    async sendSolanaTransaction(transaction: Transaction) {
        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature)
                return;
            
            transaction = res;

            const connection = new Connection(this.pool.rpc, 'confirmed');

            console.log('transaction', transaction);

            const txnSignature = await connection.sendRawTransaction(transaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            }));

            console.log('txn signature', txnSignature);

            this.updateSignature(txnSignature);
        }

        catch (err) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            this.cfAlertService.showError(new HttpErrorResponse({
                error: err
            }));

            this.btn_loading = false;
        }
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    approveAllowance() {
        if (isNaN(this.amount) || this.amount === null || this.amount === undefined || this.amount === 0) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (this.balance < this.amount) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        if (this.gas_fees_balance <= 0) {
            this.cfAlertService.showMessage('Insufficient SOL balance', true);

            return;
        }

        this.loading = true;

        const body = {
            amount: this.amount,
            currency: this.currency,
            pool_id: this.pool_id,
            vault_id: ''
        };

        this.httpService.approveAllowance(body).subscribe((res: any) => {
            this.drawdown_id = res.data.drawdown_id;
            
            const abi = res.data.abi;
            const contract_address = res.data.contract_address;
            const gas = res.data.gas;

            console.log('allowance_abi', abi);
            console.log('allowance_contract_address', contract_address);
            console.log('allowance_gas', gas);
                
            this.sendEthereumAllowanceTransaction(abi, contract_address, gas);
        }, (err: HttpErrorResponse) => {
            console.error('approveAllowance error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.loading = false;
        });
    }

    async getEthereumSignature(abi: string, contract_address: string, gas: number) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(abi, contract_address, this.httpService.user.wallet_address, gas);

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    async sendEthereumAllowanceTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;
        let allowance_signature = '';

        try {
            allowance_signature = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('allowance_signature', allowance_signature);

            this.cfAlertService.showMessage('Allowance succesful');

            this.createRepayment();
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }
    }

    async sendEthereumTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('sendEthereumTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    getGasFeeBalance(blockchain: string, chain: string) {
        const wallet_address = this.httpService.user.wallet_address;

        this.httpService.getCurrencyBalance(blockchain, chain, wallet_address).subscribe((res: any) => {
            this.gas_fees_balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getCurrencyBalance(blockchain: string, chain: string, wallet_address: string, mint_address: string) {
        if (!this.isBrowser) {
            this.balance = 0;

            return; 
        }

        this.httpService.getCurrencyBalance(blockchain, chain, wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getPoolDetails() {
        this.loading = true;

        this.httpService.getPoolDetails(this.pool_id).subscribe(res => {
            this.pool = res.data;

            this.getCurrencyBalance(this.pool.blockchain, this.pool.chain, this.httpService.user.wallet_address, this.pool.lending_currency_mint_address);
            this.loading = false;
        }, (err: HttpErrorResponse) => {
            this.loading = false;

            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
}
