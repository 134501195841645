<div class="pool-container" (click)="selectPool(pool)" [ngClass]="httpService.theme">
    <div class="pool-banner">
        <div class="pool-brand">
            <div class="pool-logo">
                <img [src]="pool.image_url" [alt]="pool.name" />
            </div>
            <div class="pool-name">
                <h4>{{ pool.name }}</h4>
            </div>
        </div>
        <div class="pool-rating-container" *ngIf="pool?.borrower_overview?.rating_agency_equivalent">
            <div class="pool-rating">
                <span [matTooltip]="'Indicative rating by ' + pool.borrower_overview.rating_agency">
                    {{
                    pool.borrower_overview
                    .rating_agency_equivalent
                    }}
                </span>
            </div>
            <span class="pool-rating-label">by
                <span (click)="redirectToUrl($event, pool.borrower_overview.rating_agency_url)">{{ pool.borrower_overview.rating_agency }}</span>
            </span>
        </div>
    </div>
    <div class="pool-stats">
        <div class="pool-stat">
            <span class="pool-stat-value">
                <span style="font-size: 14px !important">
                    $</span>

                {{ pool.stats.tvl_usd | number : "0.0-0" }}
            </span>
            <span class="pool-stat-title">Total liquidity</span>
        </div>
        <div class="pool-stat">
            <span class="pool-stat-value">
                {{pool.type}}
            </span>
            <span class="pool-stat-title">Type</span>
        </div>
        <div class="pool-stat">
            <span class="pool-stat-value">
                {{
                pool.lending_yield_rates[
                pool.lending_yield_rates.length - 1
                ].apy
                }}%
            </span>
            <span class="pool-stat-title">APY</span>
        </div>
        <div class="pool-stat">
            <span class="pool-stat-value">
                {{ pool.borrow_yield_currency | uppercase }}
            </span>
            <span class="pool-stat-title">Asset</span>
        </div>
        <div class="pool-stat">
            <span class="pool-stat-value">
                {{
                pool.borrower_overview
                .repayment_frequency_days
                }}
                days
            </span>
            <span class="pool-stat-title">Repayment</span>
        </div>
        <div class="pool-stat">
            <span class="pool-stat-value">
                {{
                pool.kyc_required
                ? "Required"
                : "Not required"
                }}
            </span>
            <span class="pool-stat-title">KYC</span>
        </div>
    </div>
</div>