/*
This file is part of web3.js.

web3.js is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

web3.js is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with web3.js.  If not, see <http://www.gnu.org/licenses/>.
*/
import chainstart from './chainstart.js';
import dao from './dao.js';
import homestead from './homestead.js';
import tangerineWhistle from './tangerineWhistle.js';
import spuriousDragon from './spuriousDragon.js';
import byzantium from './byzantium.js';
import constantinople from './constantinople.js';
import petersburg from './petersburg.js';
import istanbul from './istanbul.js';
import muirGlacier from './muirGlacier.js';
import berlin from './berlin.js';
import london from './london.js';
import shanghai from './shanghai.js';
import arrowGlacier from './arrowGlacier.js';
import grayGlacier from './grayGlacier.js';
import mergeForkIdTransition from './mergeForkIdTransition.js';
import merge from './merge.js';
export const hardforks = {
  chainstart,
  homestead,
  dao,
  tangerineWhistle,
  spuriousDragon,
  byzantium,
  constantinople,
  petersburg,
  istanbul,
  muirGlacier,
  berlin,
  london,
  shanghai,
  arrowGlacier,
  grayGlacier,
  mergeForkIdTransition,
  merge
};
