<div class="app-lend-pool-details-payfi dark">
    <div class="grid-container">
        <div class="grid-item">
            <div class="d-flex justify-content-between">
                <div class="start-section">
                    <img class="me-3 img-wd" [src]="commonData.image_url" />
                    <div class="multi-line-text-start">
                        <div class="heading">Pool Name</div>
                        <div class="sub-heading">
                            {{ commonData.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pool-description">
        {{ commonData.description }}
    </div>

    <div class="row mb-4">
        <app-payfi-widget [lending_pool_id]="commonData.lending_pool_id"></app-payfi-widget>
    </div>

    <div class="row mt-4">
        <cf-tabs [theme]="httpService.theme" [tabs]="tabs" [currentTab]="currentTab"
        (changed)="changeTab($event)"></cf-tabs>

        <div class="tabs">
            <app-my-payfi-drawdowns *ngIf="currentTab === 'my-drawdowns'" [showStats]="false"></app-my-payfi-drawdowns>
            <app-my-payfi-collaterals *ngIf="currentTab === 'my-collaterals'" [showStats]="false"></app-my-payfi-collaterals>
            <app-my-payfi-repayments *ngIf="currentTab === 'my-repayments'" [showStats]="false"></app-my-payfi-repayments>
            <app-my-investments *ngIf="currentTab === 'my-investments'" [showStats]="false"></app-my-investments>
        </div>
    </div>
</div>