<div class="app-new-payfi-repayment" [ngClass]="httpService.theme">
    <div *ngIf="
            !loading &&
            httpService.user.wallet_address !== '' &&
            !show_no_drawdowns
        ">
        <div class="field">
            <cf-dropdown [theme]="httpService.theme" [options]="drawdownsDropdownOptions" (change)="changeDrawdown($event)"
                label="Select drawdown" [showLogo]="true" [selected]="drawdown_id" [showDescription]="true">
            </cf-dropdown>
        </div>

        <div class="field" *ngIf="repayment_type">
            <cf-checkbox-large [options]="repayment_type_options" label="Payment method"
                (changed)="changeRepaymentTyepOption($event)" [selected]="repayment_type"></cf-checkbox-large>
        </div>

        <div class="field" *ngIf="repayment_type === 'fiat'">
            <cf-input-currency [theme]="httpService.theme" [value]="amount"
                [class]="'input'" label="Amount" [currency]="selected_fiat_currency" [currencyLogo]="httpService.getCurrencyUrl(selected_fiat_currency)" type="number" (valueChanged)="amountChanged($event)" [precision]="2">
            </cf-input-currency>
        </div>

        <div class="field" *ngIf="repayment_type === 'wallet'">
            <cf-input-currency-dropdown [theme]="httpService.theme" [options]="walletDropdownOptions" [value]="amount"
                [class]="'input'" label="Amount" [selected]="selected_wallet_currency" type="number"
                (currencyChanged)="walletCurrencyChanged($event)" (valueChanged)="amountChanged($event)" [precision]="2">
            </cf-input-currency-dropdown>
        </div>

        <div class="new-payfi-repayment-summary">
            <div class="summary-item">
                <span class="summary-heading">Total drawdown</span>
                <span class="summary-value">{{
                    drawdown_info.received_amount
                    | number : httpService.getPrecision(selected_fiat_currency)
                    }}
                    {{ selected_fiat_currency | uppercase }}
                </span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Last interest paid</span>
                <span class="summary-value">{{
                    drawdown_info.last_interest_payment_time
                    | date:'medium'
                    }}
                </span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Outstanding interest</span>
                <span class="summary-value">{{
                    amount
                    | number : httpService.getPrecision(selected_fiat_currency)
                    }}
                    {{ selected_fiat_currency | uppercase }}</span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Outstanding principal</span>
                <span class="summary-value">{{
                    drawdown_info.received_amount
                    | number : httpService.getPrecision(selected_fiat_currency)
                    }}
                    {{ selected_fiat_currency | uppercase }}</span>
            </div>
        </div>

        <div class="actions">
            <div class="action">
                <div *ngIf="!btn_loading">
                    <cf-button [label]="confirm_btn_label" type="solid" [disabled]="httpService.user.wallet_address === ''"
                        (clicked)="createRepayment()" [loading]="btn_loading"></cf-button>
                </div>
                <div *ngIf="btn_loading">
                    <cf-loader></cf-loader>
                </div>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div *ngIf="httpService.user.wallet_address === ''">
        <cf-button label="Connect wallet" type="outline" (clicked)="connectWallet()"></cf-button>
    </div>
    <div *ngIf="show_no_drawdowns" class="no_data">
        <div>No drawdowns</div>
    </div>
</div>