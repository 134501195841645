const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

function formatDate(epoch: number): string {
    const date = new Date(epoch);
    const month = date.getMonth();
    const day = date.getDay();

    return `${day} ${months[month]}`;
}

export default formatDate;