import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import {
    Inject, Injectable, PLATFORM_ID 
} from '@angular/core';
import {
    circle_app_id, serverUrl 
} from './app.config';
import { isPlatformBrowser } from '@angular/common';
import { W3SSdk } from '@circle-fin/w3s-pw-web-sdk';
import { LocalStorageService } from '../services/localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class CircleService {
    sdk: W3SSdk | undefined;
    app_id: string = circle_app_id;
    deviceId: string = '';
    isBrowser: boolean = false;

    constructor(
        private http: HttpClient, 
        @Inject(PLATFORM_ID) platformId: object,
        private localStorageService: LocalStorageService
    ) {
        console.log('CircleService', platformId);

        this.isBrowser = isPlatformBrowser(platformId);

        console.log('isBrowser', this.isBrowser);

        if (this.isBrowser)
            this.initializeSdk();

        else
            console.log('CircleService not browser');
    }

    initializeSdk() {
        console.log('Circle initializeSdk');

        this.sdk = new W3SSdk({
            appSettings: {
                appId: this.app_id
            }
        });

        console.log('sdk', this.sdk);

        this.getDeviceId();
    }
    
    login(email: string) {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        const body = {
            email,
            deviceId: this.deviceId
        };

        return this.http.post<any>(`${serverUrl}circle/login`, body, httpOptions);
    }

    verifyOtp(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.post<any>(`${serverUrl}circle/verifyOtp`, body, httpOptions);
    }

    getDeposits(body: any) {
        const queryParams = new URLSearchParams(body).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}circle/getDeposits?${queryParams}`, httpOptions);
    }

    getWithdrawals(body: any) {
        const queryParams = new URLSearchParams(body).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}circle/getWithdrawals?${queryParams}`, httpOptions);
    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    async getDeviceId() {
        if (!this.sdk) {
            console.log('no circle sdk');

            return;
        }

        this.deviceId = await this.sdk.getDeviceId();

        console.log('deviceId', this.deviceId);
    }

    executeChallenge(challengeId: string) {
        if (!this.sdk) {
            console.log('no circle sdk');

            return;
        }

        this.sdk.execute(challengeId, (err, result) => {
            console.log('executeChallenge onComplete');
            console.log('err', err);
            console.log('result', result);
        });
    }

    getWithdrawalFee(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}circle/getWithdrawalFee`, body, httpOptions);
    }

    newWithdrawalRequest(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}circle/newWithdrawalRequest`, body, httpOptions);
    }

    getCircleCurrencies(blockchain: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}circle/getCircleCurrencies?blockchain=${blockchain}`, httpOptions);
    }
}
