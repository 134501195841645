import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './localstorage.service';
import { serverUrl } from '../app/app.config';

@Injectable({
    providedIn: 'root'
})
export class TelegramService {
    constructor(private http: HttpClient, private localStorageService: LocalStorageService) {

    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    joinTelegramGroup(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}joinTelegramGroup`, body, httpOptions);
    }

    saveTelegramUser(user: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}saveTelegramUser`, user, httpOptions);
    }

    checkTelegramGroupJoined() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}checkTelegramGroupJoined`, {}, httpOptions);
    }
}
