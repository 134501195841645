// console.log('app.module.ts start');

import {
    LOCALE_ID, NgModule 
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import {
    FormsModule, ReactiveFormsModule 
} from '@angular/forms';
import {
    MatDialogModule, MatDialogRef 
} from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {
    DatePipe, DecimalPipe 
} from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NewInvestmentComponent } from './new-investment/new-investment.component';
import { AirdropComponent } from './airdrop/airdrop.component';
import { ClaimLoyaltyComponent } from './claim-loyalty/claim-loyalty.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NewWithdrawalComponent } from './new-withdrawal/new-withdrawal.component';
import { BorrowPoolDetailsComponent } from './borrow-pool-details/borrow-pool-details.component';
import { NewRepaymentComponent } from './new-repayment/new-repayment.component';
import { SocialCallbackComponent } from './social-callback/social-callback.component';
import { LendComponent } from './lend/lend.component';
import { LendWidgetComponent } from './lend-widget/lend-widget.component';
import ButtonMenuComponent from './button-menu/button-menu.component';
import { PoolActivityComponent } from './pool-activity/pool-activity.component';
import { LendPoolTransactionsComponent } from './lend-pool-transactions/lend-pool-transactions.component';
import { LendPoolWithdrawalsComponent } from './lend-pool-withdrawals/lend-pool-withdrawals.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BorrowWidgetComponent } from './borrow-widget/borrow-widget.component';
import { BorrowPoolLoansComponent } from './borrow-pool-loans/borrow-pool-loans.component';
import { BorrowPoolRepaymentsComponent } from './borrow-pool-repayments/borrow-pool-repayments.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { HomepageComponent } from './homepage/homepage.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { MyRepaymentsComponent } from './transactions/my-repayments/my-repayments.component';
import { MyWithdrawalsComponent } from './transactions/my-withdrawals/my-withdrawals.component';
import { NewDrawdownComponent } from './new-drawdown/new-drawdown.component';
import { MyEarningsComponent } from './transactions/my-earnings/my-earnings.component';
import { MyInvestmentsComponent } from './transactions/my-investments/my-investments.component';
import { BorrowerDashboardComponent } from './borrower-dashboard/borrower-dashboard.component';
import { NewEarningComponent } from './new-earning/new-earning.component';
import { MyDrawdownsComponent } from './transactions/my-drawdowns/my-drawdowns.component';
import { RegisterStepsComponent } from './register-steps/register-steps.component';
import { ConnectSocialsComponent } from './register-steps/connect-socials/connect-socials.component';
import { UserProfileComponent } from './register-steps/user-profile/user-profile.component';
import { VaultAllocationComponent } from './vault-allocation/vault-allocation.component';
import { ConnectWalletComponent } from './register-steps/connect-wallet/connect-wallet.component';
import { InviteCodeComponent } from './register-steps/invite-code/invite-code.component';
import { InvitationComponent } from './invitation/invitation.component';
import { ChooseProfilePictureComponent } from './register-steps/choose-profile-picture/choose-profile-picture.component';
import { CfOtpComponent } from './cfOtp/cf-otp.component';
import { LendDefaultComponent } from './lend/lend-default/lend-default.component';
import { LendPrivateEquityComponent } from './lend/lend-private-equity/lend-private-equity.component';
import { LendPoolDetailsComponent } from './lend-pool-details/lend-pool-details.component';
import { LendPoolDetailsDefaultComponent } from './lend-pool-details/lend-pool-details-default/lend-pool-details-default.component';
import { LendPoolDetailsVaultComponent } from './lend-pool-details/lend-pool-details-vault/lend-pool-details-vault.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DisclosureComponent } from './register-steps/disclosure/disclosure.component';
import { BorrowPoolDetailsDefaultComponent } from './borrow-pool-details/borrow-pool-details-default/borrow-pool-details-default.component';
import { BorrowPoolDetailsVaultComponent } from './borrow-pool-details/borrow-pool-details-vault/borrow-pool-details-vault.component';
import { LoyaltyComponent } from './loyalty/loyalty.component';
import { SpinWheelComponent } from './airdrop/spin-wheel/spin-wheel.component';
import { MarqueeCarouselComponent } from './marquee-carousel/marquee-carousel.component';
import { AmbassadorOnboardingComponent } from './ambassador/ambassador-onboarding/ambassador-onboarding.component';
import { AmbassadorTvlComponent } from './ambassador/ambassador-tvl/ambassador-tvl.component';
import { AmbassadorComponent } from './ambassador/ambassador.component';
import { AmbassadorCredComponent } from './ambassador/ambassador-cred/ambassador-cred.component';
import { MembersComponent } from './ambassador/members/members.component';
import { AmbassadorLeaderboardComponent } from './ambassador/ambassador-leaderboard/ambassador-leaderboard.component';
import { LoginComponent } from './login/login.component';
import { QRCodeModule } from 'angularx-qrcode';

import circleComponents from './circle.module';
import cfComponents from './cf.module';
import { HeaderPopupMenuComponent } from './header-popup-menu/header-popup-menu.component';
import { ChangeBlockchainDialogComponent } from './change-blockchain-dialog/change-blockchain-dialog.component';
import { ChangeChainDialogComponent } from './change-chain-dialog/change-chain-dialog.component';
import { LendPoolDetailsOverdraftComponent } from './lend-pool-details/lend-pool-details-payfi/lend-pool-details-payfi.component';
import { OverdraftWidgetComponent } from './payfi-widget/payfi-widget.component';
import { NewPayFiDrawdownComponent } from './new-payfi-drawdown/new-payfi-drawdown.component';
import { NewPayFiRepaymentComponent } from './new-payfi-repayment/new-payfi-repayment.component';
import { HomepageNewComponent } from './homepage-new/homepage-new.component';
import { ProfileComponent } from './profile/profile.component';
import { BankAccountsComponent } from './bank-accounts/bank-accounts.component';
import { AddBankComponent } from './add-bank/add-bank.component';
import { MyPayFiDrawdownsComponent } from './transactions/my-payfi-drawdowns/my-payfi-drawdowns.component';
import { OverdraftComponent } from './payfi/payfi.component';
import { PersonalProfileComponent } from './personal-profile/personal-profile.component';
import { MyPayFiRepaymentsComponent } from './transactions/my-payfi-repayments/my-payfi-repayments.component';
import { NewPayFiComponent } from './new-payfi/new-payfi.component';
import { MyBorrowingsPayFiComponent } from './my-borrowings-payfi/my-borrowings-payfi.component';
import { MyInvestmentsPayFiComponent } from './my-investments-payfi/my-investments-payfi.component';
import { PoolActivityPayFiComponent } from './pool-activity-payfi/pool-activity-payfi.component';
import { FaqPayFiComponent } from './faq-payfi/faq-payfi.component';
import { SpaceshipComponent } from './spaceship/spaceship.component';
import { NewPayFiCollateralComponent } from './new-payfi-collateral/new-payfi-collateral.component';
import { MyPayFiCollateralsComponent } from './transactions/my-payfi-collaterals/my-payfi-collaterals.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatDialogModule,
        MatRadioModule,
        MatStepperModule,
        AppRoutingModule,
        MatExpansionModule,
        QRCodeModule,
        ...cfComponents
    ],
    declarations: [
        AppComponent,
        CfOtpComponent,
        UserProfileComponent,
        FooterComponent,
        SidebarComponent,
        HeaderComponent,
        ConnectWalletComponent,
        DashboardComponent,
        NewInvestmentComponent,
        NewEarningComponent,
        NewWithdrawalComponent,
        AirdropComponent,
        ClaimLoyaltyComponent,
        MyInvestmentsComponent,
        MyRepaymentsComponent,
        BorrowPoolDetailsComponent,
        NewDrawdownComponent,
        MyEarningsComponent,
        MyWithdrawalsComponent,
        MyDrawdownsComponent,
        MyPayFiDrawdownsComponent,
        MyPayFiRepaymentsComponent,
        MyPayFiCollateralsComponent,
        NewRepaymentComponent,
        SocialCallbackComponent,
        LendComponent,
        LendPoolDetailsComponent,
        LendWidgetComponent,
        BorrowWidgetComponent,
        ButtonMenuComponent,
        PoolActivityComponent,
        VaultAllocationComponent,
        LendPoolTransactionsComponent,
        LendPoolWithdrawalsComponent,
        NotFoundComponent,
        HomepageComponent,
        BorrowPoolLoansComponent,
        BorrowPoolRepaymentsComponent,
        LineChartComponent,
        TransactionsComponent,
        BorrowerDashboardComponent,
        RegisterStepsComponent,
        ConnectSocialsComponent,
        InviteCodeComponent,
        MembersComponent,
        ChooseProfilePictureComponent,
        InvitationComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        HeaderPopupMenuComponent,
        LendDefaultComponent,
        LendPrivateEquityComponent,
        LendPoolDetailsDefaultComponent,
        LendPoolDetailsOverdraftComponent,
        LendPoolDetailsVaultComponent,
        VaultAllocationComponent,
        DisclosureComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        BorrowPoolDetailsDefaultComponent,
        BorrowPoolDetailsVaultComponent,
        LoyaltyComponent,
        SpinWheelComponent,
        MarqueeCarouselComponent,
        LoginComponent,
        AmbassadorComponent,
        AmbassadorOnboardingComponent,
        AmbassadorTvlComponent,
        AmbassadorLeaderboardComponent,
        AmbassadorCredComponent,
        ChangeBlockchainDialogComponent,
        ChangeChainDialogComponent,
        OverdraftWidgetComponent,
        NewPayFiDrawdownComponent,
        NewPayFiRepaymentComponent,
        NewPayFiCollateralComponent,
        HomepageNewComponent,
        ProfileComponent,
        BankAccountsComponent,
        AddBankComponent,
        OverdraftComponent,
        PersonalProfileComponent,
        NewPayFiComponent,
        MyBorrowingsPayFiComponent,
        MyInvestmentsPayFiComponent,
        PoolActivityPayFiComponent,
        FaqPayFiComponent,
        ...circleComponents,
        SpaceshipComponent,
        ...circleComponents
    ],
    exports: [ ButtonMenuComponent ],
    providers: [
        DecimalPipe,
        DatePipe,
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-IN'
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {
                displayDefaultIndicatorType: false
            }
        },
        // provideClientHydration(),
        provideHttpClient()
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {}
