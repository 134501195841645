import {
    Component, OnInit, Output, EventEmitter
} from '@angular/core';
import {CfAlertService} from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpService } from '../../../services/http.service';
import { nullChecker } from '../../../helpers/nullChecker';
@Component({
    selector: 'app-invite-code',
    templateUrl: './invite-code.component.html',
    styleUrl: './invite-code.component.scss'
})
export class InviteCodeComponent implements OnInit {
    window: Window;
    
    loading: boolean = false;
    @Output() completed = new EventEmitter<any>();
    currentUser:any;
    invite_code: string = '';
    constructor(
        private router: Router,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
         private activatedRoute: ActivatedRoute
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.currentUser = this.httpService.user;
        console.log('this.currentUser ', this.currentUser );

        if (this.currentUser && this.currentUser?.referred_by) 
            this.router.navigateByUrl('/dashboard');
        
        this.activatedRoute.queryParams.subscribe(params => {
            this.invite_code = params['invite_code'];
            console.log('invite_code', this.invite_code);
        });
    }

    onChangeCode(event: any) {
        console.log('event', event);

        this.invite_code = event;
        console.log('invite_code', this.invite_code);
    }

    submit() {
        this.loading = true;
        console.log('this.invite_code ', this.invite_code);

        if (nullChecker(this.invite_code)) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Invite code cannot be empty'
                }
            }));
            this.loading = false;

            return;
        }

        if (this.invite_code.length < 6) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Invite code must be at least 6 characters'
                }
            }));
            this.loading = false;

            return;
        }

        const body = {
            invite_code: this.invite_code
        };

        this.httpService.updateReferral(body).subscribe((res: any) => {
            this.loading = false;
            this.httpService.user.referred_by = res.data.referred_by;

            this.cfAlertService.showMessage(res.message);
            this.completed.emit();
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.loading = false;
        });
    }
}