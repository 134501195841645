const serverUrl = 'https://api.credible.finance/';
const socketUrl = 'https://sockets.credible.finance/';
const webUrl = 'https://credible.finance';
const RECAPTCHA_SITE_KEY = '6LcUH1EpAAAAAHOzn2bNsQu-gVu6e8a4HnmdEa2J';
const paynormaKycBaseUrl = 'https://kyc.paynorma.com';
const currencyConversionMapUrl = 'https://conversion.seracle.com/getCurrencyConversionMap';

const cluster = 'mainnet-beta';
const networks: any = {
    devnet: {
        name: 'Devnet',
        rpc: 'https://devnet.helius-rpc.com/?api-key=ced154f2-8235-4e5f-a14f-a47754cfee51'
    },
    'mainnet-beta': {
        name: 'Mainnet Beta',
        rpc: 'https://rpc.ironforge.network/mainnet?apiKey=01J1F4HJ98JTZA4R10Y0ZBGBSX'
    },
    plumedevnet: {
        name: 'Plume Devnet',
        rpc: 'https://test-rpc.plumenetwork.xyz'
    }
};
const merchant_redirect_url = 'https://credible.finance/liquidity/';
const apiKey = 'd028fb5a-d8dd-4138-b5e9-28fe4ba5dc63';
const secretKey = 'b91f7fa5-64e8-4578-b7b5-33aaec181563';
const showConsoleLogs = false;
const circle_app_id = '1f04c163-b135-594d-93e8-158530082e04';

export {
    circle_app_id,    
    showConsoleLogs,
    serverUrl,
    socketUrl,
    RECAPTCHA_SITE_KEY,
    paynormaKycBaseUrl,
    currencyConversionMapUrl,
    webUrl,
    cluster,
    networks,
    merchant_redirect_url,
    apiKey,
    secretKey
};
