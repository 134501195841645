<div class="app-circle-withdrawals dark">
    <div class="app-circle-withdrawals-container container">
        <div class="row">
            <div class="col-md-12">
                <app-circle-new-withdrawal></app-circle-new-withdrawal>
            </div>
        </div>

        <div *ngIf="!loading && httpService.user.wallet_address !== ''" class="circle-withdrawals">
            <div class="row mb-1">
                <div class="col-md-9"></div>
                <div class="col-md-3">
                    <cf-dropdown [options]="statusOptions" (change)="changeStatus($event)" label="Select Status"
                        [showLogo]="false" [selected]="selected_status" [showDescription]="true">
                    </cf-dropdown>
                </div>
            </div>
            <mat-expansion-panel (afterExpand)="afterExpand(item.blockchain_txid)"
                [expanded]="opened_panel === item.blockchain_txid"
                [ngClass]="getStatusClasses(item.blockchain_txid, item.status)" *ngFor="
                    let item of withdrawals;
                    let index = index;
                    first as isFirst
                ">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span>
                            {{ item.created | date : "mediumDate" }}
                        </span>
                        <span>
                            {{
                            item.amount
                            | number
                            : httpService.getPrecision(
                            item.currency
                            )
                            }}
                            {{ item.currency | uppercase }}
                            <span class="equivalent-amount">
                                ~
                                {{
                                item.amount_usd
                                | number
                                : httpService.getPrecision("usd")
                                }}
                                USD
                            </span>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table>
                    <tbody>
                        <tr>
                            <td class="txn-heading">Date</td>
                            <td>
                                {{ item.created | date : "medium" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Amount</td>
                            <td>
                                {{
                                item.amount
                                | number
                                : httpService.getPrecision(
                                item.currency
                                )
                                }}
                                {{ item.currency | uppercase }}
                                <span class="equivalent-amount">
                                    ~
                                    {{
                                    item.amount_usd
                                    | number
                                    : httpService.getPrecision(
                                    "usd"
                                    )
                                    }}
                                    USD
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Source Address</td>
                            <td>
                                {{ item.source_address }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Destination Address</td>
                            <td>
                                {{ item.destination_address }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Blockchain Txid</td>
                            <td>
                                <div class="redirection" *ngIf="item.blockchain_txid">
                                    {{
                                    httpService.sliceAddress(
                                    item.blockchain_txid,
                                    20
                                    )
                                    }}
                                    <i class="fa-solid fa-up-right-from-square ml cursor-hover" (click)="
                                            redirectTx(item.blockchain, item.blockchain_txid)
                                        "></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Status</td>
                            <td>
                                <i class="fa-regular fa-circle-check" *ngIf="item.status === 'complete'"></i>
                                <i class="fa-regular fa-circle-xmark" *ngIf="item.status === 'failed'"></i>
                                {{ item.status | titlecase }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
                (changed)="pageChanged($event)"></cf-paginator>
        </div>
        <div class="no-data" *ngIf="
                total_count === 0 &&
                !loading &&
                httpService.user.wallet_address !== ''
            ">
            No records found
        </div>
        <div class="no-data" *ngIf="
                total_count === 0 &&
                !loading &&
                httpService.user.wallet_address === ''
            ">
            <cf-button label="Connect Wallet" type="outline" (clicked)="connectWallet()"></cf-button>
        </div>
        <div class="loader" *ngIf="loading">
            <cf-loader></cf-loader>
        </div>
    </div>
</div>