export default {
  name: 'EIP-3607',
  number: 3607,
  comment: 'Reject transactions from senders with deployed code',
  url: 'https://eips.ethereum.org/EIPS/eip-3607',
  status: 'Final',
  minimumHardfork: 'chainstart',
  requiredEIPs: [],
  gasConfig: {},
  gasPrices: {},
  vm: {},
  pow: {}
};
