import {
    DecimalPipe, isPlatformBrowser 
} from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, Input, OnInit, PLATFORM_ID 
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import {
    Transaction, Connection 
} from '@solana/web3.js';
import { nullChecker } from '../../helpers/nullChecker';
import IPrivateCreditPool from '../../interfaces/IPrivateCreditPool';
import PrivateCreditPool from '../../models/PrivateCreditPool';
import { HttpService } from '../../services/http.service';
import { PhantomService } from '../../services/phantom.service';
import { WalletService } from '../../services/wallet.service';
import { MetamaskService } from '../metamask.service';
import { Router } from '@angular/router';
import { PayFiService } from '../../services/payfi.service';
import IPayFiDrawdown from '../../interfaces/IPayFiDrawdown';
import PayFiDrawdown from '../../models/PayFiDrawdown';
import ICheckbox from '@crediblefinance/credible-ui/lib/cf-checkbox-large/ICheckbox';
import IPayFiPool from '../../interfaces/IPayFiPool';
import PayFiPool from '../../models/PayFiPool';

@Component({
    selector: 'app-new-payfi-repayment',
    templateUrl: './new-payfi-repayment.component.html',
    styleUrl: './new-payfi-repayment.component.scss'
})
export class NewPayFiRepaymentComponent implements OnInit {
    selected_fiat_currency: string = '';
    
    drawdownsDropdownOptions: Array<IDropdown> = [];
    walletDropdownOptions: Array<IDropdown> = [];
    selected_wallet_currency: string = '';

    pools: Array<IPayFiPool> = [];
    selected_pool: IPayFiPool = new PayFiPool();

    amount: number = 0;

    platformId: object = {};
    isBrowser: boolean = false;

    drawdowns: Array<IPayFiDrawdown> = [];

    repayment_id: string = '';

    loading: boolean = false;
    btn_loading: boolean = false;

    drawdown_info: IPayFiDrawdown = new PayFiDrawdown();
    show_no_drawdowns: boolean = false;

    pool_id: string = '';
    balance: number = 0;
    gas_fees_balance: number = 0;
    @Input() drawdown_id: string = '';
    pool: IPrivateCreditPool = new PrivateCreditPool();

    repayment_type_options: Array<ICheckbox> = [{
        label: 'Fiat transfer',
        value: 'fiat',
        optionEnabled: true
    }, {
        label: 'Wallet transfer',
        value: 'wallet',
        optionEnabled: true
    }];
    repayment_type: string = this.repayment_type_options[0].value;
    fiat_conversion_rates: {
        [key: string]: number
    } = {};
    confirm_btn_label: string = 'Repay';

    constructor(
        private metamaskService: MetamaskService,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private walletService: WalletService,
        private payFiService: PayFiService,
        private decimalPipe: DecimalPipe,
        private router: Router,
        @Inject(PLATFORM_ID) platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        this.loading = true;

        this.getPools();
        this.getDrawdowns();
    }

    getDrawdowns() {
        this.show_no_drawdowns = false;

        const body = {
            page: 0,
            limit: 1000,
            token_required: true,
            usage: 'create_repayment'
        };

        this.payFiService.getDrawdowns(body).subscribe(res => {
            this.drawdowns = res.data;

            if (this.drawdowns.length > 0) {
                this.drawdowns.forEach((element: any) => {
                    const received_amount = this.decimalPipe.transform(element.received_amount, '0.0-2');
                    const receive_currency = element.received_currency.toUpperCase();
                    const date = new Date(element.created).toDateString();

                    this.drawdownsDropdownOptions.push({
                        label: `${received_amount} ${receive_currency} (${date})`,
                        value: element.drawdown_id,
                        logo: this.httpService.getCurrencyUrl(element.received_currency),
                        optionEnabled: true
                    });
                });

                if (nullChecker(this.drawdown_id)) {
                    this.drawdown_id = res.data[0].drawdown_id;
                    this.getGasFeeBalance(res.data[0].blockchain, res.data[0].chain);

                    this.drawdown_info = res.data.find((item: any) => {
                        return item.drawdown_id === this.drawdown_id;
                    });
                }

                else 
                    this.drawdown_info = res.data[0];
                
                this.selected_fiat_currency = this.drawdown_info.received_currency;
    
                this.pool_id = this.drawdown_info.pool_id;
            }

            else
                this.show_no_drawdowns = true;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    amountChanged(amount: number) {
        if (!amount) 
            amount = 0;
        
        this.amount = parseFloat(amount.toString());

        this.setConfirmBtnLabel();
    }

    changeDrawdown(event: any) {
        this.drawdown_id = event.value;

        const drawdown_info = this.drawdowns.find((item: any) => {
            return item.drawdown_id === this.drawdown_id;
        });

        if (drawdown_info) 
            this.drawdown_info = drawdown_info;

        this.calculateAmount();
    }

    calculateAmount() {
        this.selected_fiat_currency = this.drawdown_info.received_currency;

        let pending_interest = this.calculatePendingInterest(this.drawdown_info.last_interest_payment_time, this.drawdown_info.received_amount, this.drawdown_info.yield_rate);

        console.log('pending_interest', pending_interest);

        if (pending_interest < Math.pow(10, -2))
            pending_interest = 0;

        if (this.repayment_type === 'fiat') 
            this.amountChanged(pending_interest);

        else if (this.repayment_type === 'wallet') {
            const usd_rate = this.fiat_conversion_rates[this.selected_fiat_currency];
            const usd_amount = pending_interest / usd_rate;
            const crypto_amount = usd_amount / this.httpService.currencyConversionMap[this.selected_wallet_currency].usd;

            this.amountChanged(crypto_amount);
        }
    }

    walletCurrencyChanged(event: IDropdown) {
        this.selected_wallet_currency = event.value;

        this.getCurrencyBalance(this.pool.blockchain, this.pool.chain, this.httpService.user.wallet_address, this.pool.lending_currency_mint_address);
    }

    calculatePendingInterest(interest_claimed_time: number, outstanding_principal_amount: number, interest_rate: number) {
        const current_time = new Date().getTime();
        const difference_in_minutes = (current_time - interest_claimed_time) / 60000;
        
        const interest_per_min = outstanding_principal_amount * interest_rate / 100 / 365 / 24 / 60;
        
        const interest = interest_per_min * difference_in_minutes;
    
        return interest;
    }

    createRepayment() {
        if (isNaN(this.amount) || this.amount === null || this.amount === undefined || this.amount === 0) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Please enter valid amount'
                }
            }));

            return;
        }

        const body: any = {
            drawdown_id: this.drawdown_id,
            amount: this.amount,
            method: this.repayment_type
        };

        if (this.repayment_type === 'wallet')
            body.currency = this.selected_wallet_currency;

        else if (this.repayment_type === 'fiat')
            body.currency = this.selected_fiat_currency;

        this.btn_loading = true;

        this.httpService.createRepayment(body).subscribe((res: any) => {    
            this.btn_loading = false;
            this.repayment_id = res.data.repayment_id;

            if (res.data.transaction) {
                const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));
                
                this.sendSolanaTransaction(transaction);
            }

            else if (res.data.abi) {
                const abi = res.data.abi;
                const gas = res.data.gas;
                const contract_address = res.data.contract_address;

                console.log('lending', abi, contract_address, gas);
                
                this.sendEthereumTransaction(abi, contract_address, gas);
            }
        }, (err: HttpErrorResponse) => {
            console.error('Withdrawal error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.btn_loading = false;
        });
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    updateSignature(signature: string, error_code: number = -1) {
        const body = {
            drawdown_id: this.drawdown_id,
            repayment_id: this.repayment_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.httpService.updateRepayment(body).subscribe((res: any) => {
            this.btn_loading = false;

            this.cfAlertService.showMessage('Repayment succesful');

            this.router.navigateByUrl('/transactions?tab=my-repayments');
        }, (err: HttpErrorResponse) => {
            console.error('updateRepayment error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.btn_loading = false;
        });
    }

    async sendSolanaTransaction(transaction: Transaction) {
        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature)
                return;
            
            transaction = res;

            const connection = new Connection(this.pool.rpc, 'confirmed');

            console.log('transaction', transaction);

            const txnSignature = await connection.sendRawTransaction(transaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            }));

            console.log('txn signature', txnSignature);

            this.updateSignature(txnSignature);
        }

        catch (err) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            this.cfAlertService.showError(new HttpErrorResponse({
                error: err
            }));

            this.btn_loading = false;
        }
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    approveAllowance() {
        if (isNaN(this.amount) || this.amount === null || this.amount === undefined || this.amount === 0) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (this.balance < this.amount) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        if (this.gas_fees_balance <= 0) {
            this.cfAlertService.showMessage('Insufficient SOL balance', true);

            return;
        }

        this.loading = true;

        const body: any = {
            amount: this.amount,
            method: this.repayment_type,
            pool_id: this.pool_id,
            vault_id: ''
        };

        if (this.repayment_type === 'wallet')
            body.currency = this.selected_wallet_currency;

        else if (this.repayment_type === 'fiat')
            body.currency = this.selected_fiat_currency;

        this.httpService.approveAllowance(body).subscribe((res: any) => {
            this.drawdown_id = res.data.drawdown_id;
            
            const abi = res.data.abi;
            const contract_address = res.data.contract_address;
            const gas = res.data.gas;

            console.log('allowance_abi', abi);
            console.log('allowance_contract_address', contract_address);
            console.log('allowance_gas', gas);
                
            this.sendEthereumAllowanceTransaction(abi, contract_address, gas);
        }, (err: HttpErrorResponse) => {
            console.error('approveAllowance error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.loading = false;
        });
    }

    async getEthereumSignature(abi: string, contract_address: string, gas: number) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(abi, contract_address, this.httpService.user.wallet_address, gas);

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    async sendEthereumAllowanceTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;
        let allowance_signature = '';

        try {
            allowance_signature = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('allowance_signature', allowance_signature);

            this.cfAlertService.showMessage('Allowance succesful');

            this.createRepayment();
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }
    }

    async sendEthereumTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('sendEthereumTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    getGasFeeBalance(blockchain: string, chain: string) {
        const wallet_address = this.httpService.user.wallet_address;

        this.httpService.getCurrencyBalance(blockchain, chain, wallet_address).subscribe((res: any) => {
            this.gas_fees_balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getCurrencyBalance(blockchain: string, chain: string, wallet_address: string, mint_address: string) {
        if (!this.isBrowser) {
            this.balance = 0;

            return; 
        }

        this.httpService.getCurrencyBalance(blockchain, chain, wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    changeRepaymentTyepOption(option: ICheckbox) {
        this.repayment_type = option.value;

        this.calculateAmount();
    }

    getCollateralCurrencies() {
        this.payFiService.getCollateralCurrencies().subscribe((res: any) => {
            const currencies = res.data;

            const options: Array<IDropdown> = [];

            for (let i = 0; i < currencies.length; i++) {
                options.push({
                    label: currencies[i].label,
                    value: currencies[i].key,
                    logo: this.httpService.getCurrencyUrl(currencies[i].key),
                    optionEnabled: true
                });
            }

            this.walletDropdownOptions = options;
            this.selected_wallet_currency = this.walletDropdownOptions[0].value;

            for (let i = 0; i < this.pools.length; i++) {
                if (this.pools[i].drawdown_currency === this.selected_wallet_currency) {
                    this.selected_pool = this.pools[i];

                    break;
                }
            }

            this.getCurrencyBalance(this.selected_pool.blockchain, this.selected_pool.chain, this.httpService.user.wallet_address, this.selected_pool.drawdown_currency_mint_address);

            this.getFiatRates();
        }, (err: HttpErrorResponse) => {
            console.error('getUserBankAccounts error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getPools() {
        this.payFiService.getPools().subscribe(res => {
            this.pools = res.data;

            this.getCollateralCurrencies();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    getFiatRates() {
        this.payFiService.getFiatRates().subscribe((res: any) => {
            this.fiat_conversion_rates = res.data;

            this.calculateAmount();
        }, (err: HttpErrorResponse) => {
            this.loading = false;

            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    setConfirmBtnLabel() {
        if (this.repayment_type === 'fiat')
            this.confirm_btn_label = 'Repay ' + this.decimalPipe.transform(this.amount, this.httpService.getPrecision(this.selected_fiat_currency)) + ' ' + this.selected_fiat_currency.toUpperCase();

        else if (this.repayment_type === 'wallet')
            this.confirm_btn_label = 'Repay ' + this.decimalPipe.transform(this.amount, this.httpService.getPrecision(this.selected_wallet_currency)) + ' ' + this.selected_wallet_currency.toUpperCase();
    }
}
