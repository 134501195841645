import {
    Component, OnInit 
} from '@angular/core';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { AmbassadorService } from '../../ambassador.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';

@Component({
    selector: 'app-ambassador-leaderboard',
    templateUrl: './ambassador-leaderboard.component.html',
    styleUrl: './ambassador-leaderboard.component.scss'
})
export class AmbassadorLeaderboardComponent implements OnInit{
    levelOptions: Array<IDropdown> = [];
    current_level = '1';
    topPerformers: Array<any> = [];
    loading_tvl_stats = true;

    topCredUsers: Array<any> = [];
    loading_cred_stats = true;

    constructor(
        private ambassadorService: AmbassadorService,
        private cfAlertService: CfAlertService
    ) {}

    ngOnInit(): void {
        this.getReferralLevels();
        this.getTopTvlUsers();
    }

    getReferralLevels() {
        this.ambassadorService.getReferralLevels().subscribe((res) => {
            const levels = res.data;

            const levelOptions: any[] = [];

            levels.forEach((level: any) => {
                levelOptions.push({
                    value: level.toString(),
                    label: 'Level ' + level,
                    optionEnabled: true
                });
            });

            this.levelOptions = levelOptions;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    levelChange(option: IDropdown) {
        console.log('levelChange', option);
    
        this.current_level = option.value;

        this.getTopTvlUsers();
    }

    getTopTvlUsers() {
        this.loading_tvl_stats = true;

        this.ambassadorService.getTopTvlUsers(this.current_level).subscribe((res) => {
            this.topPerformers = res.data;
            this.loading_tvl_stats = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading_tvl_stats = false;

            this.cfAlertService.showError(err);
        });
    }
}
