import {
    Component, OnInit 
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { getWindow } from 'ssr-window';
import { KycService } from '../../services/kyc.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-personal-profile',
    templateUrl: './personal-profile.component.html',
    styleUrl: './personal-profile.component.scss'
})
export class PersonalProfileComponent implements OnInit {
    email: string = '';
    loading: boolean = false;
    window = getWindow();
    nationalityOptions: Array<IDropdown> = [];
    residenceOptions: Array<IDropdown> = [];
    invitation_link: string = '';

    constructor(
        public httpService: HttpService, 
        private cfAlertService: CfAlertService,
    private kycService: KycService) {}
   
    ngOnInit(): void {
        this.nationalityOptions.push({
            logo: this.httpService.getCountryFlag(this.httpService.user.nationality_country_code),
            label: this.httpService.user.nationality_country,
            value: this.httpService.user.nationality_country,
            optionEnabled: true
        });
        this.residenceOptions.push({
            logo: this.httpService.getCountryFlag(this.httpService.user.residence_country_code),
            label: this.httpService.user.residence_country,
            value: this.httpService.user.residence_country,
            optionEnabled: true
        });

        this.invitation_link = `${this.window.location.origin}/invite/${this.httpService.user.referral_code}`;
        
        this.attachSocketEvents();
    }

    attachSocketEvents() {
        this.httpService.socket.on('user_updated_data', (data: any) => {
            if (data) 
                this.httpService.user.kyc_status = data.kyc_status;
        });
    }
    
    generateKycUrl() {
        this.loading = true;

        this.kycService.generateKycUrl(this.httpService.user).subscribe((res: any) => {
            this.loading = false;

            this.window.location.href = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    emailChanged(email: string) {
        this.email = email;
    }
}
