import IPayFiPool from '../interfaces/IPayFiPool';
import PoolStats from './PoolStats';

export default class PayFiPool implements IPayFiPool {
    receiving_currencies = [];
    category_id: string = '';
    category_name: string = '';
    country: string = '';
    created: number = 0;
    description: string = '';
    enabled: boolean = true;
    drawdown_yield_rates: Array<{
        days: number,
        apy: number,
        currency_percentage: number,
        cred_percentage: number
    }> = [];
    drawdown_currency: string = '';
    min_interest_rate: number = 0;
    max_interest_rate: number = 0;
    name: string = '';
    pool_id: string = '';
    pool_size_usd: number = 0;
    status: string = '';
    tenor: number = 0;
    type: string = '';
    updated: number = 0;
    image_url: string = '';
    pool_type: string = '';
    drawdown_yield_rate: number = 0;
    drawdown_fee_percentage: number = 0;
    min_borrow_amount: number = 0;
    approved_ltv_percentage: number = 0;
    min_drawdown_amount_per_tx: number = 0;
    max_drawdown_amount_per_wallet: number = 0;
    collateral_type: string = '';
    kyc_required: boolean = false;
    pool_asset: string = '';
    pool_network: string = '';
    pool_network_img_url: string = '';
    capped_pool: Boolean = false;
    pool_size_cap_usd: number = 0;
    program_id: string = '';
    output_currency_mint_address: string = '';
    drawdown_currency_mint_address: string = '';
    website: string = '';
    stats = new PoolStats();
    blockchain = '';
    chain = '';
    chain_id = 0;
    network = '';
    rpc = '';
    start_date = 0;
    coming_soon = false;
    default_collateral_amount = 0;
    repayment_frequency_days = 7;
}