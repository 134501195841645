import {
    Component, NgZone, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from '../../services/localstorage.service';
import { OnboardingService } from '../onboarding.service';

@Component({
    selector: 'app-register-steps',
    templateUrl: './register-steps.component.html',
    styleUrl: './register-steps.component.scss'
})
export class RegisterStepsComponent implements OnInit {
    steps = [{
        step: 'connect-wallet',
        title: 'Wallet',
        completed: false
    },
    {
        step: 'user-profile',
        title: 'Profile',
        completed: false
    },
    // {
    //     step: 'connect-socials',
    //     title: 'Socials',
    //     completed: false
    // },
    {
        step: 'invite-code',
        title: 'Invitation',
        completed: false
    },
    {
        step: 'disclosure',
        title: 'Disclosure',
        completed: false
    }];

    connectedWalletAddres: string = '';
    provider: string = '';

    currentStep = -1;
    currentCollapseStatus: boolean = false;
    step_skipping_allowed: boolean = false;
    returnUrl: string = '';

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private route: ActivatedRoute,
        private onboardingService: OnboardingService,
        private router: Router,
        private location: LocationStrategy,
        private localStorageService: LocalStorageService,
        private _ngZone: NgZone
    ) {
    }

    ngOnInit(): void {
        if (this.httpService.user.wallet_address !== '') {
            this.connectedWalletAddres = this.httpService.user.wallet_address;
            this.provider = this.httpService.user.provider;

            this.steps[0].completed = true;
        }

        if (this.httpService.user.email !== '') {
            this.connectedWalletAddres = this.httpService.user.wallet_address;
            this.provider = 'circle';

            this.steps[0].completed = true;
        }

        this.subscribeQueryParams();
        this.checkProfileSteps();
        this.checkNextStep();
    }

    checkProfileSteps() {
        console.log('checkProfileSteps user => ', this.httpService.user);

        if (this.httpService.user?.display_name && this.httpService.user?.profile_picture)
            this.steps[0].completed = true;

        // if (this.httpService.user?.telegram?.group_joined && this.httpService.user?.twitter?.group_joined)
        //     this.steps[2].completed = true;

        if (this.httpService.user?.referred_by)
            this.steps[2].completed = true;

        if (this.httpService.user?.terms_and_conditions)
            this.steps[3].completed = true;

        console.log('checkProfileSteps => ', this.steps);
    }

    subscribeQueryParams() {
        this.route.queryParams.subscribe(params => {
            if (params['tab'])
                this.returnUrl = params['returnUrl'];

            if (params['invite_code'])
                this.validateInviteCode(params['invite_code']);

            else
                this.httpService.setMetaData('Connect wallet', 'Connect your wallet');
        });
    }

    validateInviteCode(invite_code: string) {
        this.onboardingService.validateInviteCode(invite_code).subscribe((res: any) => {
            const title = `${res.data.display_name} is inviting you to join Credible with code ${invite_code}`;
            const description = `${res.data.display_name} is inviting you to join Credible with code ${invite_code}`;

            this.httpService.setMetaData(title, description);
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    checkNextStep() {
        this._ngZone.run(() => {
            let currentStep = -1;

            for (let i = 0; i < this.steps.length; i++) {
                if (!this.steps[i].completed) {
                    currentStep = i;
                    break;
                }
            }

            console.log('checkNextStep => ', currentStep);

            if (currentStep === -1) {
                console.log('All steps completed');

                this.httpService.registerStepsCompleted = true;
                this.localStorageService.setItem('rsc', 'true');

                const redirect_url = !this.returnUrl || this.returnUrl === '' ? '/spaceship' : this.returnUrl;
                const base_url = window.location.origin;
                const full_url = `${base_url}${redirect_url}`;

                history.pushState(null, '', full_url);

                this.router.navigateByUrl(redirect_url, {
                    replaceUrl: true
                });

                this.location.onPopState(() => {
                    history.pushState(null, '', full_url);
                });
            }

            else {
                this.currentStep = currentStep;
                this.httpService.registerStepsCompleted = false;
                this.localStorageService.setItem('rsc', 'false');
            }
        });
    }

    changeStep(index: number) {
        if (this.step_skipping_allowed)
            this.currentStep = index;
    }

    previousStep() {
        if (this.currentStep !== 0)
            this.currentStep -= 1;
    }

    notifyWalletConnected($event: any) {
        console.log('register-steps : notifyWalletConnected => ', new Date().getTime());

        const { provider, wallet_address } = $event;

        this.connectedWalletAddres = wallet_address;
        this.provider = provider;

        this.steps[0].completed = true;
        this.checkProfileSteps();
        this.checkNextStep();
    }

    notifyProfileUpdated($event: any) {
        this.steps[1].completed = true;
        this.checkNextStep();
    }
    // notifySocialsConnected($event: any) {
    //     this.steps[1].completed = true;
    //     this.checkNextStep();
    // }

    notifyInviteCode($event: any) {
        this.steps[2].completed = true;
        this.checkNextStep();
    }

    notifyTermsAndCondittions($event: any) {
        this.steps[3].completed = true;
        this.checkNextStep();
    }

    connectWalletNext() {
        if (this.provider = 'email') {
            this.steps[0].completed = true;

            this.checkNextStep();
        }

        else
            this.currentStep += 1;
    }
}
