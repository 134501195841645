<div class="connect_wallet container-fluid">
    <div class="container gx-0">
        <span class="register-heading" *ngIf="current_provider !== 'email'">You will be asked to sign a transaction to
            authenticate you in
            our platform.<br />No funds will be moved and you will not be charged
            a transaction fee.
        </span>

        <div class="ledger" *ngIf="current_provider !== 'email'">
            <cf-checkbox (changed)="ledgerChanged($event)" name="I have a ledger device"></cf-checkbox>
        </div>

        <div class="back-button" *ngIf="current_provider === 'email'" (click)="loginWithWallets()">
            <i class="fa-solid fa-arrow-left-long"></i> Back
        </div>

        <div class="wallets" *ngIf="current_provider !== 'email'">
            <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'phantom'}"
                (click)="changeProvider('phantom')">
                <div class="provider-name">
                    <img src="https://assets.credible.finance/Phantom-Icon-Transparent-Purple.png" />
                    <span>Phantom</span>
                </div>
                <span *ngIf="!phantomDetected" class="wallet-not-detected">
                    <i class="fa-solid fa-circle-xmark"></i>
                </span>
                <span *ngIf="phantomDetected" class="wallet-detected">
                    <i class="fa-solid fa-circle-check"></i>
                </span>
            </div>

            <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'solflare'}"
                (click)="changeProvider('solflare')">
                <div class="provider-name">
                    <img src="https://assets.credible.finance/solflare.png" />
                    <span>Solflare</span>
                </div>
                <img src= />
                <span *ngIf="solflareDetected" class="wallet-detected">
                    <i class="fa-solid fa-circle-check"></i>
                </span>

                <span *ngIf="!solflareDetected" class="wallet-not-detected">
                    <i class="fa-solid fa-circle-xmark"></i>
                </span>
            </div>

            <!-- <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'metamask'}"
                (click)="changeProvider('metamask')">
                <div class="provider-name">
                    <img src="https://assets.credible.finance/logos/Metamask.png" />
                    <span>Metamask</span>
                </div>
                <span *ngIf="!metamaskDetected" class="wallet-not-detected">
                    <i class="fa-solid fa-circle-xmark"></i>
                </span>
                <span *ngIf="metamaskDetected" class="wallet-detected">
                    <i class="fa-solid fa-circle-check"></i>
                </span>
            </div> -->

            <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'email'}"
                (click)="loginWithEmail()">
                <div class="provider-name">
                    <i class="fa-solid fa-envelope"></i>
                    <span>Signin with Email</span>
                </div>
            </div>
        </div>

        <div *ngIf="current_provider === 'email'">
            <app-login (next)="nextHandler()"></app-login>
        </div>

        <div class="row mb-3" *ngIf="!showControls.connectButton">
            <div class="col-md-12 current-wallet-address">
                <cf-input class="web" [value]="wallet_address" label="Wallet address" type="text" [copy]="true"
                    placeholder="Wallet address" (valueChanged)="walletChanged($event)" [formGroup]="registerForm"
                    [readonly]="true" [control]="$any(registerForm).get('wallet_address')">
                </cf-input>

                <cf-input class="mobile" [value]="this.httpService.sliceAddress(wallet_address, 8)"
                    label="Wallet address" type="text" [copy]="true" placeholder="Wallet address"
                    (valueChanged)="walletChanged($event)" [formGroup]="registerForm" [readonly]="true"
                    [control]="$any(registerForm).get('wallet_address')">
                </cf-input>
            </div>
        </div>

        <div class="actions" *ngIf="current_provider === 'phantom'">
            <div class="action" *ngIf="!phantomDetected && width > 600">
                <cf-button label="Download Phantom" (clicked)="phantomService.downloadWallet()"
                    type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="!phantomDetected && width <= 600">
                <cf-button label="Connect Phantom" (clicked)="connectWalletMobile()" type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="phantomDetected && showControls.connectButton">
                <cf-button label="Connect Phantom" (clicked)="phantomService.connectWallet()"
                    [loading]="showControls.signingLoader">
                </cf-button>
            </div>

            <div class="action" *ngIf="phantomDetected && showControls.disconnectButton">
                <cf-button label="Disconnect Phantom" (clicked)="phantomService.disconnectWallet()"
                    type="outline"></cf-button>
            </div>
        </div>

        <div class="actions" *ngIf="current_provider === 'metamask'">
            <div class="action" *ngIf="!metamaskDetected && width > 600">
                <cf-button label="Download Metamask" (clicked)="metamaskService.downloadWallet()"
                    type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="!metamaskDetected && width <= 600">
                <cf-button label="Connect Metamask" (clicked)="connectWalletMobile()" type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="metamaskDetected && showControls.connectButton">
                <cf-button label="Connect Metamask" (clicked)="metamaskService.connectWallet()"
                    [loading]="showControls.signingLoader">
                </cf-button>
            </div>

            <div class="action" *ngIf="metamaskDetected && showControls.disconnectButton">
                <cf-button label="Disconnect Metamask" (clicked)="metamaskService.disconnectWallet()"
                    type="outline"></cf-button>
            </div>
        </div>

        <div class="actions" *ngIf="current_provider === 'solflare'">
            <div class="action" *ngIf="!solflareDetected">
                <cf-button label="Download Solflare" (clicked)="solflareService.downloadWallet()"
                    type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="solflareDetected && showControls.connectButton">
                <cf-button label="Connect Solflare" (clicked)="solflareService.connectWallet()"
                    [loading]="showControls.signingLoader">
                </cf-button>
            </div>

            <div class="action" *ngIf="solflareDetected && showControls.disconnectButton">
                <cf-button label="Disconnect Solflare" (clicked)="solflareService.disconnectWallet()"
                    type="outline"></cf-button>
            </div>
        </div>
    </div>

    <div class="controls">
        <cf-button label="Previous" type="outline" [disabled]="true"></cf-button>
        <cf-button label="Next" (clicked)="nextHandler()" type="outline"
            [disabled]="httpService.user.wallet_address === ''"></cf-button>
    </div>
</div>