<div class="app-login">
    <div class="banner">
        <div class="col-md-12">
            <span class="page-heading">Login</span>
        </div>
        <div class="col-md-12">
            <span class="description">
                Enter your details
            </span>
        </div>
    </div>

    <div class="fields">
        <cf-input label="Email Address" type="text" placeholder="Email Address" [value]="email"
            (valueChanged)="emailChanged($event)" [readonly]="emailReadOnly"></cf-input>
    </div>

    <div class="controls">
        <cf-button type="outline" label="Login" (clicked)="login()" [loading]="loading"></cf-button>
    </div>
</div>