import { bytesRegex, execTyped, integerRegex, isTupleRegex } from "./chunk-WP7KDV47.mjs";
import { __publicField } from "./chunk-NHABU752.mjs";

// package.json
var name = "abitype";
var version = "0.7.1";

// src/errors.ts
var BaseError = class extends Error {
  constructor(shortMessage, args = {}) {
    const details = args.cause instanceof BaseError ? args.cause.details : args.cause?.message ? args.cause.message : args.details;
    const docsPath = args.cause instanceof BaseError ? args.cause.docsPath || args.docsPath : args.docsPath;
    const message = [shortMessage || "An error occurred.", "", ...(args.metaMessages ? [...args.metaMessages, ""] : []), ...(docsPath ? [`Docs: https://abitype.dev${docsPath}`] : []), ...(details ? [`Details: ${details}`] : []), `Version: ${name}@${version}`].join("\n");
    super(message);
    __publicField(this, "details");
    __publicField(this, "docsPath");
    __publicField(this, "metaMessages");
    __publicField(this, "shortMessage");
    __publicField(this, "name", "AbiTypeError");
    if (args.cause) this.cause = args.cause;
    this.details = details;
    this.docsPath = docsPath;
    this.metaMessages = args.metaMessages;
    this.shortMessage = shortMessage;
  }
};

// src/narrow.ts
function narrow(value) {
  return value;
}

// src/human-readable/runtime/signatures.ts
var errorSignatureRegex = /^error (?<name>[a-zA-Z0-9_]+)\((?<parameters>.*?)\)$/;
function isErrorSignature(signature) {
  return errorSignatureRegex.test(signature);
}
function execErrorSignature(signature) {
  return execTyped(errorSignatureRegex, signature);
}
var eventSignatureRegex = /^event (?<name>[a-zA-Z0-9_]+)\((?<parameters>.*?)\)$/;
function isEventSignature(signature) {
  return eventSignatureRegex.test(signature);
}
function execEventSignature(signature) {
  return execTyped(eventSignatureRegex, signature);
}
var functionSignatureRegex = /^function (?<name>[a-zA-Z0-9_]+)\((?<parameters>.*?)\)(?: (?<scope>external|public{1}))?(?: (?<stateMutability>pure|view|nonpayable|payable{1}))?(?: returns \((?<returns>.*?)\))?$/;
function isFunctionSignature(signature) {
  return functionSignatureRegex.test(signature);
}
function execFunctionSignature(signature) {
  return execTyped(functionSignatureRegex, signature);
}
var structSignatureRegex = /^struct (?<name>[a-zA-Z0-9_]+) \{(?<properties>.*?)\}$/;
function isStructSignature(signature) {
  return structSignatureRegex.test(signature);
}
function execStructSignature(signature) {
  return execTyped(structSignatureRegex, signature);
}
var constructorSignatureRegex = /^constructor\((?<parameters>.*?)\)(?:\s(?<stateMutability>payable{1}))?$/;
function isConstructorSignature(signature) {
  return constructorSignatureRegex.test(signature);
}
function execConstructorSignature(signature) {
  return execTyped(constructorSignatureRegex, signature);
}
var fallbackSignatureRegex = /^fallback\(\)$/;
function isFallbackSignature(signature) {
  return fallbackSignatureRegex.test(signature);
}
var receiveSignatureRegex = /^receive\(\) external payable$/;
function isReceiveSignature(signature) {
  return receiveSignatureRegex.test(signature);
}
var modifiers = /* @__PURE__ */new Set(["memory", "indexed", "storage", "calldata"]);
var eventModifiers = /* @__PURE__ */new Set(["indexed"]);
var functionModifiers = /* @__PURE__ */new Set(["calldata", "memory", "storage"]);

// src/human-readable/runtime/cache.ts
function getParameterCacheKey(param, type) {
  if (type) return `${type}:${param}`;
  return param;
}
var parameterCache = /* @__PURE__ */new Map([
// Unnamed
["address", {
  type: "address"
}], ["bool", {
  type: "bool"
}], ["bytes", {
  type: "bytes"
}], ["bytes32", {
  type: "bytes32"
}], ["int", {
  type: "int256"
}], ["int256", {
  type: "int256"
}], ["string", {
  type: "string"
}], ["uint", {
  type: "uint256"
}], ["uint8", {
  type: "uint8"
}], ["uint16", {
  type: "uint16"
}], ["uint24", {
  type: "uint24"
}], ["uint32", {
  type: "uint32"
}], ["uint64", {
  type: "uint64"
}], ["uint96", {
  type: "uint96"
}], ["uint112", {
  type: "uint112"
}], ["uint160", {
  type: "uint160"
}], ["uint192", {
  type: "uint192"
}], ["uint256", {
  type: "uint256"
}],
// Named
["address owner", {
  type: "address",
  name: "owner"
}], ["address to", {
  type: "address",
  name: "to"
}], ["bool approved", {
  type: "bool",
  name: "approved"
}], ["bytes _data", {
  type: "bytes",
  name: "_data"
}], ["bytes data", {
  type: "bytes",
  name: "data"
}], ["bytes signature", {
  type: "bytes",
  name: "signature"
}], ["bytes32 hash", {
  type: "bytes32",
  name: "hash"
}], ["bytes32 r", {
  type: "bytes32",
  name: "r"
}], ["bytes32 root", {
  type: "bytes32",
  name: "root"
}], ["bytes32 s", {
  type: "bytes32",
  name: "s"
}], ["string name", {
  type: "string",
  name: "name"
}], ["string symbol", {
  type: "string",
  name: "symbol"
}], ["string tokenURI", {
  type: "string",
  name: "tokenURI"
}], ["uint tokenId", {
  type: "uint256",
  name: "tokenId"
}], ["uint8 v", {
  type: "uint8",
  name: "v"
}], ["uint256 balance", {
  type: "uint256",
  name: "balance"
}], ["uint256 tokenId", {
  type: "uint256",
  name: "tokenId"
}], ["uint256 value", {
  type: "uint256",
  name: "value"
}],
// Indexed
["event:address indexed from", {
  type: "address",
  name: "from",
  indexed: true
}], ["event:address indexed to", {
  type: "address",
  name: "to",
  indexed: true
}], ["event:uint indexed tokenId", {
  type: "uint256",
  name: "tokenId",
  indexed: true
}], ["event:uint256 indexed tokenId", {
  type: "uint256",
  name: "tokenId",
  indexed: true
}]]);

// src/human-readable/runtime/utils.ts
function parseSignature(signature, structs = {}) {
  if (isFunctionSignature(signature)) {
    const match = execFunctionSignature(signature);
    if (!match) throw new BaseError("Invalid function signature.", {
      details: signature
    });
    const inputParams = splitParameters(match.parameters);
    const inputs = [];
    const inputLength = inputParams.length;
    for (let i = 0; i < inputLength; i++) {
      inputs.push(parseAbiParameter(inputParams[i], {
        modifiers: functionModifiers,
        structs,
        type: "function"
      }));
    }
    const outputs = [];
    if (match.returns) {
      const outputParams = splitParameters(match.returns);
      const outputLength = outputParams.length;
      for (let i = 0; i < outputLength; i++) {
        outputs.push(parseAbiParameter(outputParams[i], {
          modifiers: functionModifiers,
          structs,
          type: "function"
        }));
      }
    }
    return {
      name: match.name,
      type: "function",
      stateMutability: match.stateMutability ?? "nonpayable",
      inputs,
      outputs
    };
  }
  if (isEventSignature(signature)) {
    const match = execEventSignature(signature);
    if (!match) throw new BaseError("Invalid event signature.", {
      details: signature
    });
    const params = splitParameters(match.parameters);
    const abiParameters = [];
    const length = params.length;
    for (let i = 0; i < length; i++) {
      abiParameters.push(parseAbiParameter(params[i], {
        modifiers: eventModifiers,
        structs,
        type: "event"
      }));
    }
    return {
      name: match.name,
      type: "event",
      inputs: abiParameters
    };
  }
  if (isErrorSignature(signature)) {
    const match = execErrorSignature(signature);
    if (!match) throw new BaseError("Invalid error signature.", {
      details: signature
    });
    const params = splitParameters(match.parameters);
    const abiParameters = [];
    const length = params.length;
    for (let i = 0; i < length; i++) {
      abiParameters.push(parseAbiParameter(params[i], {
        structs,
        type: "error"
      }));
    }
    return {
      name: match.name,
      type: "error",
      inputs: abiParameters
    };
  }
  if (isConstructorSignature(signature)) {
    const match = execConstructorSignature(signature);
    if (!match) throw new BaseError("Invalid constructor signature.", {
      details: signature
    });
    const params = splitParameters(match.parameters);
    const abiParameters = [];
    const length = params.length;
    for (let i = 0; i < length; i++) {
      abiParameters.push(parseAbiParameter(params[i], {
        structs,
        type: "constructor"
      }));
    }
    return {
      type: "constructor",
      stateMutability: match.stateMutability ?? "nonpayable",
      inputs: abiParameters
    };
  }
  if (isFallbackSignature(signature)) return {
    type: "fallback"
  };
  if (isReceiveSignature(signature)) return {
    type: "receive",
    stateMutability: "payable"
  };
  throw new BaseError("Unknown signature.", {
    details: signature
  });
}
var abiParameterWithoutTupleRegex = /^(?<type>[a-zA-Z0-9_]+?)(?<array>(?:\[\d*?\])+?)?(?:\s(?<modifier>calldata|indexed|memory|storage{1}))?(?:\s(?<name>[a-zA-Z0-9_]+))?$/;
var abiParameterWithTupleRegex = /^\((?<type>.+?)\)(?<array>(?:\[\d*?\])+?)?(?:\s(?<modifier>calldata|indexed|memory|storage{1}))?(?:\s(?<name>[a-zA-Z0-9_]+))?$/;
var dynamicIntegerRegex = /^u?int$/;
function parseAbiParameter(param, options) {
  const parameterCacheKey = getParameterCacheKey(param, options?.type);
  if (parameterCache.has(parameterCacheKey)) return parameterCache.get(parameterCacheKey);
  const isTuple = isTupleRegex.test(param);
  const match = execTyped(isTuple ? abiParameterWithTupleRegex : abiParameterWithoutTupleRegex, param);
  if (!match) throw new BaseError("Invalid ABI parameter.", {
    details: param
  });
  if (match.name && isSolidityKeyword(match.name)) throw new BaseError("Invalid ABI parameter.", {
    details: param,
    metaMessages: [`"${match.name}" is a protected Solidity keyword. More info: https://docs.soliditylang.org/en/latest/cheatsheet.html`]
  });
  const name2 = match.name ? {
    name: match.name
  } : {};
  const indexed = match.modifier === "indexed" ? {
    indexed: true
  } : {};
  const structs = options?.structs ?? {};
  let type;
  let components = {};
  if (isTuple) {
    type = "tuple";
    const params = splitParameters(match.type);
    const components_ = [];
    const length = params.length;
    for (let i = 0; i < length; i++) {
      components_.push(parseAbiParameter(params[i], {
        structs
      }));
    }
    components = {
      components: components_
    };
  } else if (match.type in structs) {
    type = "tuple";
    components = {
      components: structs[match.type]
    };
  } else if (dynamicIntegerRegex.test(match.type)) {
    type = `${match.type}256`;
  } else {
    type = match.type;
    if (!(options?.type === "struct") && !isSolidityType(type)) throw new BaseError("Unknown type.", {
      metaMessages: [`Type "${type}" is not a valid ABI type.`]
    });
  }
  if (match.modifier) {
    if (!options?.modifiers?.has?.(match.modifier)) throw new BaseError("Invalid ABI parameter.", {
      details: param,
      metaMessages: [`Modifier "${match.modifier}" not allowed${options?.type ? ` in "${options.type}" type` : ""}.`]
    });
    if (functionModifiers.has(match.modifier) && !isValidDataLocation(type, !!match.array)) throw new BaseError("Invalid ABI parameter.", {
      details: param,
      metaMessages: [`Modifier "${match.modifier}" not allowed${options?.type ? ` in "${options.type}" type` : ""}.`, `Data location can only be specified for array, struct, or mapping types, but "${match.modifier}" was given.`]
    });
  }
  const abiParameter = {
    type: `${type}${match.array ?? ""}`,
    ...name2,
    ...indexed,
    ...components
  };
  parameterCache.set(parameterCacheKey, abiParameter);
  return abiParameter;
}
function splitParameters(params, result = [], current = "", depth = 0) {
  if (params === "") {
    if (current === "") return result;
    if (depth !== 0) throw new BaseError("Unbalanced parentheses.", {
      metaMessages: [`"${current.trim()}" has too many ${depth > 0 ? "opening" : "closing"} parentheses.`],
      details: `Depth "${depth}"`
    });
    return [...result, current.trim()];
  }
  const length = params.length;
  for (let i = 0; i < length; i++) {
    const char = params[i];
    const tail = params.slice(i + 1);
    switch (char) {
      case ",":
        return depth === 0 ? splitParameters(tail, [...result, current.trim()]) : splitParameters(tail, result, `${current}${char}`, depth);
      case "(":
        return splitParameters(tail, result, `${current}${char}`, depth + 1);
      case ")":
        return splitParameters(tail, result, `${current}${char}`, depth - 1);
      default:
        return splitParameters(tail, result, `${current}${char}`, depth);
    }
  }
  return [];
}
function isSolidityType(type) {
  return type === "address" || type === "bool" || type === "function" || type === "string" || bytesRegex.test(type) || integerRegex.test(type);
}
var protectedKeywordsRegex = /^(?:after|alias|anonymous|apply|auto|byte|calldata|case|catch|constant|copyof|default|defined|error|event|external|false|final|function|immutable|implements|in|indexed|inline|internal|let|mapping|match|memory|mutable|null|of|override|partial|private|promise|public|pure|reference|relocatable|return|returns|sizeof|static|storage|struct|super|supports|switch|this|true|try|typedef|typeof|var|view|virtual)$/;
function isSolidityKeyword(name2) {
  return name2 === "address" || name2 === "bool" || name2 === "function" || name2 === "string" || name2 === "tuple" || bytesRegex.test(name2) || integerRegex.test(name2) || protectedKeywordsRegex.test(name2);
}
function isValidDataLocation(type, isArray) {
  return isArray || type === "bytes" || type === "string" || type === "tuple";
}

// src/human-readable/runtime/structs.ts
function parseStructs(signatures) {
  const shallowStructs = {};
  const signaturesLength = signatures.length;
  for (let i = 0; i < signaturesLength; i++) {
    const signature = signatures[i];
    if (!isStructSignature(signature)) continue;
    const match = execStructSignature(signature);
    if (!match) throw new BaseError("Invalid struct signature.", {
      details: signature
    });
    const properties = match.properties.split(";");
    const components = [];
    const propertiesLength = properties.length;
    for (let k = 0; k < propertiesLength; k++) {
      const property = properties[k];
      const trimmed = property.trim();
      if (!trimmed) continue;
      const abiParameter = parseAbiParameter(trimmed, {
        type: "struct"
      });
      components.push(abiParameter);
    }
    if (!components.length) throw new BaseError("Invalid struct signature.", {
      details: signature,
      metaMessages: ["No properties exist."]
    });
    shallowStructs[match.name] = components;
  }
  const resolvedStructs = {};
  const entries = Object.entries(shallowStructs);
  const entriesLength = entries.length;
  for (let i = 0; i < entriesLength; i++) {
    const [name2, parameters] = entries[i];
    resolvedStructs[name2] = resolveStructs(parameters, shallowStructs);
  }
  return resolvedStructs;
}
var typeWithoutTupleRegex = /^(?<type>[a-zA-Z0-9_]+?)(?<array>(?:\[\d*?\])+?)?$/;
function resolveStructs(abiParameters, structs, ancestors = /* @__PURE__ */new Set()) {
  const components = [];
  const length = abiParameters.length;
  for (let i = 0; i < length; i++) {
    const abiParameter = abiParameters[i];
    const isTuple = isTupleRegex.test(abiParameter.type);
    if (isTuple) components.push(abiParameter);else {
      const match = execTyped(typeWithoutTupleRegex, abiParameter.type);
      if (!match?.type) throw new BaseError("Invalid ABI parameter.", {
        details: JSON.stringify(abiParameter, null, 2),
        metaMessages: ["ABI parameter type is invalid."]
      });
      const {
        array,
        type
      } = match;
      if (type in structs) {
        if (ancestors.has(type)) throw new BaseError("Circular reference detected.", {
          metaMessages: [`Struct "${type}" is a circular reference.`]
        });
        components.push({
          ...abiParameter,
          type: `tuple${array ?? ""}`,
          components: resolveStructs(structs[type] ?? [], structs, /* @__PURE__ */new Set([...ancestors, type]))
        });
      } else {
        if (isSolidityType(type)) components.push(abiParameter);else throw new BaseError("Unknown type.", {
          metaMessages: [`Type "${type}" is not a valid ABI type. Perhaps you forgot to include a struct signature?`]
        });
      }
    }
  }
  return components;
}

// src/human-readable/parseAbi.ts
function parseAbi(signatures) {
  const structs = parseStructs(signatures);
  const abi = [];
  const length = signatures.length;
  for (let i = 0; i < length; i++) {
    const signature = signatures[i];
    if (isStructSignature(signature)) continue;
    abi.push(parseSignature(signature, structs));
  }
  return abi;
}

// src/human-readable/parseAbiItem.ts
function parseAbiItem(signature) {
  let abiItem;
  if (typeof signature === "string") abiItem = parseSignature(signature);else {
    const structs = parseStructs(signature);
    const length = signature.length;
    for (let i = 0; i < length; i++) {
      const signature_ = signature[i];
      if (isStructSignature(signature_)) continue;
      abiItem = parseSignature(signature_, structs);
      break;
    }
  }
  if (!abiItem) throw new BaseError("Failed to parse ABI item.", {
    details: `parseAbiItem(${JSON.stringify(signature, null, 2)})`,
    docsPath: "/api/human.html#parseabiitem-1"
  });
  return abiItem;
}

// src/human-readable/parseAbiParameter.ts
function parseAbiParameter2(param) {
  let abiParameter;
  if (typeof param === "string") abiParameter = parseAbiParameter(param, {
    modifiers
  });else {
    const structs = parseStructs(param);
    const length = param.length;
    for (let i = 0; i < length; i++) {
      const signature = param[i];
      if (isStructSignature(signature)) continue;
      abiParameter = parseAbiParameter(signature, {
        modifiers,
        structs
      });
      break;
    }
  }
  if (!abiParameter) throw new BaseError("Failed to parse ABI parameter.", {
    details: `parseAbiParameter(${JSON.stringify(param, null, 2)})`,
    docsPath: "/api/human.html#parseabiparameter-1"
  });
  return abiParameter;
}

// src/human-readable/parseAbiParameters.ts
function parseAbiParameters(params) {
  const abiParameters = [];
  if (typeof params === "string") {
    const parameters = splitParameters(params);
    const length = parameters.length;
    for (let i = 0; i < length; i++) {
      abiParameters.push(parseAbiParameter(parameters[i], {
        modifiers
      }));
    }
  } else {
    const structs = parseStructs(params);
    const length = params.length;
    for (let i = 0; i < length; i++) {
      const signature = params[i];
      if (isStructSignature(signature)) continue;
      const parameters = splitParameters(signature);
      const length2 = parameters.length;
      for (let k = 0; k < length2; k++) {
        abiParameters.push(parseAbiParameter(parameters[k], {
          modifiers,
          structs
        }));
      }
    }
  }
  if (abiParameters.length === 0) throw new BaseError("Failed to parse ABI parameters.", {
    details: `parseAbiParameters(${JSON.stringify(params, null, 2)})`,
    docsPath: "/api/human.html#parseabiparameters-1"
  });
  return abiParameters;
}
export { BaseError, narrow, parseAbi, parseAbiItem, parseAbiParameter2 as parseAbiParameter, parseAbiParameters };