import {
    Component, OnInit 
} from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { Router } from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { CircleService } from '../../circle.service';

@Component({
    selector: 'app-circle-balances',
    templateUrl: './circle-balances.component.html',
    styleUrl: './circle-balances.component.scss'
})
export class CircleBalancesComponent implements OnInit {
    currencies: any = [];

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private circleService: CircleService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getCircleCurrencies();
    }

    getCircleCurrencies() {
        this.circleService.getCircleCurrencies(this.httpService.user.blockchain).subscribe((res: any) => {
            const currencies = res.data;

            currencies.forEach((item: any) => {
                item.loading = true;
            });

            this.currencies = currencies;

            this.getBalances();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    async getBalances() {
        const blockchain = this.httpService.user.blockchain;
        const wallet_address = this.httpService.user.wallet_address;

        for (let i = 0; i < this.currencies.length; i++) {
            const mint_address = this.currencies[i].mint_address;

            this.httpService.getCurrencyBalance(blockchain, blockchain, wallet_address, mint_address).subscribe((res: any) => {
                this.currencies[i].balance = res.data;
                this.currencies[i].loading = false;
            }, (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);
            });
        }
    }

    redirectToDeposit() {
        this.router.navigate([ '/funds' ], {
            queryParams: {
                tab: 'deposits'
            }
        });
    }

    redirectToWithdrawal() {
        this.router.navigate([ '/funds' ], {
            queryParams: {
                tab: 'withdrawals'
            }
        });
    }
}