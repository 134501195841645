<div class="default-pool-container" matTooltip="Coming Soon">
    <div class="default-pool-info">
        <div class="default-pool-details">
            <div class="default-pool-logo">
                <img [src]="pool.image_url" [alt]="'SpaceX'" />
            </div>
            <div class="default-pool-name">{{pool.name}}</div>
        </div>
        <div class="default-pool-rating">
            {{pool.series}}
        </div>
    </div>
    <div class="default-pool-progress-bar">
        <div class="default-pool-progress"></div>
    </div>
    <div class="default-pool-investment-current">
        {{pool.current_investment}}
        <div class="default-pool-investment-total">
            {{pool.total_investment}}
        </div>
    </div>
    <div class="pool-sub-section">
        <div class="pool-sub-section-left">
            <div class="pool-sub-section-left-top">
                {{pool.raised}}
            </div>
            <div class="pool-sub-section-left-bottom">
                Raised
            </div>
        </div>
        <div class="pool-sub-section-right">
            <div class="pool-sub-section-right-top">
                {{pool.existing_investors}}
            </div>
            <div class="pool-sub-section-right-bottom">
                Existing Investors
            </div>
        </div>
    </div>
</div>