<div class="app-user-profile container-fluid dark">
    <div class="user-profile" *ngIf="!loading">
        <div class="profile_picture">
            <img [src]="profile_picture_url" alt="Profile picture">
            <div class="overlay" (click)="openProfilePictureDialog()">Change</div>
        </div>
        <div class="display_name">
            <cf-input label="Display name" [value]="this.display_name" [copy]="false" theme="dark"
                (valueChanged)="displayNameChanged($event)"></cf-input>
        </div>
    </div>

    <div class="loading" *ngIf="loading">
        <cf-loader class="loader"></cf-loader>
    </div>

    <div class="controls">
        <cf-button label="Previous" (clicked)="previousHandler()" type="outline"></cf-button>
        <cf-button label="Next" (clicked)="next()" type="outline" [loading]="loading"></cf-button>
    </div>
</div>