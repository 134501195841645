import {
    Component, HostListener, OnInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { HttpService } from '../../services/http.service';

import { getWindow } from 'ssr-window';
import { LineChartComponent } from '../charts/line-chart/line-chart.component';

@Component({
    selector: 'app-lend-pool-details',
    templateUrl: './lend-pool-details.component.html',
    styleUrl: './lend-pool-details.component.scss'
})

export class LendPoolDetailsComponent implements OnInit {
    category_id: string | null = null;
    pool_id: string | null = null;

    window: Window;
    
    width: any;

    @ViewChild(LineChartComponent) child!: LineChartComponent;

    constructor(private route: ActivatedRoute, public httpService: HttpService) {
        this.window = getWindow();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        this.category_id = this.route.snapshot.paramMap.get('category_id');
        this.pool_id = this.route.snapshot.paramMap.get('pool_id');

        this.width = this.window.innerWidth;
    }
}
