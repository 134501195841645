import {
    Component, EventEmitter, HostListener, Input, OnInit, Output
} from '@angular/core';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-cf-otp',
    templateUrl: './cf-otp.component.html',
    styleUrls: [ './cf-otp.component.scss' ]
})
export class CfOtpComponent implements OnInit {
    @Input() length: number = 6;
    @Output() changed = new EventEmitter<string>();
    @Input() label: string = '';
    @Input() showLabel: boolean = true;
    @Input() prefillCode: string = '';
    numbers: number[] = [];
    otp: string[] = [];
    focus: boolean[] = [];
    allowedKeys: Set<string>;
    currentIndex: number = 0;
    width: any;
    window: Window;
    platform: string = 'web';
    controlPressed: boolean = false;

    constructor() {
        this.window = getWindow();

        this.width = this.window.outerWidth;

        if (this.width <= 768)
            this.platform = 'mobile';
        
        const allowedKeys = [
            'Backspace', // backspace
            'Digit0', // zero
            'Digit1', // one
            'Digit2', // two
            'Digit3', // three
            'Digit4', // four
            'Digit5', // five
            'Digit6', // six
            'Digit7', // seven
            'Digit8', // eight
            'Digit9', // nine
            'KeyV', // for copy paste
            'MetaLeft', // left command key in mac
            'MetaRight' // left command key in mac
        ];

        for (let i = 65; i <= 90; i++)
            allowedKeys.push(`Key${String.fromCharCode(i)}`);

        this.allowedKeys = new Set(allowedKeys);
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.outerWidth;

        console.log('width on resize', this.width);

        if (this.width < 768)
            this.platform = 'mobile';
    }

    getInputId(index: number): string {
        if (this.width < 768)
            return `cf-otp-input-mobile-${index}`;
        else
            return `cf-otp-input-web-${index}`;
    }

    ngOnInit(): void {
        this.numbers = Array.from({
            length: this.length
        }, (_, i) => i);
        this.otp = Array(this.length).fill('');
        this.changeInputFocus();

        if (this.prefillCode)
            this.prefillOtp(this.prefillCode);
    }
    prefillOtp(text: string, index = 0): void {
        // const text = event.clipboardData?.getData('Text') || '';
        console.log('prefill', text);
        // const characters = text.split('').filter(char => /^[a-zA-Z0-9]$/.test(char)).slice(0, this.length - index);

        // const text = event.clipboardData?.getData('Text') || '';
        const characters = text.split('').filter(char => /^[a-zA-Z0-9]$/.test(char)).slice(0, this.length - index);

        // Update OTP array and the corresponding input fields
        characters.forEach((char, i) => {
            if (index + i < this.length) {
                this.setOtpArray(index + i, char);

                const input = document.getElementById(this.getInputId(index + i)) as HTMLInputElement;

                if (input)
                    input.value = char; // Directly set value
            }
        });

        // Update currentIndex and focus
        this.currentIndex = Math.min(index + characters.length, this.length - 1);
        this.changeInputFocus();

        // event.preventDefault();
    }

    handleKeyDown(event: KeyboardEvent, index: number): boolean {
        const allowed = this.allowedKeys.has(event.code);

        console.log('handleKeyDown', event.code, allowed);

        if (event.code === 'ControlLeft' || event.code === 'ControlRight')
            this.controlPressed = true;

        else if (event.code !== 'KeyV')
            this.controlPressed = false;

        if (!allowed)
            event.preventDefault();

        return allowed;
    }

    handleKeyUp(event: KeyboardEvent, index: number): void {
        const currentKey = event.key;
        const isAlphanumeric = /^[a-zA-Z0-9]$/.test(currentKey);

        console.log('handleKeyUp', currentKey, isAlphanumeric);
        console.log('controlPressed', this.controlPressed);

        if (this.controlPressed && (currentKey === 'v' || currentKey === 'V' || currentKey === 'KeyV')) {
            console.log('ctrl+v was pressed');

            return ;
        }

        if (isAlphanumeric) {
            this.setOtpArray(index, currentKey);

            if (index < this.length - 1) {
                this.currentIndex = index + 1;
                this.changeInputFocus();
            }
        }
        else if (event.code === 'Backspace') {
            if (this.otp[index] !== '') {
                // Clear the current input value
                this.setOtpArray(index, '');
            }
            else if (index > 0) {
                // Move to the previous input field if the current field is empty
                this.currentIndex = index - 1;
                this.changeInputFocus();
                this.setOtpArray(this.currentIndex, ''); // Clear the previous field
            }
        }
    }

    handlePaste(event: ClipboardEvent, index: number): void {
        const text = event.clipboardData?.getData('Text') || '';
        const characters = text.split('').filter(char => /^[a-zA-Z0-9]$/.test(char)).slice(0, this.length - index);

        console.log('characters', characters);

        const set = new Set();

        // Update OTP array and the corresponding input fields
        characters.forEach((char, i) => {
            console.log('handlePaste char', char, 'i', i);
            
            if (index + i < this.length) {
                if (set.has(index + i)) 
                    return;

                set.add(index + i);
                
                console.log('set', Array.from(set));

                this.setOtpArray(index + i, char);

                const input = document.getElementById(this.getInputId(index + i)) as HTMLInputElement;

                if (input)
                    input.value = char; // Directly set value
            }
        });

        // Update currentIndex and focus
        this.currentIndex = Math.min(index + characters.length, this.length - 1);
        this.changeInputFocus();

        event.preventDefault();
    }

    changeInputFocus(): void {
        console.log('changeInputFocus', this.currentIndex);

        // Clear focus from all input fields
        this.focus.fill(false);

        // Ensure the currentIndex is within the valid range
        if (this.currentIndex >= 0 && this.currentIndex < this.length) {
            this.focus[this.currentIndex] = true;

            // Manually focus on the input element
            const id = this.getInputId(this.currentIndex);

            console.log('id', id);

            const inputElement = document.getElementById(id) as HTMLInputElement;

            console.log('inputElement', inputElement);

            if (inputElement) {
                inputElement.focus();

                // Set the cursor to the end of the input field if there's text
                if (inputElement.value.length > 0) {
                    console.log('inputElement.value.length', inputElement.value.length);

                    inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
                }
            }
        }
    }

    // setOtpArray(index: number, value: string): void {
    //     console.log('index', index, 'value', value);

    //     this.otp[index] = value;

    //     const validOtp = this.otp.join('');

    //     console.log('validOtp', validOtp);

    //     this.changed.emit(validOtp);
    // }

    setOtpArray(index: number, value: string | undefined): void {
        console.log('setOtpArray index:', index, 'value:', value);

        if (index < 0 || index >= this.length) {
            console.error('Index out of bounds:', index);

            return;
        }

        console.log('index:', index, 'value:', value);

        // Update the OTP array
        this.otp[index] = value || '';

        // Emit the updated OTP
        const validOtp = this.otp.map(char => char || '').join('');

        console.log('validOtp:', validOtp);
        this.changed.emit(validOtp);

        // Optionally force Angular to detect changes
        // this.changeDetectorRef.detectChanges();
    }
}
