import {
    Component, OnInit 
} from '@angular/core';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';
import { HttpService } from '../../../services/http.service';
import {
    ActivatedRoute, Router 
} from '@angular/router';

@Component({
    selector: 'app-circle-funds',
    templateUrl: './circle-funds.component.html',
    styleUrl: './circle-funds.component.scss'
})
export class CircleFundsComponent implements OnInit {
    tabs: Array<TabOption> = [{
        label: 'Balances',
        value: 'balances'
    }, {
        label: 'Deposits',
        value: 'deposits'
    },
    {
        label: 'Withdrawals',
        value: 'withdrawals'
    }];
    currentTab: string = '';

    constructor(
        public httpService: HttpService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.currentTab = this.tabs[0].value;

        this.subscribeQueryParamsObservables();
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                }, 
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    subscribeQueryParamsObservables() {
        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find(t => t.value === tab)) 
                    this.currentTab = tab;
            }
        });
    }
}
