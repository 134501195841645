<div class="app-members container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="members-container">
                <div class="member-container" *ngFor="let member of members">
                    <div class="member-info">
                        <div>
                            <img *ngIf="member.profile_picture" class="member-profile-photo"
                                [src]="member.profile_picture" />
                            <div *ngIf="!member.profile_picture" class="member-profile-photo-dummy"></div>
                        </div>
                        <div class="member-data">
                            <span class="member-name">
                                {{ member.display_name }}
                            </span>
                            <div class="member-wallet-address">
                                <span>
                                    {{ httpService.sliceAddress(member.child_wallet_address,6) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="member-stats">
                        <div class="member-stat">
                            <span class="member-stat-value">
                                <span style="font-size: 14px !important">
                                    $</span>
                                {{ member.users_stats.tvl | number : "0.0-0" }}
                            </span>
                            <span class="member-stat-title">Liquidity Supplied</span>
                        </div>
                        <div class="member-stat">
                            <span class="member-stat-value">
                                {{ member.diamonds | number : "0.0-0" }}
                            </span>
                            <span class="member-stat-title">Diamonds</span>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="loading">
                    <div class="loader-content">
                        <cf-loader class="loader"></cf-loader>
                    </div>
                </div>
            </div>
            <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
                (changed)="pageChanged($event)"></cf-paginator>
            <div class="no-records" *ngIf="members.length === 0 && !loading && httpService.user.wallet_address !== ''">
                No members found
            </div>
        </div>
    </div>
</div>