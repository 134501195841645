<div class="app-ambassador">
    <div class="page-heading">
        Referrals
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="tabs">
                <span class="tab" (click)="changeTab(tab.key)" [ngClass]="{ 'tab-active': currentTab === tab.key }"
                    *ngFor="let tab of tabs">{{ tab.name }}</span>
            </div>
            <div class="tabs-divider"></div>
        </div>
    </div>

    <div class="row transactions">
        <app-ambassador-onboarding *ngIf="currentTab === 'onboarding'"></app-ambassador-onboarding>
        <app-ambassador-tvl *ngIf="currentTab === 'liquidity'"></app-ambassador-tvl>
        <app-members *ngIf="currentTab === 'members'"></app-members>
        <app-ambassador-leaderboard *ngIf="currentTab === 'leaderboard'"></app-ambassador-leaderboard>
        <app-ambassador-cred *ngIf="currentTab === 'cred'"></app-ambassador-cred>
    </div>
</div>