<div class="app-header" [ngClass]="themeClasses">
    <div class="content" *ngIf="width > 768">
        <div class="left-section" *ngIf="current_url === '/'">
            <div class="logo" [routerLink]="'/'">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" routerLink="/" />
            </div>
        </div>

        <div class="right-section" *ngIf="current_url !== '/'">
            <div class="profile-information" *ngIf="httpService.user.display_name !== ''">
                <div class="name-bar">
                    <div class="d-flex gap-2">
                        <img [src]="httpService.user.profile_picture" alt="Profile picture"
                            *ngIf="httpService.user.profile_picture && httpService.user.profile_picture !==  ''">
                        <div *ngIf="!httpService.user.profile_picture || httpService.user.profile_picture ===  ''"
                            class="empty-image"></div>
                        <div class="name">Hello {{ httpService.user.display_name }}</div>
                    </div>
                    <div class="actions">
                        <i class="fa-solid fa-chevron-down"></i>
                        <i class="fa-solid fa-chevron-up"></i>
                    </div>
                </div>
                <app-header-popup-menu class="popup-menu"></app-header-popup-menu>
            </div>

            <cf-button *ngIf="showConnectWallet" label="Connect wallet" type="outline"
                (clicked)="connectWallet()"></cf-button>
        </div>

        <div class="right-section" *ngIf="current_url === '/'">
            <cf-button label="Launch App" type="solid" (clicked)="gotoURL('/spaceship')"></cf-button>
        </div>
    </div>

    <div *ngIf="width <= 768" class="mobile">
        <div class="d-flex align-items-center justify-content-between">
            <div class="left" (click)="closeNavbar(); gotoURL('')">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" routerLink="/pools" />
            </div>

            <div class="right" *ngIf="current_url !== '/'">
                <i *ngIf="displayConnectWalletButton" class="fa-solid fa-wallet" (click)="connectWallet()"
                    style="color: white; font-size: 24px; margin-right: 1rem"></i>
                <i class="fa fa-bars" (click)="isMenuCollapsed = !isMenuCollapsed"
                    style="color: white; font-size: 24px"></i>
            </div>

            <div class="right" *ngIf="current_url === '/'">
                <cf-button label="Launch App" type="solid" (clicked)="gotoURL('/spaceship')"></cf-button>
            </div>
        </div>
        <div class="stats" *ngIf="!isMenuCollapsed">
            <cf-card theme="dark" [stat]="'$' + (tvl_usd | number : '0.0-0')" heading="Total value locked"
                type="compact"></cf-card>
        </div>
        <div *ngIf="!isMenuCollapsed" class="vh-100 mt-5 ps-3 mobile_menu">

            <div *ngIf="httpService.checkUserOnboardingStatus()">
                <div class="mobile-menu-item">
                    <div class="d-flex align-items-center" (click)="closeNavbar(); gotoURL('/spaceship')">
                        <img class="me-3" src="https://assets.credible.finance/Spaceship11.gif" alt="" />
                        <span class="heading">Spaceship</span>
                    </div>
                </div>
                <!-- <div class="mobile-menu-item">
                    <div class="d-flex align-items-center" (click)="closeNavbar(); gotoURL('/dashboard')">
                        <img class="me-3" src="https://assets.credible.finance/dashboard-2.png" alt="" />
                        <span class="heading">Dashboard</span>
                    </div>
                </div> -->
                <div class="mobile-menu-item">
                    <div class="d-flex align-items-center" (click)="closeNavbar(); gotoURL('/payfi')">
                        <img class="me-3" src="https://assets.credible.finance/fund.png" alt="" />
                        <span class="heading">PayFi</span>
                    </div>
                </div>
                <div class="mobile-menu-item">
                    <div class="d-flex align-items-center" (click)="closeNavbar(); gotoURL('/liquidity')">
                        <img class="me-3" src="https://assets.credible.finance/Lend-1.png" alt="" />
                        <span class="heading">Liquidity</span>
                    </div>
                </div>
                <!-- <div class="mobile-menu-item">
                    <div class="d-flex align-items-center" (click)="closeNavbar(); gotoURL('/tokenize')">
                        <img class="me-3" src="https://assets.credible.finance/Borrow-3.png" alt="" />
                        <span class="heading">Tokenize</span>
                    </div>
                    <div class="ps-5">
                    <div class="sub" (click)="closeNavbar();gotoURL('')">Create pool</div>
                </div>
                </div> -->
                <div class="mobile-menu-item">
                    <div class="d-flex align-items-center" (click)="closeNavbar(); gotoURL('/transactions')">
                        <img class="me-3" src="https://assets.credible.finance/Transaction12.png" alt="" />
                        <span class="heading">Transactions</span>
                    </div>
                </div>
                <div class="mobile-menu-item">
                    <div class="d-flex align-items-center" (click)="closeNavbar(); gotoURL('/profile')">
                        <img class="me-3" src="https://assets.credible.finance/Profile91.png" alt="" />
                        <span class="heading">Account</span>
                    </div>
                </div>
                <!-- <div class="mobile-menu-item">
                    <div class="d-flex align-items-center" (click)="closeNavbar(); gotoURL('/members')">
                        <img class="me-3" src="https://assets.credible.finance/customer2.png" alt="" />
                        <span class="heading">Members</span>
                    </div>
                </div> -->
            </div>

            <div class="mobile-menu-item" *ngIf="httpService?.user?.wallet_address !== ''">
                <div class="d-flex align-items-center" (click)="closeNavbar(); logout()">
                    <img class="me-3" src="https://assets.credible.finance/Logout-1.png" alt="" />
                    <span class="heading">Logout</span>
                </div>
                <br />
                <div class="community mb-3">
                    <div class="heading text-center" *ngIf="!isMenuCollapsed">
                        Join Our Community
                    </div>

                    <div class="icons" [ngClass]="{ 'collased-community-icons': isMenuCollapsed }">
                        <a href="https://twitter.com/crediblefin" target="_blank"><i class="fa-brands fa-twitter"
                                matTooltip="Twitter"></i></a>
                        <a href="https://t.me/crediblefinance" target="_blank"><i class="fa-brands fa-telegram"
                                matTooltip="Telegram"></i></a>
                        <a href="https://medium.com/@credible_finance" target="_blank"><i class="fa-brands fa-medium"
                                matTooltip="Medium"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>