import {
    Component, OnInit 
} from '@angular/core';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { HttpService } from '../../../services/http.service';

@Component({
    selector: 'app-circle-new-deposit',
    templateUrl: './circle-new-deposit.component.html',
    styleUrl: './circle-new-deposit.component.scss'
})
export class CircleNewDepositComponent implements OnInit {
    currencyOptions: Array<IDropdown> = [{
        label: 'USDC',
        value: 'usdc',
        logo: this.httpService.getCurrencyUrl('usdc'),
        optionEnabled: true
    }, {
        label: 'Solana',
        value: 'sol',
        logo: this.httpService.getCurrencyUrl('sol'),
        optionEnabled: true
    }];
    selectedCurrency: string = '';

    networkOptions: Array<IDropdown> = [{
        label: 'Solana',
        value: 'sol',
        logo: this.httpService.getCurrencyUrl('sol'),
        optionEnabled: true
    }];
    selectedNetwork: string = '';

    wallet_address: string = '';

    constructor(
        public httpService: HttpService
    ) {}

    ngOnInit(): void {
        this.selectedCurrency = this.currencyOptions[0].value;
        this.selectedNetwork = this.networkOptions[0].value;
        this.wallet_address = this.httpService.user.wallet_address;
    }

    changeCurrency(option: IDropdown): void {
        this.selectedCurrency = option.value;
    }

    changeNetwork(option: IDropdown): void {
        this.selectedNetwork = option.value;
    }
}
