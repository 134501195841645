<div class="app-transactions container-fluid" [ngClass]="httpService.theme">
    <cf-tabs [theme]="httpService.theme" [tabs]="tabs" [currentTab]="currentTab"
        (changed)="changeTab($event)"></cf-tabs>

    <div class="tabs">
        <app-my-investments *ngIf="currentTab === 'my-supply'"></app-my-investments>
        <app-my-earnings *ngIf="currentTab === 'my-earnings'"></app-my-earnings>
        <app-my-withdrawals *ngIf="currentTab === 'my-withdrawals'"></app-my-withdrawals>
        <app-my-drawdowns *ngIf="currentTab === 'my-drawdowns'"></app-my-drawdowns>
        <app-my-repayments *ngIf="currentTab === 'my-repayments'"></app-my-repayments>
        <app-my-payfi-drawdowns *ngIf="currentTab === 'my-payfi-drawdowns'"></app-my-payfi-drawdowns>
    </div>
</div>