import {
    Component, Input, OnInit
} from '@angular/core';
import { Router} from '@angular/router';
import { getWindow } from 'ssr-window';
import IPrivateCreditPool from '../../../interfaces/IPrivateCreditPool';
import PrivateCreditPool from '../../../models/PrivateCreditPool';
import { HttpService } from '../../../services/http.service';

@Component({
    selector: 'app-lend-default',
    templateUrl: './lend-default.component.html',
    styleUrl: './lend-default.component.scss'
})

export class LendDefaultComponent implements OnInit {
    @Input() pool: IPrivateCreditPool = new PrivateCreditPool();

    window: Window;

    constructor(private router: Router, public httpService: HttpService) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        console.log('lend-default.component.ts ngOnInit()');
    }

    selectPool(pool: IPrivateCreditPool) {
        this.router.navigate([ `/liquidity/${pool.category_id}/${pool.pool_id}` ]);
    }

    redirectToUrl(event: Event, url: string) {
        this.window.open(url, '_blank');

        event.preventDefault();
        event.stopImmediatePropagation();
    }
}