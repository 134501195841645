import {
    Component, Input, OnInit 
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import {
    ActivatedRoute, Router 
} from '@angular/router';

@Component({
    selector: 'app-payfi-widget',
    templateUrl: './payfi-widget.component.html',
    styleUrl: './payfi-widget.component.scss'
})
export class OverdraftWidgetComponent implements OnInit {
    currentTab: string = 'drawdown';
    @Input() lending_pool_id: string = '';

    tabs: Array<{
        label: string,
        key: string
    }> = [{
            label: 'Drawdown',
            key: 'drawdown'
        }, {
            label: 'Repayment',
            key: 'repayment'
        }, {
            label: 'Collateral',
            key: 'collateral'
        }, {
            label: 'Lending',
            key: 'lending'
        }, {
            label: 'Withdrawal',
            key: 'withdrawal'
        }, {
            label: 'Earnings',
            key: 'earnings'
        }];

    constructor(public httpService: HttpService, 
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (params['currenttab']) {
                const tab = params['currenttab'];

                if (this.tabs.find(t => t.key === tab))
                    this.currentTab = tab;
            }
        });
    }

    changedTab(tab: any) {
        this.currentTab = tab;

        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {
                    currenttab: tab
                }, 
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}
