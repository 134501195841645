export default {
  name: 'EIP-5133',
  number: 5133,
  comment: 'Delaying Difficulty Bomb to mid-September 2022',
  url: 'https://eips.ethereum.org/EIPS/eip-5133',
  status: 'Draft',
  minimumHardfork: 'grayGlacier',
  gasConfig: {},
  gasPrices: {},
  vm: {},
  pow: {
    difficultyBombDelay: {
      v: 11400000,
      d: 'the amount of blocks to delay the difficulty bomb with'
    }
  }
};
